import { renderPoint, renderPaint, renderLine } from './Paints'
import { getGeoByPixel, getPointsFromImage } from './PointsConvertor'

export default class CanvasLayer {
  constructor(canvasId, onApply) {
    this.canvas = null
    this.canvasId = canvasId
    this.mouse = { x: 0, y: 0 }
    this.last = { x: 0, y: 0 }
    this.selection = false
    this.setTool({})
    this.onApply = onApply
    this.data = {
      points: [],
      newPoints: [],
      line: [],
      newLinePoint: [0, 0],
    }
  }

  resize(mapState) {
    this.size = mapState.size
    this.camera = mapState.camera
    this.render()
  }

  setData(points) {
    this.data.points = points
  }

  initCanvas() {
    this.canvas = document.getElementById(this.canvasId)
  }

  onMouseDown(e) {
    this.last = {
      x: e.clientX,
      y: e.clientY,
    }
    this.mouse.x = e.clientX
    this.mouse.y = e.clientY
    this.data.newPoints = []
    this.bitmap = {
      zoom: this.camera.zoom,
      width: this.size.x,
      height: this.size.y,
      data: null,
    }
    if (this.tool.brush === 'line') this.data.line.push([e.clientX, e.clientY])
    this.selection = true
    this.onMouseMove(e)
    window.onkeydown = (event) => {
      if (event.key === 'Escape') {
        this.selection = false
        this.data.newPoints = []
        this.data.line = []
        this.render()
      }
      if (event.key === 'Enter') {
        this.onApply(this.getLinePoints())
      }
    }
  }

  onMouseMove(e) {
    if (!this.selection) return
    this.mouse.x = e.clientX
    this.mouse.y = e.clientY
    const geo = getGeoByPixel(
      { camera: this.camera, size: this.size },
      this.mouse
    )
    switch (this.tool.brush) {
      case 'point':
        this.data.newPoints[0] = [geo.x, geo.y, this.tool.depth]
        break
      case 'paint':
        break
      case 'line':
        if (this.data.line.length > 1)
          this.data.line[this.data.line.length - 1] = [e.clientX, e.clientY]
        this.newLinePoint = [e.clientX, e.clientY]
        break
    }
    this.render()
  }

  onMouseUp(e) {
    if (!this.selection) return
    this.selection = false

    switch (this.tool.brush) {
      case 'point': {
        const geo = getGeoByPixel(
          { camera: this.camera, size: this.size },
          this.mouse
        )
        this.data.line = []
        return { points: [[geo.x, geo.y, this.tool.depth]] }
      }
      case 'paint':
        this.data.line = []
        return {
          promise: getPointsFromImage(
            this.canvas.getContext('2d'),
            this.bitmap,
            { camera: this.camera, size: this.size },
            this.tool
          ),
        }
      case 'line':
        this.data.line[this.data.line.length - 1] = [e.clientX, e.clientY]
        this.render()
        return {}
    }
  }

  async getLinePoints() {
    const result = await getPointsFromImage(
      this.canvas.getContext('2d'),
      this.bitmap,
      { camera: this.camera, size: this.size },
      this.tool
    )
    this.data.line = []
    this.render()
    return result
  }

  render() {
    if (!this.canvas) return
    // if (this.data.newPoints.length === 0) return
    if (!this.camera) return
    this.canvas.width = this.size.x
    this.canvas.height = this.size.y
    const ctx = this.canvas.getContext('2d')
    // renderSelection(ctx, this.shape)

    switch (this.tool.brush) {
      case 'point':
        if (this.selection) renderPoint(ctx, this.mouse.x, this.mouse.y)
        break
      case 'paint':
        if (this.selection) {
          if (this.bitmap.data) ctx.putImageData(this.bitmap.data, 0, 0)
          renderPaint(ctx, this.mouse.x, this.mouse.y, this.tool.radius)
          this.bitmap.data = ctx.getImageData(
            0,
            0,
            this.bitmap.width,
            this.bitmap.height
          )
        }
        break
      case 'line':
        renderLine(ctx, this.data.line, this.tool.radius)
        break
    }
  }

  setTool(toolOptions) {
    this.tool = toolOptions
    if (!this.tool.brush) this.tool.brush = 'point'
    if (!this.tool.depth) this.tool.depth = 1
    if (!this.tool.radius) this.tool.radius = 10
    if (this.tool.apply) this.onApply(this.getLinePoints())
    if (this.tool.brush !== 'line' && this.data) this.data.line = []
    if (!this.tool.loading) this.render()
  }
}
