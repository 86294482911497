const colors = {
  stroke: '#1565c099',
  fill: '#1565c022',
}

function renderSelection(ctx, shape) {
  const x = shape.x0
  const y = shape.y0
  const w = shape.x1 - x
  const h = shape.y1 - y
  ctx.fillStyle = colors.fill
  ctx.strokeStyle = colors.stroke
  ctx.strokeWidth = 1
  switch (shape.type) {
    default:
    case 'rect':
      ctx.fillRect(x, y, w, h)
      ctx.strokeRect(x - 0.5, y - 0.5, w, h)
      break
    case 'circle':
      ellipseByCenter(ctx, shape.cx, shape.cy, shape.a * 2, shape.b * 2)
      ctx.fill()
      ctx.stroke()
      break
    case 'magic':
      ctx.beginPath()
      ctx.arc(shape.x, shape.y, shape.radius, 0, 2 * Math.PI, false)
      ctx.fill()
      ctx.stroke()
      break
  }
}

function renderPoint(ctx, x, y) {
  const b = 1.5
  ctx.strokeStyle = 'black'
  ctx.strokeRect(x - b, y - b, b * 2, b * 2)
}

function renderSelectedPoint(ctx, x, y) {
  const b = 2.5
  ctx.strokeWidth = 1
  ctx.fillStyle = 'red'
  ctx.fillRect(x - b, y - b, b * 2, b * 2)
}

function renderNewSelectionPoint(ctx, x, y) {
  const b = 2
  ctx.fillStyle = '#1565c0'
  ctx.fillRect(x - b, y - b, b * 2, b * 2)
}

function ellipseByCenter(ctx, cx, cy, w, h) {
  ellipse(ctx, cx - w / 2.0, cy - h / 2.0, w, h)
}

function ellipse(ctx, x, y, w, h) {
  const kappa = 0.5522848
  const ox = (w / 2) * kappa // control point offset horizontal
  const oy = (h / 2) * kappa // control point offset vertical
  const xe = x + w // x-end
  const ye = y + h // y-end
  const xm = x + w / 2 // x-middle
  const ym = y + h / 2 // y-middle
  ctx.beginPath()
  ctx.moveTo(x, ym)
  ctx.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y)
  ctx.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym)
  ctx.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye)
  ctx.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym)
  // ctx.closePath(); // not used correctly, see comments (use to close off open path)
}

export {
  renderSelection,
  renderPoint,
  renderSelectedPoint,
  renderNewSelectionPoint,
}
