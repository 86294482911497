import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import PaletteViewer from '../../../Palette/PaletteViewer'
import DataContext from '../../../../Context/DataContext'

export default function P8Options({ format, options, onChange }) {
  const manager = useContext(DataContext)
  // const [selectedPalettes, setSelectedPalettes] = React.useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    onChange({
      selectedPalettes: typeof value === 'string' ? value.split(',') : value,
    })
  }

  return format === 'praktik8' || format === 'p8' ? (
    <Box sx={{ mb: 1 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={options.deletedPoints === true}
            onChange={(event) =>
              onChange({ deletedPoints: event.target.checked })
            }
          />
        }
        label="Включить в файл удаленные области"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={options.palettes === true}
            onChange={(event) => onChange({ palettes: event.target.checked })}
          />
        }
        label="Сохранить палитры"
      />
      {!options.palettes ? null : (
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel>Сохраняемые палитры</InputLabel>
          <Select
            label="Сохраняемые палитры"
            value={options.selectedPalettes}
            multiple
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map((item, index) => manager.getPalette(item).name)
                .join(', ')
            }
          >
            {manager.getData().palettes.map((item, index) => (
              <MenuItem value={index} key={index}>
                <Checkbox
                  sx={{ pl: 0 }}
                  checked={options.selectedPalettes.indexOf(index) > -1}
                />
                <PaletteViewer
                  sx={{ minWidth: '230px', width: '80%' }}
                  named={false}
                  marked={true}
                  fixed={true}
                  value={item}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  ) : null
}

P8Options.propTypes = {
  format: PropTypes.string,
  options: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}
