import KRM from '../Map/krm'
import { NonBlockingCalculate } from '../../Data/NonBlockingCalculate'

function getGeoByPixel(mapState, pos) {
  const center = mapState.camera.center
  const scale = KRM.getMetersScaleArray(center)
  const x =
    center[0] +
    KRM.pixelsToMeters(
      pos.x - mapState.size.x / 2,
      mapState.camera.zoom,
      center[1]
    ) /
      scale.scaleX
  const y =
    center[1] -
    KRM.pixelsToMeters(
      pos.y - mapState.size.y / 2,
      mapState.camera.zoom,
      center[1]
    ) /
      scale.scaleY
  return { x: x, y: y }
}

function roundPoint(points, resolution = 8) {
  return KRM.idxToGeo(KRM.geoToIdx(points, resolution), resolution)
}

function getPointsFromImage(ctx, bitmap, mapState, tool) {
  if (!bitmap) return []

  const resolution = 8
  const center = roundPoint(mapState.camera.center, resolution)
  const size = mapState.size
  const img = ctx.getImageData(0, 0, bitmap.width, bitmap.height)
  const pix = img.data

  const scale = KRM.getMetersScaleArray(center)
  let w =
    KRM.pixelsToMeters(size.x / 2, mapState.camera.zoom, center[1]) /
    scale.scaleX
  let h =
    KRM.pixelsToMeters(size.y / 2, mapState.camera.zoom, center[1]) /
    scale.scaleY
  const stepSize = resolution
  const geoStepX = stepSize / scale.scaleX
  const geoStepY = stepSize / scale.scaleY
  const pixelCenter = KRM.getTileFromCoords(
    center[0],
    center[1],
    mapState.camera.zoom
  )
  w = Math.floor(w / geoStepX) * geoStepX
  h = Math.floor(h / geoStepY) * geoStepY
  const countX = Math.floor((w * 2) / geoStepX)
  return new Promise((resolve) => {
    const points = []
    for (let j = 0; j < countX; j++) {
      const x = center[0] - w + j * geoStepX
      const count = Math.floor((h * 2) / geoStepY)
      NonBlockingCalculate(
        count,
        100,
        (i) => {
          const y = center[1] - h + i * geoStepY
          const p = KRM.getTileFromCoords(x, y, mapState.camera.zoom)
          const pixX = Math.round(p.xp - pixelCenter.xp + size.x / 2)
          const pixY = Math.round(p.yp - pixelCenter.yp + size.y / 2)
          if (pix[pixX * 4 + pixY * bitmap.width * 4 + 3] !== 0)
            points.push(roundPoint([x, y, tool.depth], resolution))
        },
        () => {
          resolve(points)
        }
      )
    }
  })
}

export { getGeoByPixel, getPointsFromImage }
