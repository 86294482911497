import krm from '../Faces/Map/krm'

export default class GridManager {
  constructor(size, toGeoConvertor = (v) => v) {
    this.tiles = {}
    this.tilesInfo = {}
    this.index = null
    this.indexStep = 10
    this.size = size
    this.toGeoConvertor = toGeoConvertor
  }

  createIndex() {
    const step = this.indexStep
    let shift = 0
    let prevKey = null
    this.index = []
    for (const key in this.tiles) {
      this.tilesInfo[key] = { start: shift }
      for (
        let i = Math.ceil(shift / step),
          count = Math.ceil(shift / step) + this.tiles[key].length / step;
        i < count;
        i++
      ) {
        this.index[i] = key
      }
      if (prevKey) this.tilesInfo[prevKey].next = key
      shift += this.tiles[key].length
      prevKey = key
    }
    // console.log(this.index.length)
  }

  /*
  setPoints(points) {
    this.tiles = {}
    let i = 0
    for (const p of points) {
      const key = this.getTileKey(p)
      if (!this.tiles[key]) this.tiles[key] = []
      this.tiles[key].push(i++)
    }
  } */

  addPoint(point, mergeCallback, radius = 0, addCallback = null) {
    if (!mergeCallback)
      mergeCallback = (a, b, tileKey, bIndex) => {
        // if (b == null) return true
        return a[0] === b[0] && a[1] === b[1]
      }
    const tileKey = this.getTileKey(point)
    let add = true
    this.eachNearestTilesPoints(point, radius, (p, key, index) => {
      if (mergeCallback(point, p, key, index)) {
        add = false
        return false
      }
    })
    if (add && (!addCallback || addCallback(point))) {
      if (!this.tiles[tileKey]) this.tiles[tileKey] = []
      this.tiles[tileKey].push(point)
      this.index = null
      return { key: tileKey, index: this.tiles[tileKey].length - 1 }
    }
    return null
  }

  updatePointDirect(tileKey, index, p) {
    this.tiles[tileKey][index] = p
  }

  updatePoint(point) {
    const tileKey = this.getTileKey(point)
    this.eachTilePoints(tileKey, (p, index) => {
      if (p[0] === point[0] && p[1] === point[1]) {
        this.updatePointDirect(tileKey, index, point)
        return false
      }
      return true
    })
  }

  clearPointDirect(tileKey, index) {
    this.tiles[tileKey].splice(index, 1)
    this.index = null
  }

  clearPoint(point) {
    const tileKey = this.getTileKey(point)
    this.eachTilePoints(tileKey, (p, index) => {
      if (p[0] === point[0] && p[1] === point[1]) {
        this.clearPointDirect(tileKey, index)
        return false
      }
      return true
    })
  }

  firstTileIndex(tileKey) {
    let shift = 0
    for (const key in this.tiles)
      if (key === tileKey) {
        return shift
      } else shift += this.tiles[key].length
    return -1
  }

  get(index) {
    if (!this.index) this.createIndex()
    const is = Math.floor(index / this.indexStep)
    // console.log(is, this.index)
    let key = this.index[is]
    if (!this.tilesInfo[key]) this.createIndex()
    if (!key) return null
    if (!this.tilesInfo[key]) return null
    while (this.tilesInfo[key].start + this.tiles[key].length <= index) {
      if (!this.tilesInfo[key].next) return []
      key = this.tilesInfo[key].next
    }
    return this.tiles[key][index - this.tilesInfo[key].start]

    /*
    let shift = 0
    for (const key in this.tiles)
      if (index - shift < this.tiles[key].length) {
        return this.tiles[key][index - shift]
      } else shift += this.tiles[key].length
     */
  }

  getAllPoints() {
    const points = []
    for (const t in this.tiles) points.push(...this.tiles[t])
    return points
  }

  eachTile(callback) {}

  eachPoint(callback) {
    for (const key in this.tiles) this.eachTilePoints(key, callback)
  }

  isEmpty() {
    for (const t in this.tiles) if (t.length > 0) return false
    return true
  }

  pointsCount() {
    let count = 0
    for (const t in this.tiles) count += this.tiles[t].length
    return count
  }

  tilesCount() {
    let i = 0
    for (const t in this.tiles) if (this.tiles[t].length > 0) i++
    return i
  }

  eachTilePoints(tileKey, callback) {
    if (!this.tiles[tileKey]) return
    for (let i = 0; i < this.tiles[tileKey].length; i++)
      callback(this.tiles[tileKey][i], i)
  }

  eachNearestTilesPoints(point, radius, callback) {
    const keys = {}
    keys[this.getTileKey(point)] = true
    keys[this.getTileKey([point[0] - radius, point[1] + 0])] = true
    keys[this.getTileKey([point[0] - radius, point[1] - radius])] = true
    keys[this.getTileKey([point[0] - radius, point[1] + radius])] = true
    keys[this.getTileKey([point[0] + radius, point[1] + 0])] = true
    keys[this.getTileKey([point[0] + radius, point[1] - radius])] = true
    keys[this.getTileKey([point[0] + radius, point[1] + radius])] = true
    keys[this.getTileKey([point[0] + 0, point[1] - radius])] = true
    keys[this.getTileKey([point[0] + 0, point[1] + radius])] = true
    for (const key in keys)
      if (this.tiles[key])
        for (let i = 0; i < this.tiles[key].length; i++)
          if (callback(this.tiles[key][i], key, i) === false) return
  }

  getTileKey(point) {
    const x = Math.floor(point[0] / this.size)
    const y = Math.floor(point[1] / this.size)
    return x + ':' + y
  }

  getPointsInPixelTile(x, y, z, round = 0) {
    const points = []
    const indexes = []
    let index = 0
    for (const key in this.tiles) {
      const t = key.split(':')
      t[0] = (parseInt(t[0]) - 0.5) * this.size
      t[1] = (parseInt(t[1]) - 0.5) * this.size
      const geoA = this.toGeoConvertor(t)
      const geoB = this.toGeoConvertor([
        t[0] + this.size * 2,
        t[1] + this.size * 2,
      ])
      const a = krm.getTileFromCoords(geoA[0], geoA[1], z)
      const b = krm.getTileFromCoords(geoB[0], geoB[1], z)
      if (
        this.between(x, a.x, b.x, round) &&
        this.between(y, a.y, b.y, round)
      ) {
        points.push(...this.tiles[key])
        for (let i = 0; i < this.tiles[key].length; i++) indexes.push(index++)
      } else index += this.tiles[key].length
    }
    return { points: points, indexes: indexes }
  }

  between(value, a, b, round = 0) {
    const min = Math.min(a, b)
    const max = Math.max(a, b)
    const dv = (max - min) * round
    return value >= min - dv && value <= max + dv
  }
}
