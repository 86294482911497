import React from 'react'
import PropTypes from 'prop-types'
import DataContext from './DataContext'

function DataProvider({ data, children }) {
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
}

DataProvider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any,
}

export default DataProvider
