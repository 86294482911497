const features = {
  SETTINGS: true,
  FILTERS: true,

  ACTIONS_ADD: true,
  ADD_ONE: true,
  ADD_PAINT: true,
  ADD_LINE: true,

  ACTIONS_EDIT: true,
  ACTIONS_REMOVE: true,

  TOOL_HAND: true,

  TOOL_SELECT_RECT: true,
  TOOL_SELECT_CIRCLE: true,
  TOOL_SELECT_MAGIC: true,
  TOOL_CLEAR: true,
  TOOL_INVERT: true,
  TOOL_RESET_CACHE: true,

  TOOL_RULER: true,
  RULER_OPTIONS: false,
  TOOL_SELECT_METHOD: true,

  PALETTES: true,

  HISTORY: true,

  DARK_THEME: false,
  SERVICE: true,
  PRO_MODE: true,
  GRID_POINTS: false,

  HELP_PAGE: false,
}

export default features
