function formatUnit(value, units = ['штука', 'штуки', 'штук']) {
  const v = parseInt(String(value).substr(-1))
  let u = 2
  if (v === 1) u = 0
  if (v === 2 || v === 3 || v === 4) u = 1
  if (value > 10 && value < 20) u = 2
  return value + ' ' + units[u]
}

export { formatUnit }
