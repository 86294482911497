import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Slider, Typography } from '@mui/material'
import krm from '../../Map/krm'
import DataContext from '../../../Context/DataContext'
import FilterLayout from './FilterLayout'
import { NonBlockingCalculate } from '../../../Data/NonBlockingCalculate'

async function filterExecutor(data, delta, radius) {
  const selection = []
  const r = radius * radius
  const sessions = {}
  for (let i = 0; i < data.length; i++) {
    if (!sessions[data[i][4]])
      sessions[data[i][4]] = { index: data[i][4], count: 0 }
    sessions[data[i][4]].count++
  }
  const scale = krm.getMetersScale(data[0])
  const promises = []
  for (const s in sessions) {
    promises.push(
      new Promise((resolve) => {
        const session = sessions[s].index
        let j = 0
        NonBlockingCalculate(
          data.length - 3,
          100,
          (i) => {
            if (data[i][4] !== session || i < j) return
            for (j = i; j < data.length - 1; j++) {
              const dx = (data[j + 1][0] - data[i][0]) * scale.scaleX
              const dy = (data[j + 1][1] - data[i][1]) * scale.scaleY
              if (
                Math.abs(data[i][2] - data[j + 1][2]) > delta ||
                dx * dx + dy * dy > r
              ) {
                break
              }
            }
            for (let n = i + 1; n <= j; n++) selection[n] = true
            j++
          },
          () => resolve(s)
        )
      })
    )
  }

  for (const p of promises) await p
  return selection
}

function SessionFilter({ onSelect }) {
  const manager = useContext(DataContext)

  const [delta, setDelta] = React.useState(0.1)
  const [radius, setRadius] = React.useState(1)
  const [loading, setLoading] = React.useState(false)

  const handleExecute = () => {
    setLoading(true)
    filterExecutor(manager.getEffectivePoints(), delta, radius).then(
      (selection) => {
        setLoading(false)
        onSelect(selection)
      }
    )
  }

  return (
    <FilterLayout
      label="Данный фильтр пройдет по трекам и выделит слишком часто стоящие точки, после чего их можно будет удалить"
      button="Выделить точки"
      loading={loading}
      onExecute={handleExecute}
    >
      <Box sx={{ my: 2 }}>
        <Typography variant="label">
          Макс. отклонение по глубине ({delta} м)
        </Typography>
        <Slider
          disabled={loading}
          value={delta}
          valueLabelDisplay="auto"
          step={0.05}
          min={0.05}
          max={0.5}
          onChange={(e, v) => setDelta(v)}
        />
        <Typography variant="label">Радиус поиска ({radius} м)</Typography>
        <Slider
          disabled={loading}
          value={radius}
          valueLabelDisplay="auto"
          step={0.05}
          min={0.1}
          max={3}
          onChange={(e, v) => setRadius(v)}
        />
      </Box>
    </FilterLayout>
  )
}

SessionFilter.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
}

export default SessionFilter
