import React, { useEffect } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

function CheckAndValue(props) {
  const [value, setValue] = React.useState(props.value)

  const handleChange = (e) => {
    const value = e.target.value
    setValue(value)
    const f = parseFloat(value.replace(/[^0-9,.]+/g, '').replace(',', '.'))
    if (!isNaN(f) && f > 0) props.onChange(Math.abs(f))
  }

  useEffect(() => setValue(props.value), [props.value])

  return (
    <Box sx={props.sx}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked === true}
            onChange={() => props.onToggle(!props.checked)}
          />
        }
        label={props.label}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
        sx={{ ml: 4 }}
      >
        <Typography variant="body2">{props.desc}</Typography>
        <TextField
          sx={{ maxWidth: 100 }}
          size="small"
          min={0}
          disabled={!props.checked}
          value={
            props.checked || props.altValue === undefined
              ? value
              : props.altValue
          }
          onChange={(e) => handleChange(e)}
        />
      </Stack>
    </Box>
  )
}

CheckAndValue.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  altValue: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  onChange: PropTypes.func,
  sx: PropTypes.any,
}

export default CheckAndValue
