import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { BootstrapDialogTitle, BootstrapDialog } from './PopupDialog'
import DialogProgress from './DialogProgress'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ height: 1 }}>{children}</Box>}
    </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function PopupTabsDialog(props) {
  const handleClose = () => {
    if (props.onClose) props.onClose()
  }

  const [tabIndex, setTab] = React.useState(props.defaultTab || 0)

  const handleChangeTab = (event, index) => {
    setTab(index)
  }

  return (
    <BootstrapDialog
      open={props.open}
      onClose={handleClose}
      maxWidth={props.maxWidth}
      fullWidth={true}
      scroll="paper"
      sx={props.sx}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        <Typography variant="h6" component="div">
          {props.title}
        </Typography>
        <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            {props.tabs.map((item, index) => {
              return <Tab label={item.label} key={index} />
            })}
          </Tabs>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogProgress loading={props.loading}>
          {props.tabs.map((item, index) => {
            return (
              <TabPanel
                sx={{ minHeight: props.tabHeight }}
                value={tabIndex}
                key={index}
                index={index}
              >
                {item.content}
              </TabPanel>
            )
          })}
        </DialogProgress>
        {props.children}
      </DialogContent>
      {props.loading
        ? null
        : props.tabs.map((item, index) => {
            return index === tabIndex && item.actions ? (
              <DialogActions key={index}>{item.actions}</DialogActions>
            ) : null
          })}
    </BootstrapDialog>
  )
}

PopupTabsDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      actions: PropTypes.node,
    })
  ).isRequired,
  defaultTab: PropTypes.number,
  loading: PropTypes.bool,
  children: PropTypes.any,
  maxWidth: PropTypes.string,
  tabHeight: PropTypes.string,
  sx: PropTypes.any,
}

export default PopupTabsDialog
