import React, { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const Input = styled('input')({
  display: 'none',
})

const formatFSUnit = (size, unit) => {
  if (size < 1) return Math.round(size * 100) / 100 + ' ' + unit
  if (size < 10) return Math.round(size * 10) / 10 + ' ' + unit
  return Math.round(size) + ' ' + unit
}

const formatFileSize = (size) => {
  if (size / 1000 < 1) return formatFSUnit(size, 'байт')
  if (size / 1000 / 1000 < 1) return formatFSUnit(size / 1000, 'кБ')
  return formatFSUnit(size / 1000 / 1000, 'Мб')
}

function Uploader({ onLoadStart, onLoadEnd, onLoadError }) {
  const [uploadStatus, setUploadStatus] = useState('ready')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setUploadError] = useState('')
  const [fileMaxSize, setFileMaxSize] = useState(0)

  const fetchUpload = (url, options) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open(options.method, url, true)
      xhr.onloadend = (e) => {
        setUploadProgress(100)
        if (xhr.status !== 200) {
          let errorMessage = xhr.statusText
          try {
            errorMessage = JSON.parse(xhr.response).message
          } catch (e) {}
          reject(new Error(errorMessage))
        }
        try {
          const result = JSON.parse(xhr.response)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      }
      xhr.upload.onerror = (e) => reject(e)
      xhr.upload.onprogress = (e) => {
        setUploadProgress((e.loaded / e.total) * 100)
      }
      xhr.send(options.body)
    })
  }

  const uploadFile = (event) => {
    if (onLoadStart) onLoadStart()

    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file)

    setUploadProgress(0)
    setUploadStatus('progress')

    fetchUpload('/api/v1/upload', {
      method: 'POST',
      body: formData,
    })
      .then((result) => {
        setUploadProgress(100)
        result.file = {
          name: file.name,
          size: file.size,
        }
        onLoadEnd(result)
        setUploadStatus('ready')
      })
      .catch((e) => {
        setUploadStatus('error')
        setUploadError(e.message)
        if (onLoadError) onLoadError()
      })
  }

  React.useEffect(() => {
    fetch('/api/v1/max-file-size', { method: 'GET' }).then((response) => {
      response.json().then((result) => {
        setFileMaxSize(result.size)
      })
    })
  }, [])

  const retryUpload = () => setUploadStatus('ready')

  return (
    <Box>
      {uploadStatus === 'error' ? (
        <Alert severity="error" onClose={retryUpload}>
          {uploadError}
        </Alert>
      ) : null}

      {uploadStatus === 'progress' ? (
        <Box sx={{ w: 1, my: 1 }}>
          <LinearProgress
            variant={
              uploadProgress === 100 && uploadStatus === 'progress'
                ? 'indeterminate'
                : 'determinate'
            }
            value={uploadProgress}
          />
        </Box>
      ) : null}

      {uploadStatus === 'ready' ? (
        <Box>
          <label htmlFor="contained-button-file">
            <Input
              id="contained-button-file"
              type="file"
              onChange={uploadFile}
            />
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <Button variant="contained" component="span">
                Выбрать файл
              </Button>
              <Typography
                variant="caption"
                component="div"
                sx={{ color: 'text.secondary' }}
              >
                Практик 7 (tar), Практик 8 (pc8) и csv (
                <a href="/demo.csv">пример</a> для Excel).
                {fileMaxSize === 0
                  ? null
                  : ' Размер до ' + formatFileSize(fileMaxSize)}
              </Typography>
            </Stack>
          </label>
        </Box>
      ) : null}
    </Box>
  )
}

Uploader.propTypes = {
  onLoadStart: PropTypes.func,
  onLoadEnd: PropTypes.func.isRequired,
  onLoadError: PropTypes.func,
}

export { formatFileSize, Uploader }
