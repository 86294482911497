import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './Palette.module.css'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ClearIcon from '@mui/icons-material/Clear'

function getLabel(color) {
  if (color.label) return color.label
  const d = color.depth > 10 ? 1 : 10
  return '\u00A0' + Math.round(color.depth * d) / d + '\u00A0м'
}

function PaletteColorMark({
  color,
  onDialog,
  onDragStart,
  onRemove,
  remove,
  last,
  first,
  selected,
}) {
  return (
    <Box
      className={styles.root}
      sx={{ ml: last ? -4 : first ? -2 : null }}
      style={{
        left: color.shift * 100 + '%',
      }}
    >
      {remove && !(first || last) ? (
        <ClearIcon
          fontSize="small"
          className={styles.remove}
          onClick={onRemove}
        />
      ) : (
        <div className={styles.remove} />
      )}
      <ArrowDropUpIcon
        sx={{ opacity: first || last ? 0 : 1 }}
        color={selected ? 'primary' : 'text'}
      />
      <Box
        onMouseDown={first || last ? null : onDragStart}
        className={styles.label}
        sx={{ cursor: first || last ? null : 'col-resize' }}
      >
        <Typography variant="caption" color={selected ? 'primary' : null}>
          {getLabel(color)}
        </Typography>
      </Box>
      {first || !onDialog ? null : (
        <div
          onClick={onDialog}
          style={{ background: color.color }}
          className={styles.color}
        />
      )}
    </Box>
  )
}

PaletteColorMark.propTypes = {
  color: PropTypes.object,
  onDragStart: PropTypes.func,
  onDialog: PropTypes.func,
  onRemove: PropTypes.func,
  remove: PropTypes.bool,
  last: PropTypes.bool,
  first: PropTypes.bool,
  selected: PropTypes.bool,
}

export default PaletteColorMark
