import React, { useContext } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import ListIcon from '@mui/icons-material/List'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import SettingsIcon from '@mui/icons-material/Settings'

import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import FileDialog from '../Dialog/FileDialog/FileDialog'
import PropTypes from 'prop-types'
import PointsDialog from '../Dialog/PointsDialog/PointsDialog'
import SettingsDialog from '../Dialog/SettingsDialog/SettingsDialog'
import { styled } from '@mui/material/styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import FilterDialog from '../Dialog/FilterDialog/FilterDialog'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import DataContext from '../../Context/DataContext'
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dialog-tabpanel-${index}`}
      aria-labelledby={`dialog-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, height: '100%' }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index, props) {
  return {
    sx: { my: 0, fontSize: 12 },
    id: `dialog-tab-${index}`,
    'aria-controls': `dialog-tabpanel-${index}`,
    onClick: () => props.onUndense(true),
  }
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#1976d2',
  },
})

const StyledIconButton = styled((props) => (
  <IconButton {...props} variant="contained">
    {props.children}
  </IconButton>
))(({ theme }) => ({
  borderRadius: 10,
  padding: '0.3em 0.6em',
  color: 'white',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: 'rgb(25, 118, 210)',
  },
}))

function EditorDialog(props) {
  const [value, setValue] = React.useState(3)
  const manager = useContext(DataContext)
  const nodata = !manager.isDataAvailable()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleFilesChange = (data) => {
    props.onFilesChange(data)
  }

  const handleOptions = (options) => {
    props.onOptionsChange(options)
  }

  const handleSettings = (settings) => {
    props.onSettingsChange(settings)
  }

  const features = useContext(FeaturesContext)

  const bottomLine =
    (features.includes('PRO_MODE') ||
      features.includes('DARK_THEME') ||
      features.includes('SERVICE')) &&
    !props.isMobile

  const bottomLineHeight = bottomLine ? 45 : 0

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
      }}
    >
      {props.isMobile ? (
        <StyledIconButton
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: props.tabHeight,
            mb: -2,
            ml: -2,
            zIndex: 3,
          }}
          onClick={() => props.onUndense(props.dense)}
        >
          <ArrowForwardIosIcon
            sx={{ fontSize: 18 }}
            style={{
              transform: props.dense ? 'rotate(-90deg)' : 'rotate(90deg)',
            }}
          />
        </StyledIconButton>
      ) : null}
      <StyledTabs
        sx={{
          zIndex: 2,
          position: 'absolute',
          left: 0,
          right: 0,
          pt: 1,
          top: props.isMobile ? null : 0,
          bottom: props.isMobile ? 0 : null,
          borderTop: props.isMobile ? 1 : null,
          borderBottom: props.isMobile ? null : 1,
          borderColor: 'grey.300',
          height: props.tabHeight,
        }}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab
          disabled={nodata}
          icon={<ListIcon />}
          label="Точки"
          aria-label="Точки"
          {...a11yProps(0, props)}
        />
        <Tab
          disabled={nodata || !features.includes('FILTERS')}
          icon={<FilterAltIcon />}
          label="Фильтры"
          aria-label="Фильтры"
          {...a11yProps(1, props)}
        />
        <Tab
          disabled={nodata || !features.includes('SETTINGS')}
          icon={<SettingsIcon />}
          label="Настройки"
          aria-label="Настройки карты"
          {...a11yProps(2, props)}
        />
        <Tab
          icon={<UploadFileIcon />}
          label="Файлы"
          aria-label="Загрузка файла"
          {...a11yProps(3, props)}
        />
      </StyledTabs>
      <Box
        sx={{
          display: props.dense ? 'none' : 'block',
          position: 'absolute',
          left: 0,
          right: 0,
          top: props.isMobile ? 0 : props.tabHeight,
          bottom: props.isMobile ? props.tabHeight : bottomLineHeight,
        }}
      >
        <TabPanel style={{ height: '100%' }} value={value} index={0}>
          <PointsDialog
            opened={props.opened}
            onOpen={props.onOpen}
            onDataChange={props.onDataChange}
            selection={props.selection}
            onSelect={props.onSelect}
          />
        </TabPanel>
        <TabPanel style={{ height: '100%' }} value={value} index={1}>
          <FilterDialog
            options={props.options}
            onSelect={props.onSelect}
            onDataChange={props.onDataChange}
          />
        </TabPanel>
        <TabPanel style={{ height: '100%' }} value={value} index={2}>
          <SettingsDialog
            options={props.options}
            onOptionsChange={handleOptions}
            onDataChange={props.onDataChange}
          />
        </TabPanel>
        <TabPanel style={{ height: '100%' }} value={value} index={3}>
          <FileDialog
            options={props.options}
            selection={props.selection}
            onFilesChange={handleFilesChange}
          />
        </TabPanel>
      </Box>
      {bottomLine ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          style={{ borderTop: '1px solid #eee' }}
          sx={{
            position: 'absolute',
            background: 'paper.background',
            left: 0,
            right: 0,
            height: bottomLineHeight,
            bottom: 0,
            pl: 3.5,
            pr: 2,
          }}
        >
          {features.includes('DARK_THEME') ? (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ fontSize: 10 }}
                    size="small"
                    checked={props.settings.darkTheme === true}
                    onChange={(event, checked) =>
                      handleSettings({ darkTheme: checked })
                    }
                  />
                }
                label={<Typography variant="caption">Темная тема</Typography>}
              />
            </Box>
          ) : null}
          {features.includes('PRO_MODE') ? (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ fontSize: 10 }}
                    size="small"
                    checked={props.settings.proMode === true}
                    onChange={(event, checked) =>
                      handleSettings({ proMode: checked })
                    }
                  />
                }
                label={<Typography variant="caption">Режим профи</Typography>}
              />
            </Box>
          ) : null}
          {!features.includes('DARK_THEME') &&
          !features.includes('PRO_MODE') ? (
            <Box />
          ) : null}
          {features.includes('SERVICE') ? (
            <Tooltip title="Служебные настройки" placement="top">
              <IconButton
                color="secondary"
                size="small"
                onClick={() => handleSettings({ serviceDialog: true })}
              >
                <SettingsApplicationsRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      ) : null}
    </Box>
  )
}

EditorDialog.propTypes = {
  points: PropTypes.object,
  options: PropTypes.object,
  settings: PropTypes.object,
  selection: PropTypes.any,
  onFilesChange: PropTypes.func,
  onDataChange: PropTypes.func,
  onOptionsChange: PropTypes.func,
  onSettingsChange: PropTypes.func,
  onSelect: PropTypes.func,
  opened: PropTypes.array.isRequired,
  onOpen: PropTypes.func,
  isMobile: PropTypes.bool,
  dense: PropTypes.bool,
  onUndense: PropTypes.func,
  tabHeight: PropTypes.number.isRequired,
}

export default EditorDialog
