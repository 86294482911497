import { Box } from '@mui/material'
import { StyledDivider } from './StyledButtons'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import DoneIcon from '@mui/icons-material/Done'
import NumberField from './NumberField'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt'
import DataContext from '../../Context/DataContext'
import LoadingButton from '../../Layout/Components/LoadingButton'

function EditTools(props) {
  const manager = useContext(DataContext)
  const [delta, setDelta] = React.useState({ x: 0, y: 0, d: 0 })
  const [loading, setLoading] = React.useState(false)

  const handleChangeValue = (param, value) => {
    const newDelta = { ...delta }
    newDelta[param] = value
    setDelta(newDelta)
  }

  const handleApply = () => {
    setLoading(true)
    manager.pointsChange(delta, props.selection).then((res) => {
      props.onDataChange(res.manager, res.selection)
      setLoading(false)
    })
  }

  return props.tool === 'edit' ? (
    <Box
      sx={{
        py: 1,
        px: 1.5,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <ControlCameraIcon />
      <NumberField
        sx={{ mx: 1 }}
        label="З-В"
        value={0.0}
        step={0.05}
        onChange={(v) => handleChangeValue('x', v)}
      />
      <NumberField
        sx={{ mx: 1 }}
        label="C-Ю"
        value={0.0}
        step={0.05}
        onChange={(v) => handleChangeValue('y', v)}
      />
      <StyledDivider />
      <SwipeDownAltIcon />
      <NumberField
        sx={{ mx: 1 }}
        label="Глубина"
        value={0.0}
        step={0.05}
        onChange={(v) => handleChangeValue('d', v)}
      />
      <StyledDivider />
      <LoadingButton
        loading={loading}
        variant="text"
        onClick={handleApply}
        startIcon={<DoneIcon />}
      >
        Применить
      </LoadingButton>
    </Box>
  ) : null
}

EditTools.propTypes = {
  tool: PropTypes.string.isRequired,
  selection: PropTypes.array.isRequired,
  onDataChange: PropTypes.func.isRequired,
}

export default EditTools
