export default class RulerInterface {
  constructor(map) {
    this.points = []
    this.markers = []
    this.elements = []
    this.map = map
    this.used = 0
    this.elementCreator = (value) => {
      const e = document.createElement('div')
      e.innerHTML = value
      return e
    }
  }

  setElementCreator(func) {
    this.elementCreator = func
  }

  setOnDragListener(listener) {
    this.onDrag = listener
  }

  setOnClosePointListener(listener) {
    this.onClose = (event, index) => {
      event.stopPropagation()
      listener(index)
    }
  }

  createMarker(point, index) {
    if (!this.markers[index]) {
      this.elements[index] = this.elementCreator(point.value, (e) =>
        this.onClose(e, index)
      )
      this.markers[index] = new window.mapApi.YMapMarker(
        {
          source: 'rulerMarkers',
          coordinates: point.coords,
          draggable: !!this.onDrag,
          mapFollowsOnDrag: true,
          onDragMove: (coords) => this.onDrag(coords, index),
        },
        this.elements[index][0]
      )
      this.map.addChild(this.markers[index])
    } else {
      this.markers[index].update({
        coordinates: point.coords,
      })
      if (this.elements[index][1])
        this.elements[index][1].innerHTML = point.value
    }
    return this.markers[index]
  }

  createLayer(layers) {
    this.map.addChild(
      new window.mapApi.YMapFeatureDataSource({ id: 'rulerMarkers' })
    )
    this.map.addChild(
      new window.mapApi.YMapFeatureDataSource({ id: 'rulerLines' })
    )

    layers.ruler_points = new window.mapApi.YMapLayer({
      source: 'rulerMarkers',
      type: 'markers',
      zIndex: 2400,
    })

    layers.ruler_lines = new window.mapApi.YMapLayer({
      source: 'rulerLines',
      type: 'features',
      zIndex: 2350,
    })
  }

  setPoints(points) {
    points.map((item, index) => this.createMarker(item, index))
    for (
      let i = Math.min(this.used, points.length);
      i < this.markers.length;
      i++
    ) {
      if (i >= this.used) this.map.addChild(this.markers[i])
      if (i >= points.length) this.map.removeChild(this.markers[i])
    }
    this.used = points.length

    if (!this.rulerLine) {
      this.rulerLine = new window.mapApi.YMapFeature({
        id: 'rulerLine',
        source: 'rulerLines',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
        style: {
          stroke: [{ width: 2, color: 'rgb(0, 0, 0)' }],
        },
      })
      this.map.addChild(this.rulerLine)
    }
    this.rulerLine.update({
      geometry: {
        type: 'LineString',
        coordinates: points.map((item) => item.coords),
      },
    })
  }
}
