import ApiLoader from './api'
import RulerInterface from './ruler-interface'

export default class MapInterface {
  constructor(apikey, options) {
    this.apikey = apikey
    this.options = options
    this.groundLayerName = ''
    this.groundLayers = []
    this.layers = {}
    this.activeLayers = {}
    window.mapServiceLoaded = false
  }

  init(node) {
    const loader = new ApiLoader('ymaps3')
    return new Promise((resolve, reject) => {
      if (loader.isAvailable()) {
        this.placeMap(loader.getAPI(), node).then(resolve)
      } else {
        loader
          .load(
            'https://api-maps.yandex.ru/3.0-beta/?apikey=' +
              this.apikey +
              '&lang=ru_RU'
          )
          .then((api) => {
            this.placeMap(api, node).then(resolve)
          })
      }
    })
  }

  getZoom() {
    if (!this.getMap()) return 'map is not ready'
    return this.getMap().zoom
  }

  setZoom(zoom) {
    if (this.getMap())
      this.getMap().setLocation({
        zoom: zoom,
        duration: 200,
      })
  }

  getMap() {
    return window.mapService
  }

  setMap(map) {
    window.mapService = map
  }

  setLocation(location) {
    this.getMap().setLocation({
      center: [location.center[0], location.center[1]],
      zoom: location.zoom,
      duration: 200,
    })
  }

  setBounds(bounds) {
    if (bounds.longMin === undefined) return
    this.getMap().setLocation({
      bounds: [
        [bounds.longMin, bounds.latMin],
        [bounds.longMax, bounds.latMax],
      ],
      duration: 200,
    })
  }

  switchGroundLayer(type) {
    if (this.groundLayerName === type) return
    if (this.layers[type]) {
      this.groundLayers.map((item) => this.getMap().removeChild(item))
      this.groundLayers = []
      this.groundLayers.push(this.layers[type])
      if (type === 'hybrid') this.groundLayers.push(this.layers.satellite)
      this.groundLayers.map((item) => this.getMap().addChild(item))
      this.groundLayerName = type
    }
  }

  updateTilesLayer() {
    this.updateLayer('tiles')
  }

  updateLayer(type) {
    if (this.layers[type] && this.activeLayers[type]) {
      this.getMap().removeChild(this.layers[type])
      this.getMap().addChild(this.layers[type])
    }
  }

  placeMap(api, node) {
    return new Promise((resolve, reject) => {
      if (window.mapServiceLoaded) resolve()
      if (api === undefined) reject(new Error('Service loading error'))
      window.mapServiceLoaded = true
      window.mapApi = api
      // window.mapApi.strictMode = true
      window.mapApi.ready.then(() => {
        this.setMap(
          new window.mapApi.YMap(node, {
            location: {
              center: [this.options.center[0], this.options.center[1]],
              zoom: this.options.zoom,
            },
          })
        )
        this.ruler = new RulerInterface(this.getMap())
        this.createSchemeLayer()
        this.createSatelliteLayer()
        this.createHybridLayer()
        this.createMarkersLayer()
        this.ruler.createLayer(this.layers)
        this.switchGroundLayer('scheme')
        this.setEvents()
        resolve()
      })
    })
  }

  handleZoomChange(e) {
    this.options.onZoomChange(e.location)
  }

  setEvents() {
    const mapListener = new window.mapApi.YMapListener({ layerId: 'any' })
    if (this.options.onZoomChange) {
      mapListener.update({
        onResize: (e) => this.handleZoomChange(e),
        onUpdate: (e) => this.handleZoomChange(e),
      })
    }
    if (this.options.onClick)
      mapListener.update({
        onClick: (layerId, coordinates, object) =>
          this.options.onClick({
            geopos: coordinates.coordinates,
            pixpos: coordinates.screenCoordinates,
          }),
      })
    if (this.options.onMouseMove)
      mapListener.update({
        onMouseMove: (layerId, coordinates, object) =>
          this.options.onMouseMove({
            geopos: coordinates.coordinates,
            pixpos: coordinates.screenCoordinates,
          }),
      })
    this.getMap().addChild(mapListener)
  }

  // слой карты
  addTilesLayer(callback) {
    if (!this.layers.tiles) this.createTilesLayer(callback)
  }

  createTilesLayer(callback) {
    this.getMap().addChild(
      new window.mapApi.YMapTileDataSource({
        id: 'krmSource',
        raster: {
          fetchTile: (x, y, z) => {
            return new Promise((resolve) => {
              callback(x, y, z).then((imageData) => {
                resolve({ image: imageData })
              })
            })
          },
          transparent: true,
          type: 'ground',
          size: this.options.tileSize,
        },
        zoomRange: { min: 0, max: 19 },
        clampMapZoom: true,
      })
    )
    this.layers.tiles = new window.mapApi.YMapLayer({
      id: 'tilesLayer',
      source: 'krmSource',
      type: 'ground',
      zIndex: 2000,
    })
  }

  // точки измерения
  addPointsLayer(callback, show = true) {
    if (!this.layers.points) this.createPointsLayer(callback)
    this.toggleLayer('points', show)
  }

  createPointsLayer(callback) {
    this.getMap().addChild(
      new window.mapApi.YMapTileDataSource({
        id: 'points',
        raster: {
          fetchTile: (x, y, z) => {
            return new Promise((resolve) => {
              callback(x, y, z).then((imageData) => {
                resolve({ image: imageData })
              })
            })
          },
          type: 'points',
          transparent: true,
          tileRevealDuration: 0,
          size: this.options.tileSize,
        },
      })
    )
    this.layers.points = new window.mapApi.YMapLayer({
      id: 'pointsLayer',
      source: 'points',
      type: 'points',
      zIndex: 2100,
    })
  }

  // схема / гибрид /...
  createSchemeLayer() {
    this.layers.scheme = new window.mapApi.YMapDefaultSchemeLayer()
    // this.layers.scheme.update({ theme: 'light' })
  }

  createHybridLayer() {
    this.getMap().addChild(
      new window.mapApi.YMapFeatureDataSource({ id: 'hybrid' })
    )
    this.getMap().addChild(
      new window.mapApi.YMapTileDataSource({
        id: 'hybrid',
        raster: {
          fetchTile:
            'https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&x={{x}}&y={{y}}&z={{z}}&scale={{scale}}&lang=ru_RU',
          type: 'ground',
          transparent: true,
          tileRevealDuration: 0,
        },
      })
    )

    this.layers.hybrid = new window.mapApi.YMapLayer({
      source: 'hybrid',
      type: 'ground',
      zIndex: 2200,
    })
  }

  createSatelliteLayer() {
    this.getMap().addChild(
      new window.mapApi.YMapTileDataSource({
        id: 'satellite',
        raster: {
          fetchTile:
            'https://core-sat.maps.yandex.net/tiles?l=sat&x={{x}}&y={{y}}&z={{z}}&scale={{scale}}&lang=ru_RU',
          type: 'ground',
          transparent: true,
          tileRevealDuration: 0,
        },
      })
    )
    this.layers.satellite = new window.mapApi.YMapLayer({
      source: 'satellite',
      type: 'ground',
      zIndex: 1900,
    })
  }

  toggleLayer(type, active) {
    if (!this.layers[type]) return
    if (this.activeLayers[type] === active) return
    this.activeLayers[type] = active
    if (active) this.getMap().addChild(this.layers[type])
    else this.getMap().removeChild(this.layers[type])
  }

  createMarkersLayer() {
    this.getMap().addChild(
      new window.mapApi.YMapFeatureDataSource({ id: 'markers' })
    )
    this.layers.markers = new window.mapApi.YMapLayer({
      source: 'markers',
      type: 'markers',
      zIndex: 2300,
    })
  }

  setMarkers(markers) {
    if (this.markers)
      this.markers.map((item) => this.getMap().removeChild(item))
    this.markers = markers
    this.markers.map((item) => this.getMap().addChild(item))
  }

  createMarker(element, coords, draggable = false) {
    return new window.mapApi.YMapMarker(
      {
        source: 'markers',
        coordinates: coords,
        draggable: draggable,
        mapFollowsOnDrag: true,
      },
      element
    )
  }

  getRuler() {
    return this.ruler
  }
}
