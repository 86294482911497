class KRM {
  constructor() {
    this.Rn = 6378137
    this.baseResolution = 2
  }

  getEarthRadius() {
    return this.Rn
  }

  findBounds(points) {
    if (points[0] === undefined || points.length === 0) {
      return {}
    }
    const bounds = {}
    for (const p of points) this.findBoundsCallback(p, bounds)
    this.findBoundsCenter(bounds)
    return bounds
  }

  findBoundsCallback(p, bounds) {
    if (!bounds.longMin || bounds.longMin > p[0]) bounds.longMin = p[0]
    if (!bounds.longMax || bounds.longMax < p[0]) bounds.longMax = p[0]
    if (!bounds.latMin || bounds.latMin > p[1]) bounds.latMin = p[1]
    if (!bounds.latMax || bounds.latMax < p[1]) bounds.latMax = p[1]
    if (!bounds.maxDepth || bounds.maxDepth < p[2]) bounds.maxDepth = p[2]
    return bounds
  }

  findBoundsCenter(bounds) {
    bounds.longCenter = bounds.longMin + (bounds.longMax - bounds.longMin) / 2
    bounds.latCenter = bounds.latMin + (bounds.latMax - bounds.latMin) / 2
  }

  createOptimizePoints(points, dx, dy, z, centerLat) {
    // if (z <= 17) points = this.filterPoints(points, 2)
    // if (z <= 16) points = this.filterPoints(points, 3)
    return this.createOptimizePoints1(points, dx, dy, z, centerLat)
  }

  filterPoints(points, step) {
    const newPoints = []
    for (let i = 0; i < points.length; i += step) newPoints.push(points[i])
    return newPoints
  }

  createOptimizePoints1(points, dx, dy, z, centerLat) {
    const mx = this.pixelsToMeters(dx, z, centerLat)
    const my = this.pixelsToMeters(dy, z, centerLat)
    const result = []
    for (const p of points) {
      const r = this.getMetersFromCoords(p[0], p[1], centerLat)
      result.push([r[0] - mx, my - r[1], -p[2]])
    }
    return result
  }

  getTileCenter(dx, dy, z) {
    return [0, 0]
  }

  getMetersScaleArray(pointAsArray) {
    return this.getMetersScale(pointAsArray)
  }

  getMetersScale(point) {
    return {
      longitude: point[0],
      latitude: point[1],
      scaleX:
        (this.Rn * Math.cos((point[1] * Math.PI) / 180.0) * Math.PI) / 180.0,
      scaleY: (this.Rn * Math.PI) / 180.0,
      scaleZ: 1,
    }
  }

  idxToGeo(p, resolution) {
    const centerLat = ((resolution / this.Rn) * 180.0) / Math.PI
    const geoLat = p[1] * centerLat
    const tmp = centerLat / Math.cos((geoLat * Math.PI) / 180.0)
    const geoLong = p[0] * tmp
    return [geoLong, geoLat, p[2]]
  }

  geoToIdx(p, resolution) {
    const centerLat = ((resolution / this.Rn) * 180.0) / Math.PI
    const gridLat = Math.round(p[1] / centerLat)
    const tmp = centerLat / Math.cos((gridLat * centerLat * Math.PI) / 180.0)
    const gridLong = Math.round(p[0] / tmp)
    return [gridLong, gridLat, p[2]]
  }

  createOptimizePoints2(points, center) {
    const result = []
    const scale = {
      x: (this.Rn * Math.cos((center[1] * Math.PI) / 180.0) * Math.PI) / 180.0,
      y: (this.Rn * Math.PI) / 180.0,
    }

    for (const p of points) {
      result.push([
        (p[0] - center[0]) * scale.x,
        (p[1] - center[1]) * scale.y,
        -p[2],
      ])
    }

    return result
  }

  getLatLength(centerLat) {
    return 2 * Math.PI * this.Rn * Math.cos((centerLat / 180) * Math.PI)
  }

  metersToPixels(meters, z, centerLat) {
    const pm = this.getLatLength(centerLat)
    const pp = Math.pow(2, z + 8)
    console.log(pm, pp, z)
    return (meters / pm) * pp
  }

  pixelsToMeters(pixels, z, centerLat) {
    const pm = this.getLatLength(centerLat)
    const pp = Math.pow(2, z + 8)
    return (pixels / pp) * pm
  }

  getUnitFromCoords(long, lat, unit) {
    const e = 0.0818191908426
    const b = (Math.PI * lat) / 180
    const phi = (1 - e * Math.sin(b)) / (1 + e * Math.sin(b))
    const O = Math.tan(Math.PI / 4 + b / 2) * Math.pow(phi, e / 2)
    const xp = (unit / 2) * (1 + long / 180)
    const yp = (unit / 2) * (1 - Math.log(O) / Math.PI)
    return [xp, yp]
  }

  getMetersFromCoords(long, lat, centerLat) {
    return this.getUnitFromCoords(long, lat, this.getLatLength(centerLat))
  }

  getTileFromCoords(long, lat, z, tileSize = 256) {
    const c = this.getUnitFromCoords(long, lat, Math.pow(2, z + 8))
    const xp = c[0]
    const yp = c[1]
    return {
      x: Math.floor(xp / tileSize),
      y: Math.floor(yp / tileSize),
      xp: xp,
      yp: yp,
    }
  }

  getTileBounds(x, y, z) {
    return {
      latitudeMin: 0,
      latitudeMax: 0,
      longitudeMin: 0,
      longitudeMax: 0,
    }
  }

  getTilePoints(x, y, z, points, tileSize, border = [0, 0], convert = null) {
    if (!convert) convert = (p) => p
    const sendPoints = []

    for (const idx of points) {
      const geo = convert(idx)
      const p = this.getTileFromCoords(geo[0], geo[1], z, tileSize)
      const dx = Math.round(p.xp - x * tileSize)
      const dy = Math.round(p.yp - y * tileSize)

      // собираем дополнительные точки вокруг тайла
      if (
        dx < 0 &&
        dx >= -border[0] &&
        dy >= -border[1] &&
        dy <= tileSize + border[1]
      ) {
        sendPoints.push(geo)
        continue
      }
      if (
        dx >= tileSize &&
        dx <= tileSize + border[0] &&
        dy >= -border[1] &&
        dy <= tileSize + border[1]
      ) {
        sendPoints.push(geo)
        continue
      }
      if (
        dy < 0 &&
        dy >= -border[1] &&
        dx >= -border[0] &&
        dx <= tileSize + border[0]
      ) {
        sendPoints.push(geo)
        continue
      }
      if (
        dy >= tileSize &&
        dy <= tileSize + border[1] &&
        dx >= -border[0] &&
        dx <= tileSize + border[0]
      ) {
        sendPoints.push(geo)
        continue
      }

      // проверяем попадает ли точка в тайл
      if (dx < 0 || dx >= tileSize) continue
      if (dy < 0 || dy >= tileSize) continue
      sendPoints.push(geo)
    }

    return sendPoints
  }
}

export default new KRM()
