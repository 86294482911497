import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledToggleButtonGroup } from './StyledButtons'
import { useContext } from 'react'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import { CircularProgress, ToggleButton, Tooltip } from '@mui/material'

function addToolTip(component, tooltip) {
  return !tooltip ? component : <Tooltip title={tooltip}>{component}</Tooltip>
}

export default function ToolGroup({
  value,
  onChange,
  buttons,
  loading,
  ...others
}) {
  const features = useContext(FeaturesContext)

  const handleChange = (e, v) => {
    if (v !== null) onChange(v)
  }

  return (
    <StyledToggleButtonGroup
      {...others}
      exclusive
      value={value}
      onChange={handleChange}
    >
      {buttons.map((item, index) =>
        !item.feature || features.includes(item.feature) ? (
          <ToggleButton
            key={index}
            value={item.value}
            disabled={item.disabled || loading}
          >
            {loading && value === item.value ? (
              <CircularProgress sx={{ mx: 0.5 }} size="1em" color="inherit" />
            ) : (
              addToolTip(item.icon, item.tooltip)
            )}
          </ToggleButton>
        ) : null
      )}
    </StyledToggleButtonGroup>
  )
}

ToolGroup.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string,
  loading: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      feature: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
}
