import React from 'react'
import System from '../../Layout/System'
import { Button } from '@mui/material'

function Home() {
  return (
    <System>
      <h1>Справка по редактору карт Практик</h1>
      <p>Находится в разработке</p>
      <Button variant="contained" href="/">
        Открыть редактор
      </Button>
    </System>
  )
}

export default Home
