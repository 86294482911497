const colors = {
  stroke: '#1565c099',
  fill: '#1565c022',
  point: '#15ff30ff',
  shadow: '#00000055',
}

function renderPoint(ctx, x, y) {
  const r = 3
  ctx.fillStyle = colors.shadow
  ctx.beginPath()
  ctx.arc(x + 1, y + 1, r, 0, 2 * Math.PI, false)
  ctx.fill()
  ctx.fillStyle = colors.point
  ctx.beginPath()
  ctx.arc(x, y, r, 0, 2 * Math.PI, false)
  ctx.fill()
}

function renderPaint(ctx, x, y, r) {
  ctx.fillStyle = colors.point
  ctx.beginPath()
  ctx.arc(x, y, r, 0, 2 * Math.PI, false)
  ctx.fill()
}

function renderLine(ctx, points, r) {
  if (points.length === 0) return
  ctx.strokeStyle = colors.point
  ctx.fillStyle = colors.point
  ctx.lineWidth = r * 2
  ctx.lineJoin = 'round'
  ctx.beginPath()
  ctx.arc(points[0][0], points[0][1], r, 0, 2 * Math.PI, false)
  const last = points.length - 1
  ctx.arc(points[last][0], points[last][1], r, 0, 2 * Math.PI, false)
  ctx.fill()
  ctx.beginPath()
  ctx.moveTo(points[0][0], points[0][1])
  for (let i = 1; i < points.length; i++) ctx.lineTo(points[i][0], points[i][1])
  ctx.stroke()
}

export { renderPoint, renderPaint, renderLine }
