import React, { useEffect } from 'react'
import { Box, Button, Dialog, DialogActions, TextField } from '@mui/material'
import PropTypes from 'prop-types'

export default function SessionNameDialog({
  onClose,
  selectedValue,
  open,
  doneTitle,
}) {
  const [value, setValue] = React.useState(selectedValue)

  useEffect(() => {
    if (open) setValue(selectedValue)
  }, [open])

  const handleClose = () => {
    onClose(selectedValue)
  }
  const handleChange = () => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          value={value}
          autoFocus
          margin="dense"
          label="Название сесии"
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
      <DialogActions sx={{ px: 2, pb: 2 }}>
        <Button variant="contained" onClick={handleChange}>
          {doneTitle || 'Готово'}
        </Button>
        <Button onClick={onClose}>Отмена</Button>
      </DialogActions>
    </Dialog>
  )
}

SessionNameDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  doneTitle: PropTypes.string,
}
