import React, { useLayoutEffect } from 'react'
import { Box, Button, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { HexColorPicker } from 'react-colorful'
import PaletteColorMark from './PaletteColorMark'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import styles from './Palette.module.css'
import {
  PaletteColors,
  updateShifts,
  maxByDepth,
  getMaxShift,
} from './PaletteColors'
import DoneIcon from '@mui/icons-material/Done'
import NumberField from '../Tools/NumberField'

const maxShift = getMaxShift()

function PaletteEditor({
  label,
  value,
  onChange,
  children,
  evenly,
  ...others
}) {
  const targetRef = React.useRef()
  const [selectedDepth, setSelectedDepth] = React.useState(0)
  const [selectedColor, setSelectedColor] = React.useState(value[0].color)
  const [colors, setColors] = React.useState([])
  const [canvasSize, setCanvasSize] = React.useState([10, 10])
  const [selected, setSelected] = React.useState(0)
  const [addPosition, setAddPosition] = React.useState(0)

  const selectColor = (index) => {
    if (!colors[index]) return
    if (!colors[index].depth) setSelectedDepth(NaN)
    else setSelectedDepth(colors[index].depth)
    setSelectedColor(colors[index].color)
    setSelected(index)
  }

  const handleChangeColor = (color) => {
    setSelectedColor(color)
  }

  const handleAdd = () => {
    let newColors = colors.slice()
    const color = '#000000'
    const shift = addPosition / canvasSize[0]
    const value =
      Math.round((shift / maxShift) * maxByDepth(colors).depth * 100) / 100
    newColors.push(newColors[newColors.length - 1])
    newColors[newColors.length - 2] = {
      color: color,
      shift: shift,
      value: value,
    }
    newColors = updateShifts(newColors)
    setColors(newColors)
    setSelectedDepth(value)
    setSelectedColor(color)
    setSelected(newColors.length - 2)
    onChange(newColors)
  }

  const handleRemove = (index) => {
    let newColors = colors.slice()
    newColors.splice(index, 1)
    newColors = updateShifts(newColors)
    setColors(newColors)
    selectColor(0)
    onChange(newColors)
  }

  const handleDragStart = (e, index) => {
    e.stopPropagation()
    e.preventDefault()
    selectColor(index)
    const drag = {
      x: e.clientX,
      y: e.clientY,
      index: index,
      shift: colors[index].shift,
      max: maxByDepth(colors).depth / maxByDepth(colors).shift,
    }
    window.onmousemove = (e) => handleDragMove(e, drag)
    window.onmouseup = () => {
      window.onmousemove = null
      window.onmouseup = null
      const newColors = updateShifts(colors)
      onChange(newColors)
    }
  }
  const handleDragMove = (e, drag) => {
    const newColors = colors.slice()
    if (!newColors[drag.index]) newColors[drag.index] = {}
    const ds = drag.shift + (e.clientX - drag.x) / canvasSize[0]
    newColors[drag.index].shift = ds < 0 ? 0 : ds > 1 ? 1 : ds
    newColors[drag.index].depth =
      Math.round(drag.max * newColors[drag.index].shift * 100) / 100
    setSelectedDepth(newColors[drag.index].depth)
    setColors(newColors)
  }

  const handleMouseMove = (e) => {
    setAddPosition(e.clientX - canvasSize[2].left)
  }

  const handleEvenly = () => {
    let newColors = colors.slice()
    newColors.sort((a, b) => (a.shift < b.shift ? -1 : 1))
    const step = newColors[newColors.length - 2].depth / (newColors.length - 1)
    for (let i = 0; i <= newColors.length - 2; i++)
      newColors[i].depth = (i + 1) * step
    newColors = updateShifts(newColors)
    setColors(newColors)
    onChange(newColors)
  }

  const handleApply = () => {
    let newColors = colors.slice()
    if (!newColors[selected]) newColors[selected] = {}
    newColors[selected].color = selectedColor
    if (selectedDepth !== 'max') newColors[selected].depth = selectedDepth
    newColors = updateShifts(newColors)
    setColors(newColors)
    selectColor(selected)
    onChange(newColors)
  }

  React.useEffect(() => {
    if (value && value !== colors) {
      setColors(updateShifts(value))
      selectColor(selected)
    }
  }, [value])

  React.useEffect(() => {
    if (evenly) handleEvenly()
  }, [evenly])

  useLayoutEffect(() => {
    if (targetRef.current) {
      setCanvasSize([
        targetRef.current.offsetWidth,
        targetRef.current.offsetHeight,
        targetRef.current.getBoundingClientRect(),
      ])
    }
  }, [])

  return (
    <Box {...others}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          {children}
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <NumberField
              label="Глубина (м)"
              size="small"
              width={120}
              max={50}
              step={0.1}
              min={0.1}
              disabled={selected === colors.length - 1}
              value={selectedDepth}
              onChange={(value) => setSelectedDepth(value)}
            />
            <Button variant="outlined" onClick={handleApply}>
              <DoneIcon />
            </Button>
          </Stack>
          <Box sx={{ mt: 2 }}>
            <HexColorPicker
              style={{ width: '100%' }}
              color={selectedColor}
              onChange={handleChangeColor}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ height: 80 }} style={{ position: 'relative' }}>
        <Box onMouseMove={handleMouseMove} ref={targetRef}>
          <PaletteColors
            className={styles.image}
            colors={colors}
            fixed={true}
            sx={{ height: 20 }}
          >
            <AddCircleIcon
              onClick={handleAdd}
              fontSize="small"
              className={styles.add}
              sx={{ left: addPosition }}
            />
          </PaletteColors>
        </Box>
        <PaletteColorMark
          first={true}
          color={{ shift: 0, label: '0' }}
          remove={false}
        />
        {colors.map((item, index) => (
          <PaletteColorMark
            key={index}
            last={index === colors.length - 1}
            color={item}
            selected={index === selected}
            onDialog={() => selectColor(index)}
            onDragStart={(e) => handleDragStart(e, index)}
            onRemove={() => handleRemove(index)}
            remove={colors.length > 2}
          />
        ))}
      </Box>
    </Box>
  )
}

PaletteEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  maxDepth: PropTypes.any,
  regarding: PropTypes.bool,
  onChange: PropTypes.func,
  fixed: PropTypes.bool,
  evenly: PropTypes.bool,
  children: PropTypes.any,
}

export default PaletteEditor
