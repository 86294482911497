import React from 'react'
import PropTypes from 'prop-types'
import DialogFrame from '../DialogFrame'
import SessionFilter from './SessionFilter'
import GridFilter from './GridFilter'
import { FormControl, MenuItem, Select } from '@mui/material'

const filters = [
  {
    name: 'Проредить сесии',
    node: (nodeProps) => <SessionFilter {...nodeProps} />,
  },
  {
    name: 'По сетке',
    node: (nodeProps) => <GridFilter {...nodeProps} />,
  },
]

function FilterDialog({ onSelect, onDataChange }) {
  const [type, setType] = React.useState(0)
  return (
    <DialogFrame title="Фильтры">
      <FormControl fullWidth sx={{ my: 2 }}>
        <Select
          sx={{ display: 'block' }}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {filters.map((item, key) => (
            <MenuItem value={key} key={key}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {filters[type].node({ onSelect: onSelect, onChange: onDataChange })}
    </DialogFrame>
  )
}

FilterDialog.propTypes = {
  onSelect: PropTypes.func,
  onDataChange: PropTypes.func,
  options: PropTypes.any,
}

export default FilterDialog
