import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { PaletteColors, updateShifts } from './PaletteColors'

function PaletteViewer({ named, marked, fixed, value, ...others }) {
  const [marks, setMarks] = React.useState([])

  React.useEffect(() => {
    setMarks(!marked ? [] : updateShifts(value.colors))
  }, [value, marked])

  return (
    <Box {...others}>
      <PaletteColors
        sx={{ height: 26, width: 1, position: 'relative' }}
        fixed={fixed}
        colors={value.colors}
      >
        {marks.map((item, index) =>
          index === marks.length - 1 ? null : (
            <Typography
              key={index}
              variant="caption"
              sx={{
                minWidth: 32,
                height: 18,
                position: 'absolute',
                left: item.shift * 100 + '%',
                mt: '4px',
                ml: '-16px',
                bgcolor: 'background.paper',
                color: 'text.primary',
                borderRadius: 1,
                textAlign: 'center',
                fontSize: 12,
              }}
            >
              {Math.round(item.depth * 10) / 10}м
            </Typography>
          )
        )}
      </PaletteColors>
      {named ? <Typography variant="caption">{value.name}</Typography> : null}
    </Box>
  )
}

PaletteViewer.propTypes = {
  named: PropTypes.bool,
  marked: PropTypes.bool,
  fixed: PropTypes.bool,
  value: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default PaletteViewer
