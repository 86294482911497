// import moment from 'moment'
import uuid from 'react-uuid'

class FileData {
  getParentSessionIndex(sessions) {
    let minDate = null
    let minIndex = -1
    let i = 0
    for (const s of sessions) {
      if (minDate === null || s.timePosition < minDate) {
        minDate = s.timePosition
        minIndex = i
      }
      i++
    }
    return minIndex
  }

  normalize(file) {
    file.active = true
    if (!file.uuid) file.uuid = uuid()
    for (const s of file.layers.sessions) {
      for (const p of s.points)
        if (p[2] > 0) {
          file.center = [p[0], p[1]]
          break
        }
      if (file.center) break
    }
    return file
  }

  prepareSessionsToSave(sessions, name, useOldUuid = false) {
    const result = []
    const index = this.getParentSessionIndex(sessions)
    if (index < 0) return []
    const parentSessionId = useOldUuid ? sessions[index].sessionId : uuid()
    let i = 0
    for (const s of sessions) {
      const cs = {
        id: s.id,
        sessionId: useOldUuid ? s.sessionId : uuid(),
        date: s.date,
        name: s.name,
        points: [],
      }
      if (index === i) {
        cs.sessionId = parentSessionId
        cs.name = name
      } else cs.parentSessionId = parentSessionId
      for (const p of s.points) {
        cs.points.push({
          id: p.id,
          depth: p.depth,
          latitude: p.latitude,
          longitude: p.longitude,
          timePosition: p.timePosition,
        })
      }
      result.push(cs)
      i++
    }
    return result
  }
}

export default new FileData()
