import React from 'react'
import PropTypes from 'prop-types'
import CanvasLayer from './CanvasLayer'
import { getGeoByPixel } from '../PointsMaker/PointsConvertor'

export default class Selector extends React.Component {
  constructor(props) {
    super(props)
    this.canvas = new CanvasLayer('points-selector-canvas')
    this.handlerSelectEnd = this.handlerSelectEnd.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.canvasCont = React.createRef()
  }

  handleMouseMove(e) {
    if (this.props.onPositionChanged) {
      const geo = getGeoByPixel(this.props.mapState, {
        x: e.clientX,
        y: e.clientY,
      })
      this.props.onPositionChanged({ geopos: [geo.x, geo.y] })
    }
  }

  handlerSelectEnd(e) {
    const selection = this.canvas.onMouseUp(e)
    if (selection) this.props.onSelect(selection)
  }

  componentDidMount() {
    this.canvas.initCanvas()
    this.canvas.resize({
      camera: this.props.mapState.camera,
      size: {
        x: this.canvasCont.current.offsetWidth,
        y: this.canvasCont.current.offsetHeight,
      },
    })
    this.canvas.setData(this.props.points, this.props.selection)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.mapState !== this.props.mapState ||
      prevProps.points !== this.props.points ||
      prevProps.ready !== this.props.ready
    ) {
      if (this.props.ready) {
        this.canvas.initCanvas()
        this.canvas.resize({
          camera: this.props.mapState.camera,
          size: {
            x: this.canvasCont.current.offsetWidth,
            y: this.canvasCont.current.offsetHeight,
          },
        })
      }
      // console.log('mapState change')
    }
    if (prevProps.showPoints !== this.props.showPoints)
      this.canvas.setShowPoints(this.props.showPoints)
    if (prevProps.toolOptions !== this.props.toolOptions)
      this.canvas.setTool(this.props.toolOptions)
    if (
      prevProps.points !== this.props.points ||
      prevProps.selection !== this.props.selection
    ) {
      this.canvas.setData(this.props.points, this.props.selection)
      this.canvas.render()
      // console.log('points change')
    }
  }

  render() {
    return (
      <div
        ref={this.canvasCont}
        style={{
          display: this.props.ready ? 'block' : 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        onMouseMove={this.handleMouseMove}
      >
        <canvas
          id="points-selector-canvas"
          onTouchStart={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.canvas.onMouseDown(e.changedTouches[0])
          }}
          onTouchMove={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.canvas.onMouseMove(e.changedTouches[0])
          }}
          onTouchEnd={(e) => this.handlerSelectEnd(e.changedTouches[0])}
          onMouseDown={(e) => this.canvas.onMouseDown(e)}
          onMouseMove={(e) => this.canvas.onMouseMove(e)}
          onMouseUp={(e) => this.handlerSelectEnd(e)}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  }
}

Selector.propTypes = {
  points: PropTypes.any.isRequired,
  selection: PropTypes.array.isRequired,
  mapState: PropTypes.object.isRequired,
  ready: PropTypes.bool,
  showPoints: PropTypes.bool,
  toolOptions: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onPositionChanged: PropTypes.func,
}
