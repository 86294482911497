import React, { useContext, useState } from 'react'
import { Box, Typography, IconButton, Stack, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import { PointsTable } from './PointsTable'
import { SessionsTable } from './SessionsTable'
import SortButton from './SortButton'
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined'
import ListIcon from '@mui/icons-material/List'
import DialogFrame from '../DialogFrame'
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded'
import DataContext from '../../../Context/DataContext'
import HistoryIcon from '@mui/icons-material/History'
import Timeline from './Timeline'
import ToolGroup from '../../Tools/ToolGroup'

const sortVariants = [
  {
    name: 'По дате',
    key: 'time',
    order: 'asc',
  },
  {
    name: 'По дате',
    key: 'time',
    order: 'desc',
  },
  {
    name: 'По глубине',
    key: 'depth',
    order: 'asc',
  },
  {
    name: 'По глубине',
    key: 'depth',
    order: 'desc',
  },
]

const calculateSelected = (selection) => {
  let t = 0
  for (const p in selection) if (selection[p] === true) t++
  return t
}

function PointsDialog({ opened, onOpen, selection, onSelect, onDataChange }) {
  const manager = useContext(DataContext)
  const [view, setView] = useState('points')
  const [sortIndex, setSort] = useState(0)

  const changeSort = (index) => {
    setSort(index)
  }

  const handlerCheck = (selection) => {
    onSelect(selection)
  }

  const handleChangeView = (nextView) => {
    if (nextView !== null) setView(nextView)
  }

  const handlerCollapseAll = () => {
    for (let s = 0; s < manager.getData().sessions.length; s++)
      opened[s] = false
    onOpen(opened)
  }

  const selectCount = calculateSelected(selection)

  /*
  const [sessionDialogOpen, setSessionDialogOpen] = React.useState(false)

  const handleCreateSession = () => {
    setSessionDialogOpen(true)
  }
  const handleCreateSessionDone = (name) => {
    setSessionDialogOpen(false)
    onDataChange(manager.sessionCreate(name))
  } */

  return (
    <DialogFrame
      title={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          Точки
          <Typography variant="caption" sx={{ my: 1 }}>
            Выбрано: {selectCount} / {manager.getPointsCount()}
          </Typography>
        </Stack>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <ToolGroup
            size="small"
            value={view}
            onChange={handleChangeView}
            sx={{ flexGrow: 1, ml: -0.5 }}
            buttons={[
              {
                value: 'points',
                tooltip: 'Точки',
                icon: <ListIcon />,
              },
              {
                value: 'sessions',
                tooltip: 'Сессии',
                icon: <TopicOutlinedIcon />,
              },
              {
                value: 'history',
                tooltip: 'История изменений',
                icon: <HistoryIcon />,
              },
            ]}
          />
          {view !== 'sessions' ? (
            <Box />
          ) : (
            <Box>
              <IconButton onClick={handlerCollapseAll}>
                <UnfoldLessRoundedIcon />
              </IconButton>
            </Box>
          )}

          {view === 'history' ? null : (
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          )}
          {view === 'history' ? null : (
            <SortButton
              onChange={changeSort}
              index={sortIndex}
              variants={sortVariants}
            />
          )}
        </Box>

        <Box sx={{ flexGrow: 1, mx: -2, mt: 2 }}>
          {view === 'points' ? (
            <PointsTable
              onCheck={handlerCheck}
              selection={selection}
              sort={sortVariants[sortIndex]}
            />
          ) : null}
          {view === 'sessions' ? (
            <SessionsTable
              opened={opened}
              onOpen={onOpen}
              onDataChange={onDataChange}
              onCheck={handlerCheck}
              selection={selection}
              sort={sortVariants[sortIndex]}
            />
          ) : null}
          {view === 'history' ? <Timeline /> : null}
        </Box>
      </Box>
    </DialogFrame>
  )
}

PointsDialog.propTypes = {
  opened: PropTypes.array.isRequired,
  selection: PropTypes.array,
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
  onDataChange: PropTypes.func,
}

export default PointsDialog
