import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const formats = [
  ['Большое', 2500, 2500],
  ['Среднее', 1200, 1200],
  ['Маленькое', 800, 800],
]

export default function ImageOptions({ format, options, onChange }) {
  return format === 'image' ? (
    <Box>
      <FormControl fullWidth sx={{ my: 1 }} size="small">
        <InputLabel>Размер изображения</InputLabel>
        <Select
          sx={{ display: 'block' }}
          value={options.imageFormat}
          label="Размер изображения"
          onChange={(e) => onChange({ imageFormat: e.target.value })}
        >
          {formats.map((item, key) => (
            <MenuItem value={item[1] + 'x' + item[2]} key={key}>
              {item[0]} {item[1]}x{item[2]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        sx={{ mb: 1 }}
        control={
          <Checkbox
            disabled={true}
            checked={options.background === true}
            onChange={(event) => onChange({ background: event.target.checked })}
          />
        }
        label="Прозрачный фон"
      />
    </Box>
  ) : null
}

ImageOptions.propTypes = {
  format: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}
