import React from 'react'
import { SvgIcon } from '@mui/material'

const DottedSquareIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="-1 -1 8.3499997 8.3499997">
    <g transform="matrix(1.3333333,0,0,1.3333331,-6.6523806,-7.2892831)">
      <path d="m 5.3034756,5.4669633 c -0.17406,0 -0.31419,0.14013 -0.31419,0.31419 V 5.9961258 H 5.5184481 V 5.4669633 Z m 0.7441425,0 V 5.9961258 H 6.5767806 V 5.4669633 Z m 1.0583324,0 V 5.9961258 H 7.635113 V 5.4669633 Z m 1.0583326,0 V 5.9961258 H 8.693453 V 5.4669633 Z m 1.0583325,0 V 5.9961258 H 9.7517855 V 5.7811533 c 0,-0.17406 -0.14013,-0.31419 -0.3141974,-0.31419 z m -4.23333,1.0583325 v 0.52917 h 0.5291625 v -0.52917 z m 4.23333,0 v 0.52917 h 0.5291699 v -0.52917 z m -4.23333,1.0583251 V 8.1127908 H 5.5184481 V 7.5836209 Z m 4.23333,0 V 8.1127908 H 9.7517855 V 7.5836209 Z m -4.23333,1.05834 V 9.1711232 H 5.5184481 V 8.6419609 Z m 4.23333,0 V 9.1711232 H 9.7517855 V 8.6419609 Z m -4.23333,1.0583324 v 0.2149725 c 0,0.1740682 0.14013,0.3141982 0.31419,0.3141982 H 5.5184481 V 9.7002933 Z m 1.0583325,0 V 10.229464 H 6.5767806 V 9.7002933 Z m 1.0583324,0 V 10.229464 H 7.635113 V 9.7002933 Z m 1.0583326,0 V 10.229464 H 8.693453 V 9.7002933 Z m 1.0583325,0 v 0.5291707 h 0.2149725 c 0.1740674,0 0.3141974,-0.14013 0.3141974,-0.3141982 V 9.7002933 Z" />
    </g>
  </SvgIcon>
))

DottedSquareIcon.displayName = 'DottedSquareIcon'

export default DottedSquareIcon
