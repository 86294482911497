import * as React from 'react'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Check from '@mui/icons-material/Check'
import PropTypes from 'prop-types'
import LayersIcon from '@mui/icons-material/Layers'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StyledToggleButton } from '../Tools'
import { Box } from '@mui/material'

export default function LayersMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleGroundLayer = (v) => {
    const options = { ...props.options }
    options.groundLayer = v
    props.onChange(options)
    handleClose()
  }
  const handlePoints = (v) => {
    const options = { ...props.options }
    options.showPoints = v
    props.onChange(options)
    handleClose()
  }

  const handleMeter = (v) => {
    const options = { ...props.options }
    options.showMeter = v
    props.onChange(options)
    handleClose()
  }

  return (
    <Box>
      <StyledToggleButton
        size={props.size}
        value="layer"
        color={open ? 'primary.main' : null}
        onClick={handleClick}
      >
        <LayersIcon />
        <ArrowDropDownIcon />
      </StyledToggleButton>
      <Menu
        dense="true"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {[
          ['scheme', 'Схема'],
          ['satellite', 'Спутник'],
          ['hybrid', 'Гибрид'],
        ].map((item, index) => (
          <MenuItem key={index} onClick={() => handleGroundLayer(item[0])}>
            <ListItemIcon>
              {props.options.groundLayer === item[0] ? <Check /> : null}
            </ListItemIcon>
            {item[1]}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={() => handlePoints(!props.options.showPoints)}>
          <ListItemIcon>
            {props.options.showPoints ? <Check /> : null}
          </ListItemIcon>
          Показать точки измерений
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleMeter(!props.options.showMeter)}>
          <ListItemIcon>
            {props.options.showMeter ? <Check /> : null}
          </ListItemIcon>
          Показать глубиномер
        </MenuItem>
      </Menu>
    </Box>
  )
}

LayersMenu.propTypes = {
  size: PropTypes.any,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
}
