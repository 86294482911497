import React, { useContext } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import CheckAndValue from './CheckAndValue'
import DialogFrame from '../DialogFrame'
import DataContext from '../../../Context/DataContext'
import FeaturesContext from '../../../FeatureToggle/FeaturesContext'
import PaletteViewer from '../../Palette/PaletteViewer'
import PaletteDialog from '../../Palette/PaletteDialog'

function SettingsDialog(props) {
  const features = useContext(FeaturesContext)
  const manager = useContext(DataContext)

  const [openPalette, setOpenPalette] = React.useState(false)

  const optionChange = (data) => {
    const options = { ...props.options }
    for (const d in data) options[d] = data[d]
    props.onOptionsChange(options)
  }

  return (
    <DialogFrame title="Настройки карты">
      <Box component="form" noValidate autoComplete="off">
        <Box sx={{ my: 3 }}>
          <Typography sx={{ display: 'block', mb: 1 }} variant="caption">
            По каким точкам строить карту
          </Typography>
          <ToggleButtonGroup
            value={props.options.source}
            color="primary"
            exclusive
            onChange={(e, v) => {
              if (v !== null) optionChange({ source: v })
            }}
          >
            <ToggleButton value="all">По всем</ToggleButton>
            <ToggleButton value="selected">По выбранным</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ my: 1 }}>
          <Select
            value={props.options.mapType}
            displayEmpty
            fullWidth
            onChange={(e) => optionChange({ mapType: e.target.value })}
          >
            <MenuItem value="depth">Карта глубин</MenuItem>
            <MenuItem value="variation">Карта перепадов</MenuItem>
          </Select>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.options.isobath === true}
              onChange={(event, checked) => optionChange({ isobath: checked })}
            />
          }
          label="Показывать изобаты"
        />
        <CheckAndValue
          label="Свой шаг изобат"
          desc="Шаг (м)"
          value={props.options.isobathStep}
          checked={props.options.isobathCustom}
          onToggle={(v) => optionChange({ isobathCustom: v })}
          onChange={(v) => optionChange({ isobathStep: v })}
          sx={{ my: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.options.minMaxOn}
              onChange={() =>
                optionChange({ minMaxOn: !props.options.minMaxOn })
              }
            />
          }
          label="Отображать значения глубины"
        />
        {!features.includes('GRID_POINTS') ? null : (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.options.showGridPoints === true}
                onChange={(event, checked) =>
                  optionChange({ showGridPoints: checked })
                }
              />
            }
            label="Точки сетки построения"
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={props.options.showPoints === true}
              onChange={() =>
                optionChange({ showPoints: !props.options.showPoints })
              }
            />
          }
          label="Точки измерения"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.options.showTracks === true}
              onChange={() =>
                optionChange({ showTracks: !props.options.showTracks })
              }
            />
          }
          label="Показать треки"
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="caption">Прозрачность карты</Typography>
          <Slider
            defaultValue={props.options.opacity}
            valueLabelDisplay="auto"
            step={10}
            marks
            min={10}
            max={100}
            onChangeCommitted={(e, v) => optionChange({ opacity: v })}
          />
        </Box>
        {!features.includes('PALETTES') ? null : (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ width: 1, mb: 1 }}>
              <InputLabel>Палитра</InputLabel>
              <Select
                value={props.options.paletteType}
                label="Палитра"
                onChange={(e) => optionChange({ paletteType: e.target.value })}
              >
                <MenuItem value="gradient">Градиентная</MenuItem>
                <MenuItem value="fixed">Фиксированная</MenuItem>
              </Select>
            </FormControl>
            {props.options.paletteType === 'gradient' ? (
              <CheckAndValue
                label="Фиксированный масштаб глубины"
                desc="Все что больше указанной глубины окрасить как минимум"
                value={props.options.customDepth}
                altValue={manager.getMaxDepth()}
                checked={props.options.customDepthOn}
                onToggle={(v) => optionChange({ customDepthOn: v })}
                onChange={(v) => optionChange({ customDepth: v })}
                sx={{ my: 2 }}
              />
            ) : (
              <Box>
                <FormControl fullWidth sx={{ my: 1 }}>
                  <InputLabel>Выбор палитры</InputLabel>
                  <Select
                    value={props.options.activePaletteIndex}
                    label="Выбор палитры"
                    onChange={(e) =>
                      optionChange({
                        palette: manager.getPalette(e.target.value).colors,
                        activePaletteIndex: e.target.value,
                      })
                    }
                  >
                    {manager.getData().palettes.map((item, index) => (
                      <MenuItem value={index} key={index}>
                        <PaletteViewer
                          sx={{ minWidth: '280px', width: '80%' }}
                          named={true}
                          marked={true}
                          fixed={true}
                          value={item}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mb: 3 }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      const res = manager.newPalette()
                      props.onDataChange(res.manager)
                      optionChange({ activePaletteIndex: res.index })
                      setOpenPalette(true)
                    }}
                  >
                    Создать
                  </Button>
                  <Stack direction="row" spacing={1}>
                    <Button size="small" onClick={() => setOpenPalette(true)}>
                      Изменить
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => {
                        props.onDataChange(
                          manager.removePalette(
                            props.options.activePaletteIndex
                          )
                        )
                        let n = props.options.activePaletteIndex
                        if (n >= manager.getData().palettes.length)
                          n = manager.getData().palettes.length - 1
                        optionChange({ activePaletteIndex: n })
                      }}
                    >
                      Удалить
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            )}
            <PaletteDialog
              open={openPalette}
              value={manager.getPalette(props.options.activePaletteIndex)}
              onChange={(newPalette) => {
                props.onDataChange(
                  manager.updatePalette(
                    props.options.activePaletteIndex,
                    newPalette
                  )
                )
                optionChange({ palette: newPalette.colors })
                setOpenPalette(false)
              }}
              onClose={() => setOpenPalette(false)}
            />
          </Box>
        )}
      </Box>
    </DialogFrame>
  )
}

SettingsDialog.propTypes = {
  options: PropTypes.object.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
  onDataChange: PropTypes.func.isRequired,
}

export default SettingsDialog
