import * as React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

function PopupDialog(props) {
  const handleClose = () => {
    if (props.onClose) props.onClose()
  }

  return (
    <BootstrapDialog
      open={props.open}
      onClose={handleClose}
      maxWidth={props.maxWidth}
      fullWidth={true}
      scroll="paper"
    >
      <BootstrapDialogTitle onClose={handleClose}>
        {props.title}
      </BootstrapDialogTitle>
      {props.children}
    </BootstrapDialog>
  )
}

PopupDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  maxWidth: PropTypes.string,
}

export { PopupDialog as default, BootstrapDialog, BootstrapDialogTitle }
