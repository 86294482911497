import React from 'react'
import EditorDialog from './EditorDialog'
import EditorTools from './EditorTools'
import EditorMap from './EditorMap'
import styles from './Editor.module.css'
import { Box, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import md5 from 'md5'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DataManager from '../../Data/DataManager'
import DataProvider from '../../Context/DataProvider'
import ServiceDialog from '../Dialog/ServiceDialog/ServiceDialog'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import SettingsProvider from '../../Context/SettingsProvider'

const tabHeight = 85

const createUuid = (version, options) => {
  const fields = [
    'session',
    'source',
    // 'mapType',
    // 'isobath',
    'isobathCustom',
    // 'minMaxOn',
    // 'minMaxRadius',
  ]
  if (options.isobathCustom) fields.push('isobathStep')
  if (options.paletteType === 'gradient') {
    fields.push('customDepthOn')
    fields.push('customDepth')
  }
  let s = version
  for (const f of fields) s += String(options[f])
  if (options.paletteType === 'fixed')
    s = options.palette.reduce(
      (prev, value) => prev + value.color + '-' + value.value,
      s
    )
  return md5(s)
}

export default class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: [],
      selection: [],
      options: {
        uuid: '',
        groundLayer: 'scheme',
        source: 'all',
        session: Math.random(),
        mapType: 'depth',
        customDepthOn: false,
        customDepth: 5,
        isobath: true,
        isobathCustom: false,
        isobathStep: 0.5,
        minMaxFilter: true,
        minMaxOn: true,
        minMaxRadius: 0,
        minMaxLevel: 0,
        showGridPoints: false,
        showPoints: false,
        showTracks: false,
        showMeter: true,
        opacity: 100,
        paletteType: 'gradient',
        palette: [],
        activePaletteIndex: 0,
      },
      settings: {
        tile: {},
        grid: Array(12).fill(5).concat([4, 4, 4, 4, 3, 3, 2, 2, 1, 1]),
        border: {},
        darkTheme: false,
        proMode: false,
        serviceDialog: false,
      },
      tool: 'hand',
      toolOptions: {},
      panel: !props.isMobile,
    }
    this.state.dataManager = new DataManager(this.state.settings)
    this.state.options.palette = this.state.dataManager.getPalette(
      this.state.options.activePaletteIndex
    ).colors

    this.setSelection = this.setSelection.bind(this)
    this.setOptions = this.setOptions.bind(this)
    this.handleFilesChange = this.handleFilesChange.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleSettingsChange = this.handleSettingsChange.bind(this)
    this.handleToolChange = this.handleToolChange.bind(this)
    this.handleAction = this.handleAction.bind(this)
  }

  handleAction(event) {
    switch (event.action) {
      case 'reset-cache': {
        const newOptions = { ...this.state.options }
        newOptions.session = Math.random()
        newOptions.uuid = createUuid(
          this.state.dataManager.getVersion(),
          newOptions
        )
        this.setState({ options: newOptions })
        this.editorMapRef.resetCache()
        break
      }
      case 'redo':
      case 'undo':
        this.handleDataChange(this.state.dataManager.clone())
        break
    }
  }

  componentDidMount() {
    fetch('/api/v1/settings', { method: 'GET' }).then((response) => {
      response.json().then((result) => this.handleSettingsChange(result))
    })
  }

  setSelection(selection) {
    this.setState({ selection: selection })
    this.state.dataManager.setSelection(
      selection,
      this.state.options.source === 'selected'
    )
    if (this.state.options.source !== 'all') {
      const options = { ...this.state.options }
      options.uuid = createUuid(this.state.dataManager.getVersion(), options)
      this.setState({ options: options })
    }
    // if (this.state.options.showPoints) this.editorMapRef.updatePointsLayer(true)
  }

  setOptions(options) {
    this.state.dataManager.setSelection(
      this.state.selection,
      options.source === 'selected'
    )
    options.uuid = createUuid(this.state.dataManager.getVersion(), options)
    this.setState({ options: options })
    // this.editorMapRef.updateTilesLayer()
  }

  handleSettingsChange(settings) {
    const update = !!settings.grid
    for (const p in this.state.settings)
      if (settings[p] === undefined) settings[p] = this.state.settings[p]
    this.setState({ settings: settings })
    if (update) this.state.dataManager.updateSettings(settings)
  }

  handleDataChange(manager, selection = [], opened = []) {
    manager.setSelection(selection, false)
    const newState = {
      opened: opened,
      selection: selection,
      dataManager: manager,
      options: { ...this.state.options },
    }
    newState.options.uuid = createUuid(manager.getVersion(), this.state.options)
    this.setState(newState)
  }

  handleFilesChange(event) {
    if (event.manager) {
      const manager = event.manager
      const options = { ...this.state.options }
      options.uuid = createUuid(manager.getVersion(), options)
      this.setState({
        selection: [],
        opened: [],
        options: options,
        data: manager,
      })
      if (event.findOnMap) this.editorMapRef.setBounds(manager.getAllBounds())
      // this.editorMapRef.updateTilesLayer()
    }
    if (event.showFileIndex !== undefined)
      this.editorMapRef.setBounds(
        this.state.dataManager.getFileBounds(event.showFileIndex)
      )
  }

  handleToolChange(tool, options) {
    const state = {}
    if (tool) state.tool = tool
    if (options) state.toolOptions = options
    this.setState(state)
  }

  render() {
    const panelWidth = this.props.isMobile ? '100wh' : 375
    return (
      <SettingsProvider data={this.state.settings}>
        <DataProvider data={this.state.dataManager}>
          <Box className={styles.layout}>
            <Box
              className={styles.container}
              sx={this.props.isMobile ? { pt: 0, width: 1 } : { pt: 2 }}
            >
              <Box
                className={styles.tools}
                sx={
                  this.props.isMobile
                    ? {
                        mx: 0,
                        borderRadius: 0,
                        minWidth: 1,
                      }
                    : { mx: 2, borderRadius: 2 }
                }
              >
                <EditorTools
                  selection={this.state.selection}
                  options={this.state.options}
                  tool={this.state.tool}
                  toolOptions={this.state.toolOptions}
                  onToolChange={this.handleToolChange}
                  onDataChange={this.handleDataChange}
                  onSelect={this.setSelection}
                  onOptionsChange={this.setOptions}
                  isMobile={this.props.isMobile}
                  onAction={this.handleAction}
                />
              </Box>
            </Box>
            <Box
              className={styles.map}
              sx={{
                right:
                  this.state.panel && !this.props.isMobile ? panelWidth : 0,
              }}
            >
              <EditorMap
                isMobile={this.props.isMobile}
                selection={this.state.selection}
                options={this.state.options}
                onSelect={this.setSelection}
                onDataChange={this.handleDataChange}
                onToolChange={this.handleToolChange}
                map={{
                  startPosition: {
                    zoom: 8,
                    center: [37.64, 55.76],
                  },
                  tileSize: 256,
                  scale: 2,
                  border: this.state.settings.border,
                }}
                minTilesZoom={8}
                maxMarkersZoom={14}
                tool={this.state.tool}
                toolOptions={this.state.toolOptions}
                ref={(ref) => {
                  this.editorMapRef = ref
                }}
              />
            </Box>
            <Box
              className={styles.dialog}
              sx={{
                right:
                  this.state.panel || this.props.isMobile ? 0 : -panelWidth,
                height:
                  !this.props.isMobile || this.state.panel ? '100%' : tabHeight,
                width: panelWidth,
              }}
            >
              {this.props.isMobile ? null : (
                <IconButton
                  onClick={() => this.setState({ panel: !this.state.panel })}
                  variant="contained"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: '100%',
                    mt: 2,
                    mr: 1,
                    borderRadius: 2,
                    background: 'black',
                    color: 'white',
                  }}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={{
                      transform: this.state.panel ? null : 'rotate(180deg)',
                    }}
                  />
                </IconButton>
              )}
              <EditorDialog
                tabHeight={tabHeight}
                dense={!this.state.panel}
                onUndense={(v) => this.setState({ panel: v })}
                isMobile={this.props.isMobile}
                opened={this.state.opened}
                onOpen={(o) => this.setState({ opened: o })}
                options={this.state.options}
                onOptionsChange={this.setOptions}
                onDataChange={this.handleDataChange}
                onFilesChange={this.handleFilesChange}
                sessions={this.state.sessions}
                points={this.state.points}
                selection={this.state.selection}
                onSelect={this.setSelection}
                settings={this.state.settings}
                onSettingsChange={this.handleSettingsChange}
              />
            </Box>
          </Box>
          {this.context.includes('SERVICE') ? (
            <ServiceDialog
              open={this.state.settings.serviceDialog}
              settings={this.state.settings}
              onClose={() =>
                this.handleSettingsChange({ serviceDialog: false })
              }
              onChange={this.handleSettingsChange}
            />
          ) : null}
        </DataProvider>
      </SettingsProvider>
    )
  }
}

Editor.contextType = FeaturesContext

Editor.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}
