import { Box } from '@mui/material'
import { StyledDivider } from './StyledButtons'
import React from 'react'
import PropTypes from 'prop-types'
import ColorizeRoundedIcon from '@mui/icons-material/ColorizeRounded'
import NumberField from './NumberField'
import ToolButton from './ToolButton'
import BrushRoundedIcon from '@mui/icons-material/BrushRounded'
import ToolGroup from './ToolGroup'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded'
import LoadingButton from '../../Layout/Components/LoadingButton'
import DoneIcon from '@mui/icons-material/Done'

function AddTools(props) {
  // const manager = React.useContext(DataContext)

  const options = props.options[props.tool] || {
    brush: 'point',
    lastBrush: 'point',
    depth: 1.15,
    apply: false,
    loading: false,
  }

  const handleChange = (value) => {
    const newOptions = { ...options }
    for (const p in value) newOptions[p] = value[p]
    props.onOptionsChange(newOptions)
  }

  const handleApply = () => {
    const newOptions = { ...options }
    newOptions.apply = true
    newOptions.loading = true
    props.onOptionsChange(newOptions)
  }

  return props.tool === 'add' ? (
    <Box
      sx={{
        p: 0.6,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <ToolGroup
        size="small"
        value={options.brush}
        loading={options.loading}
        onChange={(v) => handleChange({ brush: v })}
        buttons={[
          {
            value: 'point',
            tooltip: 'Точка',
            icon: <GpsFixedRoundedIcon />,
            feature: 'ADD_ONE',
          },
          {
            value: 'paint',
            tooltip: 'Кисть',
            icon: <BrushRoundedIcon />,
            feature: 'ADD_PAINT',
          },
          {
            value: 'line',
            tooltip: 'Вдоль ломаной',
            icon: <TimelineRoundedIcon />,
            feature: 'ADD_LINE',
          },
        ]}
      />
      <StyledDivider />
      <ToolButton
        size="small"
        tooltip="Пипетка глубины"
        selected={options.brush === 'pipette'}
        onClick={() => {
          if (options.brush !== 'pipette')
            handleChange({ brush: 'pipette', lastBrush: options.brush })
          else handleChange({ brush: options.lastBrush })
        }}
      >
        <ColorizeRoundedIcon />
      </ToolButton>
      <NumberField
        sx={{ mx: 1 }}
        label="Глубина"
        min={0.05}
        value={options.depth}
        step={0.05}
        onChange={(v) => handleChange({ depth: v })}
      />
      {options.brush !== 'line' ? null : (
        <LoadingButton
          loading={options.loading}
          variant="text"
          onClick={handleApply}
          startIcon={<DoneIcon />}
        >
          Применить
        </LoadingButton>
      )}
    </Box>
  ) : null
}

AddTools.propTypes = {
  tool: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onOptionsChange: PropTypes.func,
  onDataChange: PropTypes.func,
}

export default AddTools
