class MapMarkers {
  createRulerMarker(value, onClose) {
    const e = document.createElement('div')
    e.style.background = 'white'
    e.style.borderRadius = '50%'
    e.style.boxShadow = '0 2px 5px rgba(0,0,0,0.5)'
    e.style.width = '16px'
    e.style.height = '16px'
    e.style.margin = '-8px 0 0 -8px'
    e.style.position = 'relative'
    e.style.padding = '2px'
    const e0 = document.createElement('div')
    e0.style.width = '100%'
    e0.style.height = '100%'
    e0.style.borderRadius = '50%'
    e0.style.border = '2px solid grey'
    e.append(e0)
    const t = document.createElement('div')
    if (value) {
      t.style.position = 'absolute'
      t.style.padding = '3px 5px'
      t.style.background = 'white'
      t.style.borderRadius = '3px'
      t.style.boxShadow = '0 2px 5px rgba(0,0,0,0.5)'
      t.style.right = '100%'
      t.style.bottom = '100%'
      t.style.margin = '0 0 0 5px'
      t.style.fontSize = '12px'
      t.style.color = 'black'
      t.style.whiteSpace = 'nowrap'
      t.innerHTML =
        '<span style="display:inline-block; vertical-align: middle;">' +
        value +
        '</span>'
      e.append(t)
      if (onClose) {
        const d = document.createElement('span')
        d.style.display = 'inline-block'
        d.style.marginLeft = '5px'
        d.style.verticalAlign = 'middle'
        d.style.color = 'grey'
        d.style.textAlign = 'center'
        d.style.fontSize = '16px'
        d.style.lineHeight = '12px'
        d.style.cursor = 'pointer'
        d.style.padding = '0'
        d.innerHTML = '×'
        d.onclick = onClose
        t.append(d)
      }
    }
    return [e, t.firstChild]
  }

  createFileMarker(name) {
    const e = document.createElement('div')
    e.style.background = 'white'
    e.style.borderRadius = '0 7px 7px 7px'
    e.style.boxShadow = '0 2px 5px rgba(0,0,0,0.5)'
    e.style.padding = '4px 6px'
    e.style.textAlign = 'center'
    e.style.whiteSpace = 'nowrap'
    e.innerHTML = name
    return e
  }
}

export default new MapMarkers()
