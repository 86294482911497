export function NonBlockingCalculate(count, chunksize, callback, finished) {
  let i = 0
  ;(function chunk() {
    const end = Math.min(i + chunksize, count)
    for (; i < end; ++i) {
      if (callback(i) === false) {
        // console.log('abort:', i, count)
        i = count
        return
      }
    }
    if (i < count) {
      // console.log('chunk done:', i, count)
      setTimeout(chunk, 10)
    } else {
      finished()
    }
  })()
}
