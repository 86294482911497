import { styled } from '@mui/material/styles'
import { Divider, ToggleButton, ToggleButtonGroup } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    /*
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    paddingRight: 0,
     */
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

const StyledDivider = () => {
  return (
    <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
  )
}

const StyledToggleButton = (props) => {
  const color = !props.color ? 'text.primary' : props.color
  return (
    <StyledToggleButtonGroup exclusive value={!props.selected ? null : 'value'}>
      <ToggleButton
        sx={{
          ...props.sx,
          border: 'none',
          color: !props.disabled ? color : 'text.disabled',
          minWidth: props.size === 'small' ? 25 : 45,
        }}
        onClick={props.onClick}
        disabled={props.disabled}
        value="value"
        size={props.size}
      >
        {props.children}
      </ToggleButton>
    </StyledToggleButtonGroup>
  )
}

StyledToggleButton.propTypes = {
  sx: PropTypes.object,
  size: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export { StyledToggleButtonGroup, StyledToggleButton, StyledDivider }
