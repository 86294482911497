import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import PopupTabsDialog from '../../../Layout/Dialog/PopupTabsDialog'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const Input = styled('input')({
  display: 'none',
})

function TilesTab({ table }) {
  return !table.titles || !table.table ? (
    <Chip label="Нет данных" />
  ) : (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {table.titles.map((item, index) => (
              <TableCell key={index}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {table.table.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {table.titles.map((field, cellIndex) => (
                <TableCell key={cellIndex}>{row[field]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TilesTab.propTypes = {
  table: PropTypes.any,
}

function GridTab({ grid, onChange }) {
  const handleChange = (event, index) => {
    const newGrid = grid.slice()
    newGrid[index] = event.target.value
    onChange(newGrid)
  }
  return (
    <Box>
      {grid.map((item, index) =>
        item === null ? null : (
          <Stack
            sx={{ my: 1 }}
            spacing={2}
            key={index}
            direction="row"
            alignItems="center"
          >
            <Chip label={index} />
            <TextField
              sx={{ flexGrow: 1 }}
              value={item}
              onChange={(e) => handleChange(e, index)}
              size="small"
            />
          </Stack>
        )
      )}
    </Box>
  )
}

GridTab.propTypes = { grid: PropTypes.array, onChange: PropTypes.func }

function ServiceDialog(props) {
  const [loading, setLoading] = React.useState(false)
  const [newGrid, setNewGrid] = React.useState(props.settings.grid)

  const handleDownload = () => {
    fetch('/api/v1/service/download', { method: 'GET' })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'tileSettings.csv')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }

  const handleUpload = (event) => {
    const file = event.target.files[0]
    const formData = new FormData()
    setLoading(true)
    formData.append('file', file)
    fetch('/api/v1/service/upload', { method: 'POST', body: formData }).then(
      (response) => {
        response.json().then((result) => {
          setLoading(false)
          props.onChange({ tile: result })
        })
      }
    )
  }

  const loadTilesTable = () => {
    setLoading(true)
    fetch('/api/v1/service/read', { method: 'GET' }).then((response) => {
      response.json().then((result) => {
        setLoading(false)
        props.onChange({ tile: result })
      })
    })
  }

  const handleSaveGrid = () => {
    setLoading(true)
    fetch('/api/v1/service/save-grid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ grid: getGrid() }),
    }).then((response) => {
      response.json().then((result) => {
        setLoading(false)
        props.onChange(result)
      })
    })
  }

  const getGrid = () => {
    return newGrid.map((item) =>
      item ? parseFloat(String(item).replace(/,/g, '.')) : null
    )
  }

  const handleApplyGrid = () => {
    props.onChange({ grid: getGrid() })
  }

  React.useEffect(() => {
    setNewGrid(props.settings.grid)
  }, [props.settings])

  React.useEffect(() => {
    if (props.open) loadTilesTable()
  }, [props.open])

  return (
    <PopupTabsDialog
      title="Сервисные настройки"
      open={props.open}
      onClose={props.onClose}
      tabs={[
        {
          label: 'Тайлы',
          content: <TilesTab table={props.settings.tile} />,
          actions: (
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="caption">Файл настроек csv:</Typography>
              <Box>
                <label htmlFor="service-upload-table">
                  <Input
                    id="service-upload-table"
                    type="file"
                    onChange={handleUpload}
                  />
                  <Button variant="outlined" component="span">
                    Загрузить новый
                  </Button>
                </label>
              </Box>
              <Button variant="outlined" onClick={handleDownload}>
                Скачать
              </Button>
            </Stack>
          ),
        },
        {
          label: 'Сетка',
          content: <GridTab grid={newGrid} onChange={setNewGrid} />,
          actions: (
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleApplyGrid}>
                Применить
              </Button>
              <Button variant="outlined" onClick={handleSaveGrid}>
                Сохранить
              </Button>
            </Stack>
          ),
        },
      ]}
      loading={loading}
      maxWidth="md"
    />
  )
}

ServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    tile: PropTypes.object,
    grid: PropTypes.array,
    border: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ServiceDialog
