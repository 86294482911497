import FileData from './FileData'
import PointsData from './PointsData'
import History from './History'
import krm from '../Faces/Map/krm'
import moment from 'moment'
import { NonBlockingCalculate } from './NonBlockingCalculate'

class DataManager {
  constructor(settings = {}, data = null, version = 0) {
    this.settings = settings
    if (!this.settings.grid) this.settings.grid = []
    if (!data) data = {}
    this.data = data
    if (!this.data.sessions) {
      this.data.sessions = [
        {
          editor: true,
          time: moment().unix() * 1000,
          name: 'Редактор',
          points: [],
        },
      ]
      this.data.editorSessionIndex = 0
    }
    if (!this.data.files) this.data.files = []
    if (!this.data.points) this.data.points = new PointsData(this.settings)
    if (!this.data.history) this.data.history = new History()
    if (!this.data.palettes)
      this.data.palettes = [
        {
          uuid: '',
          name: 'Стандартная',
          colors: [
            { color: '#ee0000', depth: 2 },
            { color: '#eeee00', depth: 3 },
            { color: '#77ff00', depth: 4 },
            { color: '#00aaee', depth: 5 },
            { color: '#0000ee' },
          ],
        },
      ]
    this.version = version
  }

  updatePalette(index, data) {
    if (!this.data.palettes[index]) return this
    this.data.palettes[index] = data
    return this.clone(false)
  }

  getPalette(index) {
    return this.data.palettes[index]
  }

  newPalette() {
    this.data.palettes.push({
      name: 'Новая',
      colors: [{ color: '#ee3000', depth: 3 }, { color: '#0500ee' }],
    })
    return {
      index: this.data.palettes.length - 1,
      manager: this.clone(false),
    }
  }

  removePalette(index) {
    if (!this.data.palettes[index]) return this
    this.data.palettes.splice(index, 1)
    return this.clone(false)
  }

  clone(updateVersion = true) {
    return new DataManager(
      this.settings,
      this.data,
      this.version + updateVersion ? 1 : 0
    )
  }

  getHistory() {
    return this.data.history
  }

  getData() {
    return this.data
  }

  getMaxDepth() {
    return this.data.points.getBounds().maxDepth
  }

  setMapState(mapState) {
    this.data.mapState = mapState
  }

  setSelection(selection, use = false) {
    if (this.data.points.setSelection(selection, use)) this.version++
  }

  updateSettings(settings) {
    this.settings = settings
    if (!this.settings.grid) this.settings.grid = []
    this.data.points.updateSettings(this.settings)
  }

  removeByTime(time, session = null) {
    if (session === null) session = this.data.editorSessionIndex
    return new Promise((resolve) => {
      // TODO: добавить NonBlockingCalc
      for (let i = this.data.sessions[session].points.length - 1; i >= 0; i--)
        if (this.data.sessions[session].points[i][3] === time)
          this.data.sessions[session].points.splice(i, 1)
      resolve()
    })
  }

  // точки
  async pointsAdd(points, history = true, time = 0) {
    const es = this.data.editorSessionIndex
    if (time === 0) time = moment().valueOf()
    for (const p of points) p[3] = time
    this.data.sessions[es].points = this.data.sessions[es].points.concat(points)
    await this.data.points.add(points, es)
    if (history)
      this.data.history.add(
        'Добавление ' + points.length + ' точек',
        time,
        async () => await this.pointsAdd(points, false, time),
        async () => {
          await this.removeByTime(time)
          await this.updateEffectivePoints()
        }
      )
    return new DataManager(this.settings, this.data, this.version + 1)
  }

  async pointsChange(delta, selection, history = true, time = 0) {
    const points = []
    let addCount = 0
    if (time === 0) time = moment().valueOf()
    for (const s in selection) {
      if (selection[s] !== true) continue
      const pnt = this.data.points.get(s)
      const scale = krm.getMetersScale(pnt)
      if (delta.x !== 0 || delta.y !== 0)
        points.push([pnt[0], pnt[1], -100, time])
      points.push([
        pnt[0] + delta.x / scale.scaleX,
        pnt[1] - delta.y / scale.scaleY,
        pnt[2] + delta.d,
        time,
      ])
      addCount++
    }
    const es = this.data.editorSessionIndex
    for (const p of points) this.data.sessions[es].points.push(p)
    await this.data.points.add(points, es)
    const allPoints = this.data.points.getPoints().getAllPoints()
    const newSelection = []
    let i = 0
    for (const p of allPoints) {
      if (p[3] === time) newSelection[i] = true
      i++
    }
    if (history)
      this.data.history.add(
        'Изменение ' + addCount + ' точек',
        time,
        async () => await this.pointsChange(delta, selection, false, time),
        async () => {
          await this.removeByTime(time)
          await this.updateEffectivePoints()
        }
      )
    return {
      manager: new DataManager(this.settings, this.data, this.version + 1),
      selection: newSelection,
    }
  }

  async pointsDelete(selection, history = true, time = 0, manual = true) {
    const points = []
    await new Promise((resolve) => {
      NonBlockingCalculate(
        selection.length,
        1000,
        (i) => {
          if (selection[i] === true) points.push([...this.data.points.get(i)])
          return true
        },
        () => resolve()
      )
    })
    const es = this.data.editorSessionIndex
    if (time === 0) time = moment().valueOf()
    for (const p of points) {
      p[2] = manual ? -100 : -200
      p[3] = time
      this.data.sessions[es].points.push(p)
    }
    await this.data.points.add(points, es)
    if (history)
      this.data.history.add(
        'Удаление ' + points.length + ' точек',
        time,
        async () => await this.pointsDelete(selection, false, time, manual),
        async () => {
          await this.removeByTime(time)
          await this.updateEffectivePoints()
        }
      )
    return new DataManager(this.settings, this.data, this.version + 1)
  }

  // работа с файлами
  async addFile(file, history = true) {
    const f = FileData.normalize(file)
    this.data.files.push(f)
    const index = this.data.files.length - 1
    await this.addEffectivePoints(f)
    if (history)
      this.data.history.add(
        'Добавление файла ' + file.name,
        moment().valueOf(),
        async () => await this.addFile(file, false),
        async () => await this.removeFile(index, false)
      )
    return new DataManager(this.settings, this.data, this.version)
  }

  async removeFile(index, history = true) {
    if (!this.data.files[index]) return this
    const f = this.data.files[index]
    await this.removeEffectivePoints(f)
    const file = this.data.files.splice(index, 1)[0]
    if (history)
      this.data.history.add(
        'Удаление файла ' + file.name,
        moment().valueOf(),
        async () => await this.removeFile(index, false),
        async () => await this.addFile(file, false)
      )
    return new DataManager(this.settings, this.data, this.version)
  }

  async activateFile(index, active, history = true) {
    if (!this.data.files[index]) return this
    const f = this.data.files[index]
    f.active = active
    if (active) await this.addEffectivePoints(f)
    else await this.removeEffectivePoints(f)
    if (history)
      this.data.history.add(
        (!active ? 'Деактивация' : 'Активация') + ' файла ' + f.name,
        moment().valueOf(),
        async () => await this.activateFile(index, active, false),
        async () => await this.activateFile(index, !active, false)
      )
    return new DataManager(this.settings, this.data, this.version)
  }

  async updateEffectivePoints() {
    this.data.points.clear()
    this.data.sessions.sort((a, b) =>
      a.time === b.time ? 0 : a.time > b.time ? 1 : -1
    )
    let sIndex = 0
    for (const s of this.data.sessions) {
      if (s.editor === true) this.data.editorSessionIndex = sIndex
      if (s.disabled !== true) await this.data.points.add(s.points, sIndex)
      sIndex++
    }
  }

  async addEffectivePoints(f) {
    for (const s of f.layers.sessions) {
      s.editor = false
      s.disabled = false
      s.file = f.uuid
    }
    this.data.sessions = this.data.sessions.concat(f.layers.sessions)
    // console.log(f.layers.palettes)
    if (f.layers.palettes)
      this.data.palettes = this.data.palettes.concat(f.layers.palettes)
    await this.updateEffectivePoints()
  }

  async removeEffectivePoints(f) {
    for (let i = this.data.sessions.length - 1; i >= 0; i--)
      if (this.data.sessions[i].file === f.uuid) this.data.sessions.splice(i, 1)
    await this.updateEffectivePoints()
  }

  // сессии
  sessionCreate(name) {
    const date = moment()
    this.data.sessions.push({
      name: name,
      timePosition: date.unix(),
      date: date.format('r'),
      points: [],
    })
    return new DataManager(this.settings, this.data, this.version)
  }

  sessionRename(index, name) {
    // console.log(index, name)
    this.data.sessions[index].name = name
    return new DataManager(this.settings, this.data, this.version)
  }

  sessionDelete(index) {
    this.data.sessions.splice(index, 1)
    this.data.points = []
    return new DataManager(this.settings, this.data, this.version)
  }

  sessionMergeWith(index, mergeSessions) {
    let points = this.data.sessions[index].points
    for (const s of mergeSessions)
      points = points.concat(this.data.sessions[s].points)
    this.data.sessions[index].points = points
    mergeSessions.sort((a, b) => b - a)
    for (const s of mergeSessions) this.data.sessions.splice(s, 1)
    this.data.points = []
    return new DataManager(this.settings, this.data, this.version)
  }

  async sessionDisable(index, disabled) {
    if (!this.data.sessions[index]) return this
    this.data.sessions[index].disabled = disabled
    await this.updateEffectivePoints()
    return new DataManager(this.settings, this.data, this.version + 1)
  }

  getAllBounds() {
    const bounds = {}
    this.data.points
      .getPoints()
      .eachPoint((item, index) => krm.findBoundsCallback(item, bounds))
    return bounds
  }

  getFileBounds(fileIndex) {
    const uuid = this.data.files[fileIndex].uuid
    const bounds = {}
    this.data.points.getPoints().eachPoint((item, index) => {
      if (this.data.sessions[item[4]].file === uuid)
        krm.findBoundsCallback(item, bounds)
    })
    krm.findBoundsCenter(bounds)
    return bounds
  }

  getSessionsCount() {
    return this.data.sessions.length
  }

  getPointsCount() {
    return this.data.points.count()
  }

  getSelectionPointsCount() {
    return this.data.points.getSelectionPointsCount()
  }

  findPointsInRadius(pos, radius) {
    const r = radius * radius
    const scale = krm.getMetersScaleArray(pos)
    const result = []
    for (const p of this.data.points) {
      const dx = (p[0] - pos[0]) * scale.scaleX
      const dy = (p[1] - pos[1]) * scale.scaleY
      const r0 = dx * dx + dy * dy
      if (r0 < r)
        result.push({
          longitude: p[0],
          latitude: p[1],
          depth: p[2],
          delta: Math.pow(r0, 0.5),
        })
    }
    result.sort((a, b) =>
      a.delta === b.delta ? 0 : a.delta > b.delta ? 1 : -1
    )
    return result
  }

  getPointData(index) {
    return this.data.points.get(index)
  }

  // точки для рендера (привязаны к сетке, минимизированный формат)
  getRenderPoints(zoom) {
    return {
      // list: this.data.points.getGrid(zoom).getAllPoints(),
      data: this.data.points.getGrid(zoom),
      bounds: this.data.points.getBounds(),
    }
  }

  // точки для выделения (минимизированный формат + номер сессии)
  getPointsData() {
    return this.data.points
  }

  getEffectivePoints() {
    return this.data.points.getPoints().getAllPoints()
  }

  // точки для списка (в виде ссылок на сессии, полный формат)
  getListPoints(sort) {
    return this.getSelectedPoints(sort, null)
  }

  getSelectedPoints(sort, selection) {
    const keys = {
      time: 3,
      depth: 2,
    }
    const points = []
    if (!this.data.points || this.data.points.isEmpty()) return []
    if (!selection) {
      const count = this.data.points.count()
      for (let i = 0; i < count; i++) points.push(i)
    } else {
      for (const s of selection) if (s && this.data.points[s]) points.push(s)
    }
    if (sort) {
      const key = keys[sort.key]
      const order = sort.order === 'asc'
      points.sort((a, b) => {
        const c =
          this.data.points.get(a)[key] === this.data.points.get(b)[key]
            ? 0
            : this.data.points.get(a)[key] > this.data.points.get(b)[key]
            ? 1
            : -1
        return order ? c : -c
      })
    }
    return points
  }

  getEffectiveSessionPoints(sessionIndex) {
    const session = this.data.sessions[sessionIndex]
    const grid = PointsData.getPointsGridManager()
    const deleted = []
    this.fillGridFromSession(grid, deleted, session.points)
    return {
      real: grid.getAllPoints(),
      deleted: deleted,
    }
  }

  fillGridFromSession(grid, deleted, points) {
    for (const p of points)
      grid.addPoint(
        p,
        (a, b, tileKey, index) => {
          if (a[0] === b[0] && a[1] === b[1]) {
            if (a[3] > b[3]) {
              if (a[2] === -100 || a[2] === -200) {
                grid.clearPointDirect(tileKey, index)
                if (a[2] === -100) deleted.push([a[0], a[1], a[2]])
              } else grid.updatePointDirect(tileKey, index, a)
            }
            return true
          }
          return false
        },
        grid.size * 0.5
      )
  }

  getAllEffectivePoints() {
    const grid = PointsData.getPointsGridManager()
    const deleted = []
    for (const session of this.data.sessions)
      this.fillGridFromSession(grid, deleted, session.points)
    return {
      real: grid.getAllPoints(),
      deleted: deleted,
    }
  }

  getSavingData(options) {
    const data = {}
    // собираем сесии и точки
    if (options.source === 'selected') {
      const res = this.data.points.getSelectedData()
      const sessions = []
      for (const s in res.sessions) {
        const s0 = this.data.sessions[s]
        res.sessions[s].index = sessions.length
        sessions.push({
          file: s0.file,
          name: s0.name,
          uuid: s0.uuid,
          root: s0.root,
          time: s0.time,
          points: res.sessions[s].points,
        })
      }
      for (const p of res.points) p[4] = res.sessions[p[4]].index
      data.sessions = sessions
      data.points = res.points
      data.deleted = []
    } else {
      const editorRes = this.getEffectiveSessionPoints(
        this.data.editorSessionIndex
      )
      data.sessions = this.data.sessions
      data.sessions[this.data.editorSessionIndex].points = editorRes.real
      const res = this.getAllEffectivePoints()
      data.points = res.real.filter((v) => v[2] > -100)
      data.deleted = [...res.real.filter((v) => v[2] === -100), ...res.deleted]
      console.log(data.points.length)
      console.log(data.deleted.length)
      /*
      const res = this.getEffectiveSessionPoints(this.data.editorSessionIndex)
      data.points = [
        ...this.getEffectivePoints(),
        ...res.real.filter((v) => v[2] > -100),
      ]
      console.log(data.points)
      data.deleted = [...res.real.filter((v) => v[2] === -100), ...res.deleted]
       */
    }
    // палитры
    data.palettes = []
    for (const pt of this.data.palettes) {
      const p = { name: pt.name, colors: [] }
      for (const c of pt.colors)
        p.colors.push({
          color: c.color,
          depth: c.depth,
        })
      data.palettes.push(p)
    }
    if (options.type === 'image') {
      const points = this.data.points.getGrid(19).getAllPoints()
      data.points = []
      for (const p of points)
        data.points.push(krm.idxToGeo(p, this.data.points.baseGridSize))
      if (data.points.length > 0) {
        const bounds = krm.findBounds(data.points)
        data.points = krm.createOptimizePoints2(data.points, [
          bounds.longCenter,
          bounds.latCenter,
        ])
      }
    }
    return data
  }

  isDataAvailable() {
    return (
      (this.data.files && this.data.files.length > 0) ||
      !this.data.points.isEmpty()
    )
  }

  getVersion() {
    let v = ''
    for (const f of this.data.files) v += f.uuid + ':'
    return v + this.version + ':'
  }
}

export default DataManager
