import { Box, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function DialogFrame(props) {
  return (
    <Box
      sx={{
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h6" sx={{ mb: 0.5, px: 3, flexShrink: 0 }}>
        {props.title}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          px: 3,
          overflow: 'hidden auto',
          position: 'relative',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

DialogFrame.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
}

export default DialogFrame
