import {
  renderSelection,
  renderPoint,
  renderSelectedPoint,
  renderNewSelectionPoint,
} from './Paints'

import KRM from '../Map/krm'

export default class CanvasLayer {
  constructor(canvasId) {
    this.canvas = null
    this.canvasId = canvasId
    this.mouse = { x: 0, y: 0 }
    this.last = { x: 0, y: 0 }
    this.shape = {}
    this.magicSelection = []
    this.setTool({})
    this.selection = false
    this.data = {
      points: null,
      selection: [],
    }
    this.showPoints = false
  }

  setShowPoints(show) {
    this.showPoints = show
  }

  resize(mapState) {
    this.size = mapState.size
    this.camera = mapState.camera
    this.render()
  }

  setData(points, selection) {
    this.data = {
      points: points,
      selection: selection,
    }
  }

  calculateDelta() {
    if (!this.camera || !this.camera.center) return [0, 0]
    const c = KRM.getTileFromCoords(
      this.camera.center[0],
      this.camera.center[1],
      this.camera.zoom,
      512
    )
    return [
      Math.round(c.xp - this.size.x / 2),
      Math.round(c.yp - this.size.y / 2),
    ]
  }

  initCanvas() {
    this.canvas = document.getElementById(this.canvasId)
  }

  onMouseDown(e) {
    this.last = {
      x: e.clientX,
      y: e.clientY,
    }
    this.mouse.x = e.clientX
    this.mouse.y = e.clientY
    this.magicSelection = []
    this.selection = true
    this.onMouseMove(e)
    window.onkeydown = (event) => {
      if (event.key === 'Escape') {
        this.selection = false
        this.render()
      }
    }
  }

  onMouseMove(e) {
    if (!this.selection) return
    this.mouse.x = e.clientX
    this.mouse.y = e.clientY
    const a = this.last
    const b = this.mouse
    this.shape = {
      x0: Math.min(a.x, b.x),
      y0: Math.min(a.y, b.y),
      x1: Math.max(a.x, b.x),
      y1: Math.max(a.y, b.y),
      x: b.x,
      y: b.y,
    }
    // списать в прямоугольник
    this.shape.a = (this.shape.x1 - this.shape.x0) / 2
    this.shape.b = (this.shape.y1 - this.shape.y0) / 2
    this.shape.cx = this.shape.x0 + this.shape.a
    this.shape.cy = this.shape.y0 + this.shape.b
    // строить из центра
    /*
    this.shape.a = Math.abs(b.x - a.x)
    this.shape.b = Math.abs(b.y - a.y)
    this.shape.cx = a.x
    this.shape.cy = a.y
    if (this.tool.shape === 'circle') {
      this.shape.x0 -= this.shape.a
      this.shape.y0 -= this.shape.b
    }
     */
    if (this.tool.shape === 'magic') {
      this.shape.radius = 15
    }
    this.shape.type = this.tool.shape

    this.render()
  }

  getMethod(render = false) {
    switch (this.tool.method) {
      default:
      case 'replace':
        return (x, y, p) => this.isInSelection(x, y, p)
      case 'add':
        return render
          ? (x, y, p) => this.isInSelection(x, y, p) && !this.data.selection[p]
          : (x, y, p) => this.isInSelection(x, y, p) || this.data.selection[p]
      case 'exclude':
        return render
          ? (x, y, p) => this.isInSelection(x, y, p) && this.data.selection[p]
          : (x, y, p) => !this.isInSelection(x, y, p) && this.data.selection[p]
      case 'intersect':
        return (x, y, p) =>
          this.isInSelection(x, y, p) && this.data.selection[p]
    }
  }

  onMouseUp(e) {
    if (!this.selection) return
    const selection = []
    const method = this.getMethod()
    this.eachPoint((p, x, y, i) => {
      if (method(x, y, i)) selection[i] = true
    })
    this.selection = false
    this.render()
    this.data.selection = selection
    return selection
  }

  render() {
    if (!this.canvas) return
    if (!this.data.points || this.data.points.isEmpty()) return
    if (!this.camera) return
    this.canvas.width = this.size.x
    this.canvas.height = this.size.y
    const ctx = this.canvas.getContext('2d')
    if (this.selection) renderSelection(ctx, this.shape)
    const method = this.getMethod(true)
    this.eachPoint((p, x, y, i) => {
      if (method(x, y, i)) renderNewSelectionPoint(ctx, x, y)
      else {
        if (this.data.selection[i] === true) renderSelectedPoint(ctx, x, y)
        else if (this.showPoints) renderPoint(ctx, x, y)
      }
    })
  }

  isInSelection(x, y, p) {
    if (!this.tool) return false
    switch (this.tool.shape) {
      default:
      case 'rect':
        return this.isInSelectionRect(x, y)
      case 'circle':
        return this.isInSelectionCircle(x, y)
      case 'magic':
        return this.isInSelectionMagic(x, y, p)
    }
  }

  isInSelectionMagic(x, y, p) {
    if (!this.selection) return false
    if (this.magicSelection[p]) return true
    if (
      x < this.shape.x - this.shape.radius ||
      x > this.shape.x + this.shape.radius ||
      y < this.shape.y - this.shape.radius ||
      y > this.shape.y + this.shape.radius
    )
      return false
    const dx = x - this.shape.x
    const dy = y - this.shape.y
    const result = dx * dx + dy * dy <= this.shape.radius * this.shape.radius
    if (result) this.magicSelection[p] = true
    return result
  }

  isInSelectionRect(x, y) {
    if (!this.selection) return false
    return (
      x >= this.shape.x0 &&
      x <= this.shape.x1 &&
      y >= this.shape.y0 &&
      y <= this.shape.y1
    )
  }

  isInSelectionCircle(x0, y0) {
    if (!this.selection) return false
    if (!this.isInSelectionRect(x0, y0)) return false
    const x = x0 - this.shape.cx
    const y = y0 - this.shape.cy
    const a = this.shape.a
    const b = this.shape.b
    return (x * x) / (a * a) + (y * y) / (b * b) <= 1
  }

  eachPoint(callback) {
    const c = this.calculateDelta()
    let i = 0
    if (!this.data.points) return
    for (const p of this.data.points) {
      const pc = KRM.getTileFromCoords(p[0], p[1], this.camera.zoom)
      const x = Math.round(pc.xp - c[0])
      const y = Math.round(pc.yp - c[1])
      callback(p, x, y, i)
      i++
    }
  }

  setTool(toolOptions) {
    this.tool = toolOptions
    if (!this.tool.shape) this.tool.shape = 'rect'
    if (!this.tool.method) this.tool.method = 'replace'
  }
}
