import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Slider, Typography } from '@mui/material'
import krm from '../../Map/krm'
import DataContext from '../../../Context/DataContext'
import FilterLayout from './FilterLayout'
import moment from 'moment'

async function executeGridFilter(manager, resolution, time) {
  const points = manager.getPointsData().getPoints()
  const newPoints = []
  points.eachPoint((p) =>
    newPoints.push(krm.idxToGeo(krm.geoToIdx(p, resolution), resolution))
  )
  const selection = []
  for (let i = 0; i < points.pointsCount(); i++) selection[i] = true
  let m = manager
  m = await m.pointsDelete(selection, false, time, false)
  m = await m.pointsAdd(newPoints, false, time + 1)
  return m
}

function GridFilter({ onSelect, onChange }) {
  const manager = useContext(DataContext)

  const [grid, setGrid] = React.useState(1)
  // const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const time = moment().valueOf()
  const handleExecute = () => {
    setLoading(true)
    manager.getHistory().add(
      'Фильтр по сетке',
      time,
      async () => await executeGridFilter(manager, grid, time),
      async () => {
        await manager.removeByTime(time)
        await manager.removeByTime(time + 1)
        await manager.updateEffectivePoints()
      }
    )
    executeGridFilter(manager, grid, time).then((m) => {
      setLoading(false)
      onChange(m)
    })
  }

  return (
    <FilterLayout
      label="Данный фильтр заменит имеющиеся точки ближайшими точками сетки, что позволит распределить их равномерно"
      alert="Применение фильтра приводит к потере данных об исходных точках и треках"
      button="Применить"
      buttonVariant="contained"
      loading={loading}
      onExecute={handleExecute}
    >
      <Box sx={{ my: 2 }}>
        <Typography variant="label">Размер сетки ({grid} м)</Typography>
        <Slider
          disabled={loading}
          value={grid}
          valueLabelDisplay="auto"
          step={0.1}
          min={1}
          max={5}
          onChange={(e, v) => setGrid(v)}
        />
      </Box>
    </FilterLayout>
  )
}

GridFilter.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
}

export default GridFilter
