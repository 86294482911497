import { Button, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function LoadingButton({ loading, children, startIcon, ...others }) {
  const realStartIcon = loading ? (
    <CircularProgress sx={{ mr: 1 }} size="1em" color="inherit" />
  ) : (
    startIcon
  )

  return (
    <Button disabled={loading} startIcon={realStartIcon} {...others}>
      {children}
    </Button>
  )
}

LoadingButton.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
  startIcon: PropTypes.node,
  loading: PropTypes.bool,
  children: PropTypes.node,
}

export default LoadingButton
