import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

export default function P7Options({ format, options, onChange }) {
  return format === 'praktik7' || format === 'p7' ? (
    <FormControlLabel
      sx={{ mb: 1 }}
      control={
        <Checkbox
          checked={options.useOldUuid === true}
          onChange={(event) => onChange({ useOldUuid: event.target.checked })}
        />
      }
      label="Обновить данные при импорте"
    />
  ) : null
}

P7Options.propTypes = {
  format: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}
