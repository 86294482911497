import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List } from 'react-virtualized'
import 'react-virtualized/styles.css'
import {
  Alert,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { formatDepth, formatDate } from './PointsTable'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DataContext from '../../../Context/DataContext'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import SessionNameDialog from './SessionNameDialog'
import { formatUnit } from '../../Common/units'

const sortKeys = {
  time: 3,
  depth: 2,
}

function createSessionsMap(manager) {
  const sessionsMap = []
  let i = 0
  console.log(manager.getData().sessions)
  for (const s of manager.getData().sessions)
    sessionsMap.push({
      ...s,
      index: i++,
      effectivePoints: [],
    })
  i = 0
  manager
    .getPointsData()
    .getPoints()
    .eachPoint((p) => sessionsMap[p[4]].effectivePoints.push([p, i++]))
  return sessionsMap
}

const POINT = 0
const SESSION = 1
const SESSION_ADDS = 2

function getRowsMap(manager, sessionsMap, opened, sort) {
  const order = sort.order === 'asc' ? 1 : -1
  const key = sortKeys[sort.key] || 3
  for (const s of sessionsMap) {
    s.effectivePoints.sort((a, b) => (a[0][key] > b[0][key] ? 1 : -1) * order)
  }
  if (sort.key === 'time') sessionsMap.sort((a, b) => a.time - b.time)
  else
    sessionsMap.sort((a, b) => {
      if (a.effectivePoints.length === 0) return -order
      if (b.effectivePoints.length === 0) return order
      return (
        (a.effectivePoints[0][0][key] > b.effectivePoints[0][0][key] ? 1 : -1) *
        order
      )
    })
  const rows = []
  for (const s of sessionsMap) {
    rows.push([SESSION, s])
    rows.push([SESSION_ADDS, s])
    if (opened[s.index] === true)
      for (const p of s.effectivePoints) rows.push([POINT, p[0], p[1]])
  }
  return rows
}

function linesCounts(sessionsMap, opened) {
  let total = 0
  for (const s of sessionsMap) {
    total += 2
    if (opened[s.index] === true) total += s.effectivePoints.length
  }
  return total
}

function SessionsTable(props) {
  const manager = useContext(DataContext)
  const sessions = manager.getData().sessions
  const [selected, setSelected] = useState(-1)

  const map = createSessionsMap(manager)
  const tree = getRowsMap(manager, map, props.opened, props.sort)
  const count = linesCounts(map, props.opened)

  const handleChange = (index) => {
    const p = tree[index][2]
    const selection = props.selection.slice()
    selection[p] = !selection[p]
    props.onCheck(selection)
  }

  const handleClick = (index) => {
    setSelected(index)
  }

  const item = (index) => {
    return tree[index][1]
  }

  const isChecked = (index) => {
    return props.selection[tree[index][2]] === true
  }

  const handleSessionToggle = (index) => {
    const s = tree[index][1].index
    props.opened[s] = !props.opened[s]
    props.onOpen(props.opened)
  }

  const isSessionChecked = (index) => {
    const list = tree[index][1].effectivePoints
    if (list.length === 0) return false
    // console.log(list)
    for (const p of list) if (props.selection[p[1]] !== true) return false
    return true
  }

  const isSessionIndeterminate = (index) => {
    const list = tree[index][1].effectivePoints
    let a = false
    let b = false
    for (const p of list) {
      if (props.selection[p[1]] === true) a = true
      if (props.selection[p[1]] !== true) b = true
      if (a && b) return true
    }
    return false
  }

  const handleSessionCheck = (index, e) => {
    const isSelected = isSessionChecked(index)
    const selection = props.selection.slice()
    for (const p of tree[index][1].effectivePoints)
      selection[p[1]] = !isSelected
    props.onCheck(selection)
  }

  const renderPoint = (index) => {
    return (
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', pl: 3.5 }}
      >
        <Box>
          <Checkbox
            checked={isChecked(index)}
            onChange={() => handleChange(index)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant="caption" sx={{ pl: 1 }}>
            {formatDate(item(index)[3], 'H:mm')}
          </Typography>
        </Box>
        <Box>{formatDepth(item(index)[2])}</Box>
      </Stack>
    )
  }

  const renderSessionAdds = (index) => {
    const s = tree[index][1]
    return (
      <Stack
        direction="row"
        spacing={2}
        alignItems="top"
        sx={{ mt: -2, pl: 5, color: !s.disabled ? null : 'text.secondary' }}
      >
        <Typography variant="caption">
          {formatDate(s.time, 'D MMM Y')}
        </Typography>
        <Typography variant="caption">
          {s.disabled
            ? 'сессия отключена'
            : formatUnit(s.effectivePoints.length, ['точка', 'точки', 'точек'])}
        </Typography>
      </Stack>
    )
  }

  const renderSession = (index) => {
    const s = tree[index][1]
    return (
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', color: !s.disabled ? null : 'text.secondary' }}
      >
        <Box>
          <IconButton onClick={() => handleSessionToggle(index)}>
            <ExpandMoreIcon
              style={{
                transform:
                  props.opened[s.index] === true ? null : 'rotate(-90deg)',
              }}
            />
          </IconButton>
          <Typography variant="button">{s.name}</Typography>
        </Box>
        <Box>
          <Checkbox
            checked={isSessionChecked(index)}
            indeterminate={isSessionIndeterminate(index)}
            onChange={(e) => handleSessionCheck(index, e)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <IconButton
            sx={{ color: 'text.primary', mr: -2 }}
            component="label"
            onClick={(e) => handleSessionMenu(e, s.index)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Stack>
    )
  }

  const renderRow = (index) => {
    if (tree[index] !== undefined) {
      switch (tree[index][0]) {
        case POINT:
          return renderPoint(index)
        case SESSION:
          return renderSession(index)
        case SESSION_ADDS:
          return renderSessionAdds(index)
      }
    }
    return <Skeleton />
  }

  const rowRenderer = ({ key, index, style }) => {
    return (
      <ListItem
        key={key}
        style={style}
        onClick={() => handleClick(index)}
        sx={{
          px: 1,
          pr: 3,
          borderRadius: 1,
          color: selected[0] === index ? 'primary.main' : null,
          // color: selected === index ? 'background.paper' : null,
        }}
      >
        {renderRow(index)}
      </ListItem>
    )
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedSession, setSelectedSession] = React.useState(-1)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSessionMenu = (event, index) => {
    setAnchorEl(event.target)
    setSelectedSession(index)
  }

  const handleRename = () => {
    handleClose()
    setRenameOpen(true)
    setRenameValue(sessions[selectedSession].name)
  }
  const [renameOpen, setRenameOpen] = React.useState(false)
  const [renameValue, setRenameValue] = React.useState('')
  const handleRenameDone = (newName) => {
    setRenameOpen(false)
    // props.onDataChange(manager.sessionRename(selectedSession, newName))
  }

  const handleDisable = () => {
    manager
      .sessionDisable(selectedSession, !sessions[selectedSession].disabled)
      .then((newManager) => props.onDataChange(newManager))
    handleClose()
  }

  return (
    <Box sx={{ height: 1 }}>
      <AutoSizer>
        {({ height, width }) => {
          return count === 0 ? (
            <Alert style={{ width: width }} severity="info">
              Нет точек для отображения
            </Alert>
          ) : (
            <List
              width={width}
              height={height}
              rowCount={count}
              rowHeight={40}
              rowRenderer={rowRenderer}
            />
          )
        }}
      </AutoSizer>
      <Menu
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlinedIcon />
          </ListItemIcon>
          Переименовать
        </MenuItem>
        <MenuItem onClick={handleDisable}>
          <ListItemIcon />
          {sessions[selectedSession] &&
          sessions[selectedSession].disabled === true
            ? 'Включить'
            : 'Отключить'}
        </MenuItem>
      </Menu>
      <SessionNameDialog
        open={renameOpen}
        selectedValue={renameValue}
        onClose={handleRenameDone}
        doneTitle="Изменить"
      />
    </Box>
  )
}

SessionsTable.propTypes = {
  onDataChange: PropTypes.func,
  onCheck: PropTypes.func,
  onOpen: PropTypes.func,
  opened: PropTypes.array.isRequired,
  selection: PropTypes.array.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
}

export { SessionsTable }
