import React, { useContext } from 'react'
import Chip from '@mui/material/Chip'
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import PropTypes from 'prop-types'
import DialogFrame from '../DialogFrame'
import { formatFileSize, Uploader } from './Uploader'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Downloader from './Downloader'
import DataContext from '../../../Context/DataContext'

function FileDialog({ selection, onFilesChange, options }) {
  const manager = useContext(DataContext)
  const files = manager.getData().files
  const [loadingFile, setLoadingFile] = React.useState(-1)

  const handleAddFile = (file) => {
    setLoadingFile(files.length)
    manager.addFile(file).then((newManager) => {
      setLoadingFile(-1)
      onFilesChange({ manager: newManager, findOnMap: true })
    })
  }

  const handleActive = (event, index) => {
    setLoadingFile(index)
    event.stopPropagation()
    manager.activateFile(index, event.target.checked).then((newManager) => {
      setLoadingFile(-1)
      onFilesChange({ manager: newManager, findOnMap: false })
    })
  }

  const handleRemove = (index) => {
    setLoadingFile(index)
    manager.removeFile(index).then((newManager) => {
      setLoadingFile(-1)
      onFilesChange({ manager: newManager, findOnMap: false })
    })
  }

  const handleFileClick = (index) => {
    onFilesChange({ showFileIndex: index })
  }

  return (
    <DialogFrame title="Файлы">
      <Box sx={{ my: 1 }}>
        {files.length === 0 ? (
          <Typography
            sx={{ mt: 1, mb: 3, color: 'text.secondary' }}
            variant="caption"
            component="div"
          >
            Не загружено ни одного файла
          </Typography>
        ) : (
          <List>
            {files.map((item, index) => (
              <ListItem
                key={index}
                sx={{ pl: 0 }}
                alignItems="flex-start"
                disablePadding
                secondaryAction={
                  loadingFile === index ? (
                    <CircularProgress variant="indeterminate" />
                  ) : (
                    <IconButton
                      edge="end"
                      sx={{ color: 'text.primary' }}
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    sx={{ px: 0, pt: 0.75 }}
                    checked={item.active}
                    disabled={loadingFile !== -1}
                    onChange={(event) => handleActive(event, index)}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemButton onClick={() => handleFileClick(index)}>
                  <Box sx={{ my: 0.5 }}>
                    <Box>
                      <Typography variant="subtitle1">
                        {item.name}
                        <Chip
                          sx={{ ml: 1 }}
                          label={formatFileSize(item.file.size)}
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        {item.file.name}
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <Uploader onLoadEnd={handleAddFile} />
      <Downloader selection={selection} renderOptions={options} />
    </DialogFrame>
  )
}

FileDialog.propTypes = {
  onFilesChange: PropTypes.func,
  selection: PropTypes.array.isRequired,
  options: PropTypes.object,
}

export default FileDialog
