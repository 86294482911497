import React from 'react'
import PropTypes from 'prop-types'
import SettingsContext from './SettingsContext'

function SettingsProvider({ data, children }) {
  return (
    <SettingsContext.Provider value={data}>{children}</SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any,
}

export default SettingsProvider
