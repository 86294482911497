export default class History {
  constructor() {
    this.clear()
  }

  clear() {
    this.timeline = []
    this.position = 0
  }

  add(name, time, redo, undo) {
    if (this.position < this.timeline.length)
      this.timeline.splice(this.position, this.timeline.length - this.position)
    this.timeline.push({
      name: name,
      time: time,
      redo: redo,
      undo: undo,
    })
    this.position = this.timeline.length
  }

  undo() {
    if (this.position === 0) return Promise.resolve()
    this.position--
    return this.timeline[this.position].undo()
  }

  redo() {
    if (this.timeline.length === 0 || this.position >= this.timeline.length)
      return Promise.resolve()
    return this.timeline[this.position++].redo()
  }

  available() {
    const r = {
      redo: this.timeline.length > 0 && this.position < this.timeline.length,
      undo: this.position > 0,
    }
    r.undoText =
      'Отменить' + (r.undo ? ': ' + this.timeline[this.position - 1].name : '')
    r.redoText =
      'Вернуть' + (r.redo ? ': ' + this.timeline[this.position].name : '')
    return r
  }
}
