import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List } from 'react-virtualized'
import 'react-virtualized/styles.css'
import {
  Alert,
  Box,
  Checkbox,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import moment from 'moment'
import 'moment/locale/ru'
import DataContext from '../../../Context/DataContext'

const formatDepth = (depth) => {
  return Math.round(depth * 100) / 100 + ' м'
}

const formatDate = (utime, format = 'D MMM Y H:mm') => {
  const date = new Date(utime)
  return moment(date).locale('ru').format(format)
}

function PointsTable(props) {
  const manager = useContext(DataContext)
  const [selected, setSelected] = useState(-1)

  const count = manager.getPointsCount()
  const points = manager.getListPoints(props.sort)

  const handleChange = (index) => {
    const p = points[index]
    const selection = props.selection.slice()
    selection[p] = !selection[p]
    props.onCheck(selection)
  }

  const handleClick = (index) => {
    setSelected(index)
  }

  const item = (index) => {
    return manager.getPointData(points[index])
  }

  const isChecked = (index) => {
    return !!props.selection[points[index]]
  }

  const rowRenderer = ({ key, index, style }) => {
    return (
      <ListItem
        key={key}
        style={style}
        onClick={() => handleClick(index)}
        sx={{
          px: 1,
          pr: 3,
          borderRadius: 1,
          color: selected === index ? 'primary.main' : null,
          // color: selected === index ? 'background.paper' : null,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Box>
            <Checkbox
              checked={isChecked(index)}
              onChange={() => handleChange(index)}
              // inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant="caption" sx={{ pl: 1 }}>
              {formatDate(item(index)[3], 'D MMM Y')}
            </Typography>
            <Typography variant="caption" sx={{ pl: 2 }}>
              {formatDate(item(index)[3], 'H:mm')}
            </Typography>
          </Box>
          <Box>{formatDepth(item(index)[2])}</Box>
        </Stack>
      </ListItem>
    )
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        return count === 0 ? (
          <Alert style={{ width: width }} severity="info">
            Нет точек для отображения
          </Alert>
        ) : (
          <List
            width={width}
            height={height}
            rowCount={count}
            rowHeight={40}
            rowRenderer={rowRenderer}
          />
        )
      }}
    </AutoSizer>
  )
}

PointsTable.propTypes = {
  onCheck: PropTypes.func,
  selection: PropTypes.array.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
}

export { PointsTable, formatDepth, formatDate }
