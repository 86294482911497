import React from 'react'
import PropTypes from 'prop-types'
import 'react-virtualized/styles.css'
import { Button, MenuItem, Menu, Box } from '@mui/material'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'

function SortButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (index) => {
    handleClose()
    props.onChange(index)
  }

  return (
    <Box>
      <Button
        onClick={handleClick}
        sx={{
          px: 1,
          py: 1,
          color: open ? 'primary.main' : 'text.primary',
        }}
        size="small"
        startIcon={
          <SortOutlinedIcon
            style={{
              transform:
                props.variants[props.index].order !== 'asc'
                  ? null
                  : 'scaleY(-1)',
            }}
          />
        }
      >
        {props.variants[props.index].name}
      </Button>
      <Menu
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.variants.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleChange(index)}
              disableRipple
            >
              <SortOutlinedIcon
                style={{
                  transform: item.order !== 'asc' ? null : 'scaleY(-1)',
                }}
                sx={{ mr: 1 }}
              />
              {item.name}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

SortButton.propTypes = {
  index: PropTypes.number.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
}

export default SortButton
