import { Stack, Typography } from '@mui/material'
import styles from './ScaleRuler.module.css'
import React from 'react'
import PropTypes from 'prop-types'
import krm from './krm'

function getPixelSize(camera, tileSize, meters) {
  if (!camera || !camera.center || !camera.zoom) return 0
  const scale = krm.getMetersScale(camera.center)

  const long = camera.center[0] + meters / scale.scaleX
  const p0 = krm.getTileFromCoords(
    camera.center[0],
    camera.center[1],
    camera.zoom,
    tileSize
  )
  const p1 = krm.getTileFromCoords(
    long,
    camera.center[1],
    camera.zoom,
    tileSize
  )

  return p1.xp - p0.xp
}

function roundUnit(value) {
  if (value < 10) return Math.round(value * 100) / 100
  if (value < 100) return Math.round(value * 10) / 10
  return Math.round(value)
}

function getUnit(meters) {
  if (meters >= 1000) return roundUnit(meters / 1000) + ' км'
  return roundUnit(meters) + ' м'
}

function RulerMarks({ times }) {
  const steps = []
  for (let i = 0; i < times; i++)
    steps.push(<span key={i} style={{ width: 100 / times + '%' }} />)
  return <div>{steps}</div>
}
RulerMarks.propTypes = { times: PropTypes.number }

function ScaleRuler({ camera, tileSize, ...other }) {
  const getWidth = () => {
    const ns = [2, 5, 10]
    for (let i = 0; i <= 8; i++) {
      for (let j = 0; j < ns.length; j++) {
        const meters = Math.pow(10, i) * ns[j]
        const pixels = getPixelSize(camera, tileSize, meters)
        if (pixels > 100) return { pixels: pixels, meters: meters, ns: ns[j] }
      }
    }
    return { pixels: 0, meters: 0 }
  }

  const width = getWidth()

  return width.meters === 0 ? null : (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      className={styles.ruler}
      {...other}
    >
      <div style={{ width: width.pixels }}>
        <RulerMarks times={width.ns} />
      </div>
      <Typography variant="button" fontSize="small">
        {getUnit(width.meters)}
      </Typography>
    </Stack>
  )
}

ScaleRuler.propTypes = {
  camera: PropTypes.object.isRequired,
  tileSize: PropTypes.number.isRequired,
}

export { ScaleRuler, getUnit }
