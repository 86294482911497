function fetchScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = resolve
    script.onerror = reject
    script.src = url

    const beforeScript = document.getElementsByTagName('script')[0]
    beforeScript.parentNode.insertBefore(script, beforeScript)
  })
}

class Api {
  constructor(objectName) {
    this.objectName = objectName
    this.checkApi()
  }

  checkApi() {
    this.api =
      typeof window !== 'undefined' && window[this.objectName]
        ? window[this.objectName]
        : null
  }

  getAPI() {
    return this.api
  }

  isAvailable() {
    return Boolean(this.api)
  }

  load(url) {
    return new Promise((resolve, reject) => {
      fetchScript(url)
        .then(() => {
          this.checkApi()
          if (this.isAvailable()) resolve(this.api)
          else {
            console.error('Error loading api: ' + this.objectName)
            reject(new Error('Api not loaded'))
          }
        })
        .catch((e) => {
          console.error('Error loading api: ' + this.objectName)
          reject(e)
        })
    })
  }
}

export default Api
