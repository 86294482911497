import React, { useEffect } from 'react'
import { IconButton, Stack, TextField } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiIconButton-root': {
    margin: 0,
    padding: 0,
  },
}))

function formatValue(v, plus = true) {
  v = Math.round(v * 100) / 100
  if (isNaN(v)) return ''
  if (v >= 0) return (plus ? '+ ' : '') + v
  return '- ' + -v
}

export default function NumberField({
  min,
  max,
  step,
  value,
  onChange,
  label,
  width,
  sx,
  ...other
}) {
  const [newValue, setNewValue] = React.useState(formatValue(value, min < 0))

  const handleChange = (e) => {
    const newValue = e.target.value
    setNewValue(newValue)
    const f = parseValue(newValue)
    if (!isNaN(f) && onChange) onChange(f)
  }

  const handleChangeButton = (direct) => {
    let f = parseValue(newValue)
    if (isNaN(f)) f = 0
    if (min !== undefined && f < min) f = min
    if (max !== undefined && f > max) f = max
    f = f + direct * step
    setNewValue(formatValue(f, min < 0))
    if (onChange) onChange(f)
  }

  const parseValue = (newValue) => {
    let f = parseFloat(
      String(newValue)
        .replace(/[^-0-9,.]+/g, '')
        .replace(',', '.')
    )
    if (!isNaN(f)) {
      if (min !== undefined && f < min) f = min
      if (max !== undefined && f > max) f = max
    }
    return f
  }

  useEffect(() => setNewValue(formatValue(value, min < 0)), [value])

  return (
    <Stack direction="row" spacing={0.5} sx={sx}>
      <TextField
        hiddenLabel
        sx={{ flexGrow: 1, width: width || 85 }}
        size="small"
        margin="none"
        label={label}
        value={newValue}
        onChange={handleChange}
        {...other}
      />
      <Stack direction="column" justifyContent="space-between" spacing={0}>
        <StyledIconButton
          sx={{ py: 0.1, borderRadius: 1 }}
          size="small"
          disabled={other.disabled}
          onClick={() => handleChangeButton(1)}
        >
          <KeyboardArrowUpIcon fontSize="inherit" />
        </StyledIconButton>
        <StyledIconButton
          sx={{ py: 0.1, borderRadius: 1 }}
          size="small"
          disabled={other.disabled}
          onClick={() => handleChangeButton(-1)}
        >
          <KeyboardArrowDownIcon fontSize="inherit" />
        </StyledIconButton>
      </Stack>
    </Stack>
  )
}

NumberField.propTypes = {
  sx: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.func,
}
