import React from 'react'
import PropTypes from 'prop-types'
import PaletteEditor from './PaletteEditor'
import PopupDialog from '../../Layout/Dialog/PopupDialog'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material'
import DataContext from '../../Context/DataContext'
import krm from '../Map/krm'
import TileMaker from '../Map/tile'
// import TileMaker from '../../Map/tile'
// import DataContext from '../../../Context/DataContext'

function copy(colors) {
  if (!colors) return []
  const newColors = []
  let i = 0
  for (const c of colors) {
    newColors.push({
      color: c.color,
      shift: c.shift,
      depth: c.depth,
      label: i === colors.length - 1 ? 'max' : null,
    })
    i++
  }
  return newColors
}

function PaletteDialog(props) {
  const manager = React.useContext(DataContext)

  const [colors, setColors] = React.useState(copy(props.value.colors))
  const [evenly, setEvenly] = React.useState(false)
  const [name, setName] = React.useState('')

  const canvasRef = React.useRef()
  const canvasContRef = React.useRef()

  const tileMaker = new TileMaker(
    manager.getData().mapState.tileMaker.opts,
    () => {}
  )

  const updatePreview = () => {
    if (!canvasRef.current) return
    const canvas = canvasRef.current
    const options = { ...manager.getData().mapState.tileMaker.opts }
    canvas.width = options.tileSize * options.scale
    canvas.height = options.tileSize * options.scale
    const mapState = manager.getData().mapState
    const ctx = canvas.getContext('2d')
    // const z = Math.round(mapState.camera.zoom)
    const z = 17
    const pos = krm.getTileFromCoords(
      mapState.camera.center[0],
      mapState.camera.center[1],
      z
    )
    const x = pos.x
    const y = pos.y
    const points = manager.getRenderPoints(z)
    options.customGrid = true
    options.grid = 5
    options.palette = colors
    options.minMaxOn = false
    tileMaker.setOptions(options)
    tileMaker
      .draw(
        ctx,
        x,
        y,
        z,
        points.data,
        [],
        points.bounds,
        mapState.camera.center === undefined ? [0, 0] : mapState.camera.center
      )
      .then((data) => {})
      .catch((e) => {
        ctx.fillStyle = '#aa0000'
        ctx.fillText('Ошибка:', 10, 50)
        ctx.font = '500 20px Roboto'
        ctx.fillText(e.message, 10, 65)
      })
  }

  React.useEffect(() => {
    if (props.value) {
      setColors(copy(props.value.colors))
      setName(props.value.name)
    }
  }, [props.value, props.open])
  React.useEffect(() => {
    if (props.open) setTimeout(() => updatePreview(), 100)
  }, [props.open])

  return (
    <PopupDialog
      title="Редактирование палитры"
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogContent>
        <PaletteEditor
          onChange={(v) => {
            setColors(v)
            setEvenly(false)
            updatePreview()
            // tile.fetch(manager.getRenderPoints(12),0,0,12, )
          }}
          fixed={true}
          value={colors}
          evenly={evenly}
          sx={{ mt: 1 }}
        >
          <Box
            sx={{
              width: 1,
              height: 256,
              bgcolor: 'divider',
              borderRadius: 2,
            }}
            style={{ overflow: 'hidden' }}
            ref={canvasContRef}
          >
            <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
          </Box>
        </PaletteEditor>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{ width: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            label="Название"
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
          />
          <Box>
            <Button onClick={() => setEvenly(true)}>Равномерно</Button>
            <Button
              variant="contained"
              onClick={() =>
                props.onChange({ colors: copy(colors), name: name })
              }
            >
              Применить
            </Button>
          </Box>
        </Stack>
      </DialogActions>
    </PopupDialog>
  )
}

PaletteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PaletteDialog
