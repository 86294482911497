import { Button, Stack } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ClearIcon from '@mui/icons-material/Clear'

function RulerTools(props) {
  return props.tool === 'ruler' ? (
    <Stack direction="row" spacing={2} sx={{ py: 1, px: 1.5 }}>
      <Button size="small" variant="text" startIcon={<DeleteForeverIcon />}>
        Удалить все точки
      </Button>
      <Button size="small" variant="text" startIcon={<ClearIcon />}>
        Удалить промежуточные
      </Button>
    </Stack>
  ) : null
}

RulerTools.propTypes = {
  tool: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onOptionsChange: PropTypes.func,
}

export default RulerTools
