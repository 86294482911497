import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StyledToggleButton } from '../Tools'
import { useContext } from 'react'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import { Box } from '@mui/material'

export default function ToolSelector({ tools, onChange, onClick, size }) {
  const features = useContext(FeaturesContext)

  const list = []
  tools.map((item) => {
    if (!item.feature || features.includes(item.feature)) list.push(item)
  })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [tool, setTool] = React.useState(0)

  const open = Boolean(anchorEl)
  const handleMoreClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget.parentNode)
  }
  const handleClick = () => {
    if (list[tool].onClick) list[tool].onClick()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChangeTool = (index) => {
    setTool(index)
    if (onChange) onChange(list[index].key)
    if (list[tool].onClick) list[index].onClick()
    handleClose()
  }

  return list.length === 0 ? null : (
    <Box>
      <StyledToggleButton
        size={size}
        value="layer"
        color={open ? 'primary.main' : null}
        onClick={handleClick}
        sx={{ mx: 0, pr: 1 }}
      >
        {list[tool].icon}
        <ArrowDropDownIcon onClick={handleMoreClick} />
      </StyledToggleButton>
      <Menu
        dense="true"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {list.map((item, index) => (
          <MenuItem
            key={index}
            disabled={!item.enabled}
            onClick={() => handleChangeTool(index)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

ToolSelector.propTypes = {
  size: PropTypes.any,
  tools: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
}
