import React, { Component } from 'react'
import MapInterface from '../Map/map-interface'
import PropTypes from 'prop-types'
import TileMaker from '../Map/tile'
import Selector from '../Selection/Selector'
import { LinearProgress, Paper, Stack, Typography } from '@mui/material'
import { StyledToggleButton } from '../Tools'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DataContext from '../../Context/DataContext'
import Divider from '@mui/material/Divider'
import { ScaleRuler, getUnit } from '../Map/ScaleRuler'
import MapMarkers from '../Map/MapMarkers'
import krm from '../Map/krm'
import PointsMaker from '../PointsMaker/PointsMaker'

export default class EditorMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapState: {
        camera: {},
        size: {},
      },
      loading: {
        done: 0,
        total: 0,
      },
      depth: null,
      markers: [],
      ruler: [],
    }
    this.tileMaker = new TileMaker(
      {
        ...this.props.options,
        tileSize: this.props.map.tileSize,
        scale: this.props.map.scale,
        border: this.props.map.border,
      },
      (loading) => {
        this.setState({
          loading: loading,
        })
      }
    )
    this.loadMapTile = this.loadMapTile.bind(this)
    this.loadPointsTile = this.loadPointsTile.bind(this)
    this.setLocation = this.setLocation.bind(this)
    // this.updateTilesLayer = this.updateTilesLayer.bind(this)
    this.handleZoomIn = this.handleZoomIn.bind(this)
    this.handleZoomOut = this.handleZoomOut.bind(this)
    this.handleZoomChange = this.handleZoomChange.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseClick = this.handleMouseClick.bind(this)
    this.handleMarkerDrag = this.handleMarkerDrag.bind(this)
    this.handleMarkerClose = this.handleMarkerClose.bind(this)
    this.handleOptionsChange = this.handleOptionsChange.bind(this)
    this.resetCache = this.resetCache.bind(this)
    this.map = null
    this.mapOptions = {
      ...this.props.map.startPosition,
      onZoomChange: this.handleZoomChange,
      onMouseMove: this.handleMouseMove,
      onClick: this.handleMouseClick,
    }
    this.rulerMarkers = []
    this.mapElement = React.createRef()
  }

  resetCache() {
    this.tileMaker.resetCache()
    this.map.updateLayer('tiles')
    this.map.updateLayer('points')
  }

  handleOptionsChange(tool, opts) {
    const newOptions = { ...this.props.toolOptions }
    newOptions[tool] = opts
    this.props.onToolChange(tool, newOptions)
  }

  handleMouseMove(event) {
    const radius = 8
    const points = this.context.findPointsInRadius(event.geopos, radius)
    if (points.length > 0) {
      let depth = 0
      let delta = 0
      const count = Math.min(points.length, 10)
      for (let i = 0; i < count; i++) {
        if (points[i].delta === 0) {
          depth = points[i].depth
          delta = 1
          break
        }
        depth += points[i].depth * (radius / points[i].delta)
        delta += radius / points[i].delta
      }
      this.setState({ depth: depth / delta })
    } else this.setState({ depth: null })
  }

  handleMouseClick(event) {
    if (this.props.tool === 'ruler') {
      const ruler = this.state.ruler.slice()
      ruler.push({ coords: event.geopos })
      this.updateRulerValues(ruler)
      this.setState({ ruler: ruler })
    }
  }

  handleMarkerDrag(coords, index) {
    const ruler = this.state.ruler.slice()
    ruler[index] = { coords: coords }
    this.updateRulerValues(ruler)
    this.setState({ ruler: ruler })
  }

  handleMarkerClose(index) {
    if (this.state.ruler.length === 1) {
      this.setState({ ruler: [] })
      return
    }
    const ruler = this.state.ruler.slice()
    ruler.splice(index, 1)
    this.updateRulerValues(ruler)
    this.setState({ ruler: ruler })
  }

  updateRulerValues(ruler) {
    const scale = krm.getMetersScaleArray(this.state.mapState.camera.center)
    ruler[0].value = 'Начало'
    let len = 0
    for (let i = 1; i < ruler.length; i++) {
      const dx = scale.scaleX * (ruler[i - 1].coords[0] - ruler[i].coords[0])
      const dy = scale.scaleY * (ruler[i - 1].coords[1] - ruler[i].coords[1])
      len += Math.pow(dx * dx + dy * dy, 0.5)
      ruler[i].value = getUnit(len)
    }
  }

  createFilesMarkers() {
    const markers = []
    this.context.getData().files.map((item) => {
      if (item.center)
        markers.push(
          this.map.createMarker(
            MapMarkers.createFileMarker(item.name),
            item.center
          )
        )
    })
    return markers
  }

  setLocation(location) {
    this.map.setLocation(location)
  }

  setBounds(bounds) {
    this.map.setBounds(bounds)
  }

  handleZoomChange(camera) {
    const size = this.state.mapState.size
    if (!camera) camera = this.state.mapState.camera
    this.setState({
      mapState: { camera: camera, size: size },
    })
    this.context.setMapState({
      camera: camera,
      size: size,
      tileMaker: this.tileMaker,
    })
  }

  componentDidMount() {
    this.setState({
      mapState: {
        camera: this.state.mapState.camera,
        size: {
          x: this.mapElement.current.offsetWidth,
          y: this.mapElement.current.offsetHeight,
        },
      },
    })
    if (!this.map) {
      this.map = new MapInterface(
        '760ec1f5-ab20-45a7-ae40-83245027d28a',
        this.mapOptions
      )
      this.map.init(document.getElementById('map')).then(() => {
        this.map.addTilesLayer((x, y, z) => this.loadMapTile(x, y, z))
        this.map.addPointsLayer(
          (x, y, z) => this.loadPointsTile(x, y, z),
          true // this.props.options.showPoints || this.props.options.showTracks
        )
        this.map.switchGroundLayer(this.props.options.groundLayer)
        this.map.setMarkers(this.createFilesMarkers())
        this.map.getRuler().setOnDragListener(this.handleMarkerDrag)
        this.map.getRuler().setOnClosePointListener(this.handleMarkerClose)
        this.map.getRuler().setElementCreator(MapMarkers.createRulerMarker)
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.options !== prevProps.options ||
      this.props.map !== prevProps.map
    ) {
      this.tileMaker.setOptions({
        ...this.props.options,
        tileSize: this.props.map.tileSize,
        scale: this.props.map.scale,
        border: this.props.map.border,
      })
      this.map.switchGroundLayer(this.props.options.groundLayer)
    }
    if (this.props.options !== prevProps.options) {
      // this.tileMaker.resetCache()
      this.map.setMarkers(this.createFilesMarkers())
      if (
        this.props.options.uuid !== prevProps.options.uuid ||
        this.props.options.opacity !== prevProps.options.opacity ||
        this.props.options.mapType !== prevProps.options.mapType ||
        this.props.options.isobath !== prevProps.options.isobath ||
        this.props.options.minMaxOn !== prevProps.options.minMaxOn ||
        this.props.options.minMaxRadius !== prevProps.options.minMaxRadius ||
        this.props.options.minMaxLevel !== prevProps.options.minMaxLevel ||
        this.props.options.minMaxFilter !== prevProps.options.minMaxFilter ||
        this.props.options.showGridPoints !== prevProps.options.showGridPoints
      )
        this.map.updateLayer('tiles')
      if (
        this.props.options.showTracks !== prevProps.options.showTracks ||
        this.props.options.showPoints !== prevProps.options.showPoints ||
        this.props.options.uuid !== prevProps.options.uuid
      ) {
        /*
        this.map.toggleLayer(
          'points',
          this.props.options.showPoints || this.props.options.showTracks
        ) */
        this.map.updateLayer('points')
      }
    }

    if (this.props.selection !== prevProps.selection) {
      this.map.updateLayer('points')
    }

    if (this.state.ruler !== prevState.ruler) {
      this.map.toggleLayer('ruler_points', this.props.tool === 'ruler')
      this.map.toggleLayer('ruler_lines', this.props.tool === 'ruler')
      this.map.getRuler().setPoints(this.state.ruler)
    }

    if (
      this.state.mapState !== prevState.mapState ||
      this.props.tool !== prevProps.tool
    ) {
      const camera = this.state.mapState.camera
      this.map.toggleLayer('tiles', camera.zoom >= this.props.minTilesZoom)
      this.map.toggleLayer('markers', camera.zoom < this.props.maxMarkersZoom)
    }
  }

  async loadPointsTile(x, y, z) {
    const tile = this.props.map.tileSize * this.props.map.scale
    const canvas = document.createElement('canvas')
    canvas.width = tile
    canvas.height = tile
    const ctx = canvas.getContext('2d')
    if (z < 16) return createImageBitmap(canvas)

    return new Promise((resolve) => {
      if (this.props.options.showTracks)
        this.tileMaker.drawTracks(
          ctx,
          x,
          y,
          z,
          this.context.getPointsData().getPoints()
        )
      this.tileMaker.drawPoints(
        ctx,
        x,
        y,
        z,
        this.context.getPointsData().getPoints(),
        this.props.selection,
        this.props.options.showPoints
      )
      resolve(createImageBitmap(canvas))
    })
  }

  async loadMapTile(x, y, z) {
    const tile = this.props.map.tileSize * this.props.map.scale
    const canvas = document.createElement('canvas')
    canvas.width = tile
    canvas.height = tile
    const ctx = canvas.getContext('2d')
    if (z < this.props.minTilesZoom) return createImageBitmap(canvas)

    return new Promise((resolve) => {
      const points = this.context.getRenderPoints(z)
      this.tileMaker
        .draw(
          ctx,
          x,
          y,
          z,
          points.data,
          this.props.selection,
          points.bounds,
          this.state.mapState.camera.center === undefined
            ? [0, 0]
            : this.state.mapState.camera.center
        )
        .then(() => {
          resolve(createImageBitmap(canvas))
        })
        .catch((e) => {
          ctx.fillStyle = '#aa0000'
          ctx.fillText('Ошибка:', 10, 50)
          ctx.font = '500 20px Roboto'
          ctx.fillText(e.message, 10, 65)
          resolve(createImageBitmap(canvas))
        })
    })
  }

  handleZoomIn() {
    this.map.setZoom(Math.round(this.state.mapState.camera.zoom) + 1)
  }

  handleZoomOut() {
    this.map.setZoom(Math.round(this.state.mapState.camera.zoom) - 1)
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          id="map"
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          ref={this.mapElement}
        />
        {this.state.loading.done < this.state.loading.total &&
        this.state.loading.total > 0 ? (
          <LinearProgress
            sx={{ position: 'absolute', left: 0, top: 0, right: 0 }}
            variant="determinate"
            value={(this.state.loading.done / this.state.loading.total) * 100}
          />
        ) : null}
        <ScaleRuler
          sx={{
            bottom: this.props.options.showMeter ? 30 : 0,
            ml: 2,
            mb: this.props.options.showMeter ? 4 : 2,
          }}
          camera={this.state.mapState.camera}
          tileSize={this.props.map.tileSize}
        />
        {this.props.options.showMeter ? (
          <Paper
            sx={{
              position: 'absolute',
              bottom: 0,
              m: 2,
              px: 2,
              py: 0.5,
              boxShadow: 4,
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography variant="button" fontSize="small">
                Глубина:{' '}
                {this.state.depth
                  ? Math.round(this.state.depth * 100) / 100 + ' м'
                  : '—'}
              </Typography>
              <Divider flexItem orientation="vertical" />
              <Typography variant="button" fontSize="small">
                Масштаб:{' '}
                {Math.round(this.state.mapState.camera.zoom * 1000) / 1000}
              </Typography>
            </Stack>
          </Paper>
        ) : null}
        <Selector
          onSelect={this.props.onSelect}
          mapState={this.state.mapState}
          ready={this.props.tool === 'select'}
          toolOptions={this.props.toolOptions[this.props.tool] || {}}
          points={this.context.getPointsData()}
          selection={this.props.selection}
          onPositionChanged={this.handleMouseMove}
          showPoints={this.props.options.showPoints}
        />
        <PointsMaker
          onOptionsChange={(opts) => this.handleOptionsChange('add', opts)}
          onDataChange={this.props.onDataChange}
          mapState={this.state.mapState}
          ready={this.props.tool === 'add'}
          toolOptions={this.props.toolOptions[this.props.tool]}
          points={this.context.getPointsData()}
          onPositionChanged={this.handleMouseMove}
          depth={this.state.depth}
        />
        <Stack
          direction="column"
          sx={{
            position: 'absolute',
            boxShadow: 4,
            borderRadius: 2,
            bgcolor: 'background.paper',
            left: 0,
            top: 65,
            ml: this.props.isMobile ? 0 : 2,
            mt: 10,
          }}
        >
          <StyledToggleButton
            value="zoomin"
            size="small"
            onClick={this.handleZoomIn}
          >
            <AddIcon />
          </StyledToggleButton>
          <StyledToggleButton
            value="zoomout"
            size="small"
            onClick={this.handleZoomOut}
          >
            <RemoveIcon />
          </StyledToggleButton>
        </Stack>
      </div>
    )
  }
}

EditorMap.contextType = DataContext

EditorMap.propTypes = {
  map: PropTypes.shape({
    startPosition: PropTypes.shape({
      center: PropTypes.array.isRequired,
      zoom: PropTypes.number.isRequired,
    }),
    tileSize: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    border: PropTypes.object.isRequired,
  }),
  selection: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  tool: PropTypes.string.isRequired,
  toolOptions: PropTypes.object.isRequired,
  features: PropTypes.array,
  onSelect: PropTypes.func,
  onDataChange: PropTypes.func,
  onToolChange: PropTypes.func,
  minTilesZoom: PropTypes.number.isRequired,
  maxMarkersZoom: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
}
