import krm from './krm'

export default class TileMaker {
  constructor(options, onLoadingChange) {
    this.opts = options
    this.onLoadingChange = onLoadingChange
    this.queue = []
    this.resetCache()
  }

  setOptions(options) {
    this.opts = options
  }

  clearQueue() {
    /* for (const qz in this.queue)
      for (const q of qz) if (!q.done) q.onerror(new Error('clear queue')) */
    this.queue = []
  }

  initQueue() {
    const z = this.cache.currentZoom
    if (this.queue[z])
      for (const q of this.queue[z])
        if (!q.done) q.onerror(new Error('clear queue'))
    this.queue[z] = []
  }

  resetCache() {
    // console.log('reset')
    this.clearQueue()
    this.cache = {
      tiles: {},
      currentZoom: 0,
      zoomBounds: [],
      uuid: '',
    }
  }

  loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.addEventListener('load', () => resolve(img))
      img.addEventListener('error', (e) =>
        reject(new Error(`Error loading: ${url}`))
      )
      img.src = url
    })
  }

  loadBlob(base64) {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) bytes[i] = binaryString.charCodeAt(i)
    return new Blob([bytes.buffer], { type: 'application/octet-binary' })
  }

  async draw(ctx, x, y, z, points, selection, bounds, center) {
    await this.drawMap(ctx, x, y, z, points, bounds, center)
    if (this.opts.showGridPoints) {
      const tileGeoPoints = points.getPointsInPixelTile(x, y, z).points
      // console.log('tile', tileGeoPoints.length)
      for (let i = 0; i < tileGeoPoints.length; i++)
        tileGeoPoints[i] = points.toGeoConvertor(tileGeoPoints[i])
      this.drawGeoPoints(ctx, x, y, z, tileGeoPoints, [], true)
    }
  }

  drawTracks(ctx, x, y, z, points) {
    const tileGeoPoints = points.getPointsInPixelTile(x, y, z, 0.5)
    const tileSize = this.opts.tileSize
    let sid = -1
    ctx.strokeStyle = '#000000'
    ctx.strokeWidth = 0.5
    ctx.beginPath()
    tileGeoPoints.points.sort((a, b) =>
      a[4] === b[4] ? (a[3] > b[3] ? 1 : -1) : a[4] > b[4] ? 1 : -1
    )
    for (const idx of tileGeoPoints.points) {
      const geo = points.toGeoConvertor(idx)
      const p = krm.getTileFromCoords(geo[0], geo[1], z, tileSize)
      const dx = Math.round(p.xp - x * tileSize) * this.opts.scale
      const dy = Math.round(p.yp - y * tileSize) * this.opts.scale
      if (geo[4] !== sid) {
        ctx.moveTo(dx, dy)
        sid = geo[4]
      } else ctx.lineTo(dx, dy)
    }
    ctx.stroke()
  }

  drawPoints(ctx, x, y, z, points, selection, showUnselected = false) {
    const tileGeoPoints = points.getPointsInPixelTile(x, y, z)
    const tileSelection = []
    for (const index of tileGeoPoints.indexes)
      tileSelection.push(selection[index])
    // console.log('points', tileGeoPoints.points.length)
    this.drawGeoPoints(
      ctx,
      x,
      y,
      z,
      tileGeoPoints.points,
      tileSelection,
      false,
      points.toGeoConvertor,
      showUnselected
    )
  }

  isTileInBounds(x, y, z, bounds) {
    if (!this.cache.zoomBounds[z]) {
      this.cache.zoomBounds[z] = {
        min: krm.getTileFromCoords(
          bounds.longMin,
          bounds.latMin,
          z,
          this.opts.tileSize
        ),
        max: krm.getTileFromCoords(
          bounds.longMax,
          bounds.latMax,
          z,
          this.opts.tileSize
        ),
      }
    }
    const b = this.cache.zoomBounds[z]
    // проверяем попадает ли тайл в координаты
    return b.min.x <= x && b.max.x >= x && b.max.y <= y && b.min.y >= y
  }

  drawMap(ctx, x, y, z, points, bounds, center) {
    if (this.cache.uuid !== this.opts.uuid) {
      this.resetCache()
      this.cache.uuid = this.opts.uuid
    }
    if (this.cache.currentZoom !== z) {
      this.cache.currentZoom = z
      this.initQueue()
      // console.log(this.queue)
    }

    //  || points.isEmpty() === 0
    if (points === undefined) {
      return
    }
    if (!this.isTileInBounds(x, y, z, bounds)) {
      return
    }
    // if (!this.cache.zoomBounds[z] || !this.opts.border || !this.opts.border[z])
    // return

    const N = krm.pixelsToMeters(this.opts.tileSize, z, center[1])
    const sendPoints = krm.getTilePoints(
      x,
      y,
      z,
      points.getPointsInPixelTile(x, y, z).points,
      this.opts.tileSize,
      [
        (this.opts.border[z].radius / N) * this.opts.tileSize,
        (this.opts.border[z].radius / N) * this.opts.tileSize,
      ],
      points.toGeoConvertor
    )

    const b = {
      latCenter: bounds.latCenter,
      longCenter: bounds.longCenter,
      latMin: bounds.latMin,
      latMax: bounds.latMax,
      longMin: bounds.longMin,
      longMax: bounds.longMax,
      min: this.cache.zoomBounds[z].min,
      max: this.cache.zoomBounds[z].max,
      maxDepth: bounds.maxDepth,
    }

    if (sendPoints.length > 0) {
      // console.log('draw:', x, y, z)
      return new Promise((resolve, reject) => {
        this.load(sendPoints, x, y, z, b, center)
          .then(async (data) => {
            if (data.layers) {
              await this.drawMapLayers(ctx, data, z, center)
              resolve()
            }
            reject(new Error('Wrong data format'))
          })
          .catch((e) => reject(e))
      })
    }
  }

  async drawMapLayers(ctx, data, z, center) {
    ctx.globalAlpha = this.opts.opacity / 100
    let imageBitmap
    if (data.layers[this.opts.mapType]) {
      imageBitmap = await createImageBitmap(
        this.loadBlob(data.layers[this.opts.mapType])
      )
      ctx.drawImage(imageBitmap, 0, 0)
    }
    if (this.opts.isobath && data.layers.isobath) {
      imageBitmap = await createImageBitmap(this.loadBlob(data.layers.isobath))
      ctx.globalAlpha = (this.opts.opacity / 100) * 0.6
      ctx.drawImage(imageBitmap, 0, 0)
      ctx.globalAlpha = this.opts.opacity / 100
    }
    if (this.opts.minMaxOn) {
      const points = this.findMinMaxPoints(data.layers.minmax, 2)
      for (const p of points) this.drawMinMaxPoint(ctx, p)
    }
    ctx.globalAlpha = 1
  }

  findMinMaxPoints(points, parts = 1, tileSize = 512) {
    const s = tileSize / parts
    const groups = []
    for (const p of points) {
      if (p.depth < 2 && p.q < 0.7) continue
      const x = Math.floor(p.x / s)
      const y = Math.floor(p.y / s)
      const n = x * parts + y
      if (!groups[n]) groups[n] = []
      groups[n].push(p)
    }
    if (groups.length === 0) return []
    const min = []
    const max = []
    let gn = 0
    for (const g of groups) {
      if (!g) continue
      min[gn] = null
      max[gn] = null
      for (const p of g) {
        if (p.d < 0 && (!min[gn] || min[gn].depth < p.depth)) min[gn] = p
        if (p.d > 0 && (!max[gn] || max[gn].depth > p.depth)) max[gn] = p
      }
      gn++
    }
    const result = []
    for (let gn = 0; gn < groups.length; gn++) {
      if (min[gn]) result.push(min[gn])
      if (max[gn]) result.push(max[gn])
    }
    return result
  }

  drawMinMaxPoint(ctx, p) {
    let x = p.x
    let y = p.y
    const label = Math.round(p.depth * 10) / 10
    const isMax = p.d < 0

    const border = 8
    let dx = 0
    let dy = 0
    if (x < border) x = border
    if (x > 512 - border) x = 512 - border
    if (y < border) y = border
    if (y < 20) dy = 20
    if (y > 512 - 20) y = 512 - 20

    ctx.fillStyle = !isMax ? '#ff0000' : '#40009f'
    this.drawTriangle(ctx, x, y, isMax)
    ctx.lineWidth = 1
    ctx.strokeStyle = '#ffffff'
    this.drawTriangle(ctx, x, y, isMax, false)
    ctx.font = 'bold 30px Roboto'
    dx += x > 512 - 50 ? -12 : 12
    ctx.textAlign = x > 512 - 50 ? 'right' : 'left'
    ctx.lineWidth = 3
    ctx.strokeStyle = '#ffffff'
    ctx.strokeText(label, x + dx, y + dy)
    ctx.fillStyle = !isMax ? '#ff0000' : '#40009f'
    ctx.fillText(label, x + dx, y + dy)
  }

  drawTriangle(ctx, x, y, isMax, fill = true) {
    const size = 8
    y += isMax ? 0 : -size
    ctx.beginPath()
    ctx.moveTo(x, y)
    const d = isMax ? 1 : -1
    ctx.lineTo(x - size, y - size * d)
    ctx.lineTo(x + size, y - size * d)
    ctx.lineTo(x, y)
    if (fill) ctx.fill()
    else ctx.stroke()
  }

  drawGeoPoints(
    ctx,
    x,
    y,
    z,
    points,
    selection,
    gridStyle = false,
    convert = null,
    showUnselected = false
  ) {
    if (!convert) convert = (p) => p
    let i = 0
    const b = 10
    const tileSize = this.opts.tileSize
    for (const idx of points) {
      const geo = convert(idx)
      const p = krm.getTileFromCoords(geo[0], geo[1], z, this.opts.tileSize)
      let dx = Math.round(p.xp - x * tileSize)
      let dy = Math.round(p.yp - y * tileSize)
      if (
        !(
          dx < -b ||
          dx > this.opts.tileSize + b ||
          dy < -b ||
          dy > this.opts.tileSize + b
        )
      ) {
        dx *= this.opts.scale
        dy *= this.opts.scale
        dx = Math.round(dx)
        dy = Math.round(dy)
        if (gridStyle) {
          ctx.fillStyle = '#00000050'
          ctx.fillRect(dx - 2, dy - 2, 6, 6)
          ctx.fillStyle = '#ffffff'
          ctx.fillRect(dx - 3, dy - 3, 6, 6)
        } else {
          ctx.fillStyle = '#ff0000'
          ctx.strokeStyle = '#000000'
          if (selection[i] === true) ctx.fillRect(dx - 3, dy - 3, 6, 6)
          else if (showUnselected) ctx.strokeRect(dx - 3, dy - 3, 6, 6)
        }
      }
      i++
    }
  }

  nextQuery() {
    // console.log('nextQuery')
    const z = this.cache.currentZoom
    let done = true
    if (!this.queue[z]) return
    for (let i = 0; i < this.queue[z].length; i++)
      if (!this.queue[z][i].done) {
        if (this.queue[z][i].progress) {
          // console.log('progress:', i)
          return
        }
        this.execQuery(i)
        done = false
        break
      }
    if (done) {
      this.onLoadingChange({
        done: 1,
        total: 1,
      })
      // console.log('done')
      this.queue[z] = []
    }
  }

  execQuery(index) {
    const z = this.cache.currentZoom
    if (index < this.queue[z].length) {
      if (this.queue[z][index]) {
        this.queue[z][index].progress = true
        this.queue[z][index].callback()
      } else {
        // console.log('NOT FOUND:', z, index, this.queue[z])
      }
      this.onLoadingChange({
        done: index,
        total: this.queue[z].length,
      })
    } else {
      // console.log('DONE WHEN EXEC')
      this.onLoadingChange({
        done: 1,
        total: 1,
      })
      // this.queue[z] = []
    }
  }

  load(points, x, y, z, bounds, center) {
    const tileCode = `${this.opts.uuid}-${x}-${y}-${z}`
    // const uuid = this.opts.uuid
    return new Promise((resolve, reject) => {
      if (this.cache.tiles[tileCode] !== undefined) {
        // console.log('cache used: ', tileCode)
        resolve(this.cache.tiles[tileCode])
      } else {
        if (!this.queue[z]) this.queue[z] = []
        const index = this.queue[z].length
        this.queue[z].push({
          tileCode: tileCode,
          done: false,
          progress: false,
          callback: () =>
            this.fetch(points, x, y, z, bounds, center)
              .then((result) => {
                this.cache.tiles[tileCode] = result
                if (this.queue[z][index]) this.queue[z][index].done = true
                if (z === this.cache.currentZoom)
                  setTimeout(() => this.nextQuery(), 0)
                resolve(result)
              })
              .catch((e) => {
                console.error(e)
                reject(e)
              }),
          onerror: (e) => reject(e),
        })
        // console.log('add to queue: ', index, tileCode)
        this.nextQuery()
      }
    })
  }

  fetch(points, x, y, z, bounds, center) {
    const options = this.opts
    const N = krm.pixelsToMeters(this.opts.tileSize, z, center[1])
    if (!this.opts.customGrid)
      options.grid = N / (N / this.opts.border[z].grid + 0.5)
    options.contours = this.opts.isobath

    return fetch('/api/v1/tile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        uuid: this.opts.uuid,
        x: x,
        y: y,
        z: z,
        width: this.opts.tileSize * this.opts.scale,
        height: this.opts.tileSize * this.opts.scale,
        depth: this.opts.customDepthOn
          ? this.opts.customDepth
          : bounds.maxDepth,
        dx: 0,
        dy: 0,
        points: krm.createOptimizePoints(
          points,
          (x + 0.5) * this.opts.tileSize,
          (y + 0.5) * this.opts.tileSize,
          z,
          center[1]
        ),
        options: options,
        range: {
          x_min: 0,
          x_max: N,
          y_min: 0,
          y_max: N,
        },
      }),
    }).then((response) => response.json())
  }
}
