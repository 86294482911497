import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledToggleButton } from './StyledButtons'
import { CircularProgress, Tooltip } from '@mui/material'

function addToolTip(component, tooltip) {
  return !tooltip ? component : <Tooltip title={tooltip}>{component}</Tooltip>
}

export default function ToolButton({
  loading,
  children,
  tooltip,
  disabled,
  ...other
}) {
  return (
    <StyledToggleButton disabled={disabled || loading} {...other}>
      {loading ? (
        <CircularProgress sx={{ mr: 1 }} size="1em" color="inherit" />
      ) : (
        addToolTip(children, tooltip)
      )}
    </StyledToggleButton>
  )
}

ToolButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
}
