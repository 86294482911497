import { Box } from '@mui/material'
import { StyledDivider } from './StyledButtons'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import React, { useContext } from 'react'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import PropTypes from 'prop-types'
import ToolGroup from './ToolGroup'
import CropDinRoundedIcon from '@mui/icons-material/CropDinRounded'
import {
  DottedSquareIcon,
  MergingIcon,
  ExcludeIcon,
  IntersectionIcon,
} from '../../Layout/Icons'
import InvertColorsIcon from '@mui/icons-material/InvertColors'
import DeselectIcon from '@mui/icons-material/Deselect'
import ToolButton from './ToolButton'
import DataContext from '../../Context/DataContext'

function SelectionTools(props) {
  const features = useContext(FeaturesContext)
  const manager = useContext(DataContext)

  const options = props.options[props.tool] || {
    shape: props.isMobile ? 'magic' : 'rect',
    method: 'replace',
  }

  const handleChange = (value) => {
    const newOptions = { ...options }
    for (const p in value) newOptions[p] = value[p]
    props.onOptionsChange(newOptions)
  }

  const handleInvert = () => {
    const points = manager.getPointsData()
    const selection = []
    for (let p = 0; p < points.count(); p++)
      selection[p] = !props.selection[p] || props.selection[p] !== true
    props.onSelect(selection)
  }

  const checkSelection = () => {
    for (const p in props.selection)
      if (props.selection[p] === true) return true
    return false
  }
  const haveSelection = checkSelection()
  const handleClear = () => {
    props.onSelect([])
  }

  return props.tool === 'select' ? (
    <Box
      sx={{
        p: 0.6,
        display: 'flex',
        flexWrap: 'nowrap',
      }}
    >
      <ToolGroup
        size="small"
        value={options.shape}
        onChange={(v) => handleChange({ shape: v })}
        buttons={[
          {
            value: 'rect',
            tooltip: 'Прямоугольное выделение',
            icon: <CropDinRoundedIcon />,
            feature: 'TOOL_SELECT_RECT',
          },
          {
            value: 'circle',
            tooltip: 'Овальное выделение',
            icon: <PanoramaFishEyeIcon />,
            feature: 'TOOL_SELECT_CIRCLE',
          },
          {
            value: 'magic',
            tooltip: 'Выделение областью',
            icon: <AutoFixHighIcon />,
            feature: 'TOOL_SELECT_MAGIC',
          },
        ]}
      />
      <StyledDivider />
      {!features.includes('TOOL_SELECT_METHOD') ? null : (
        <ToolGroup
          size="small"
          value={options.method}
          onChange={(v) => handleChange({ method: v })}
          buttons={[
            {
              value: 'replace',
              tooltip: 'Заменить выделение',
              icon: <DottedSquareIcon />,
            },
            {
              value: 'add',
              tooltip: 'Добавить',
              icon: <MergingIcon />,
            },
            {
              value: 'exclude',
              tooltip: 'Вычесть',
              icon: <ExcludeIcon />,
            },
            {
              value: 'intersect',
              tooltip: 'Пересечение',
              icon: <IntersectionIcon />,
            },
          ]}
        />
      )}
      <StyledDivider />
      {!features.includes('TOOL_INVERT') ? null : (
        <ToolButton
          size="small"
          tooltip="Инвертировать выделение"
          onClick={handleInvert}
        >
          <InvertColorsIcon />
        </ToolButton>
      )}
      {!features.includes('TOOL_CLEAR') ? null : (
        <ToolButton
          disabled={!haveSelection}
          size="small"
          tooltip="Очистить выделение"
          onClick={handleClear}
        >
          <DeselectIcon />
        </ToolButton>
      )}
    </Box>
  ) : null
}

SelectionTools.propTypes = {
  tool: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  selection: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onOptionsChange: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
}

export default SelectionTools
