import krm from '../Faces/Map/krm'
import GridManager from './GridManager'
import { NonBlockingCalculate } from './NonBlockingCalculate'

export default class PointsData {
  constructor(settings) {
    this.clear()
    this.updateSettings(settings)
  }

  static getPointsGridManager() {
    return new GridManager((1000 / krm.getEarthRadius()) * Math.PI)
  }

  clear() {
    this.points = PointsData.getPointsGridManager()
    // this.points = new GridManager(100, (p) => krm.idxToGeo(p, 1))
    this.cleanGrid()
    this.grids = []
  }

  cleanGrid() {
    this.baseGrid = new GridManager(100, (p) =>
      krm.idxToGeo(p, this.baseGridSize)
    )
  }

  updateSettings(settings) {
    this.settings = settings
    this.baseGridSize = 4 // this.settings.grid[19]
  }

  setSelection(selection, use) {
    if (this.useSelection === use && selection === this.selection) return false
    this.selection = selection
    this.useSelection = use
    this.cleanGrid()
    let i = 0
    for (const p of this.points.getAllPoints()) {
      if (!this.useSelection || this.selection[i] === true)
        this.baseGrid.addPoint(krm.geoToIdx(p, this.baseGridSize))
      i++
    }
    return true
  }

  getSelectionPointsCount() {
    const result = { sessions: 0, points: 0 }
    const sessions = {}
    let i = 0
    for (const s of this.selection) {
      if (s === true) {
        const p = this.points.get(i)
        if (!p) continue
        sessions[p[4]] = true
        result.points++
      }
      i++
    }
    for (const s in sessions) if (s) result.sessions++
    return result
  }

  getSelectedData() {
    const result = { sessions: {}, points: [] }
    let i = 0
    for (const s of this.selection) {
      if (s === true) {
        const p = this.points.get(i)
        result.points.push(p)
        if (!result.sessions[p[4]])
          result.sessions[p[4]] = {
            index: p[4],
            points: [],
          }
        result.sessions[p[4]].points.push(p)
      }
      i++
    }
    return result
  }

  isEmpty() {
    return this.points.isEmpty()
  }

  [Symbol.iterator]() {
    return this.points.getAllPoints().values()
    /*
    var index = -1;
    var data  = this._data;

    return {
      next: () => ({ value: data[++index], done: !(index in data) })
    }; */
  }

  get(index) {
    return this.points.get(index)
  }

  count() {
    return this.points.pointsCount()
  }

  add(points, session) {
    return new Promise((resolve) => {
      this.bounds = null
      points = points.sort((a, b) => (a[3] > b[3] ? 1 : -1))
      const addedIndexes = []
      NonBlockingCalculate(
        points.length,
        1000,
        (i) => {
          const p = points[i]
          p[4] = session
          const res = this.points.addPoint(
            p,
            (a, b, tileKey, index) => {
              if (a[0] === b[0] && a[1] === b[1]) {
                if (a[3] > b[3]) {
                  if (a[2] === -100 || a[2] === -200) {
                    // remove
                    // console.log('rem', a[4], a[2], a[3])
                    this.points.clearPointDirect(tileKey, index)
                    this.baseGrid.clearPoint(krm.geoToIdx(a, this.baseGridSize))
                  } else {
                    // update
                    // console.log('upd', a[4], a[2], a[3])
                    this.points.updatePointDirect(tileKey, index, a)
                    this.baseGrid.updatePoint(
                      krm.geoToIdx(a, this.baseGridSize)
                    )
                  }
                } // else console.log('ign', a[4], a[2], a[3])
                return true
              }
              return false
            },
            this.points.size * 0.5,
            (a) => a[2] > -100
          )
          if (res) {
            addedIndexes.push(res)
            if (!this.useSelection || this.selection[res] === true)
              this.baseGrid.addPoint(krm.geoToIdx(p, this.baseGridSize))
          }
        },
        () => resolve(addedIndexes)
      )
    })
  }

  remove(session) {}

  getBounds() {
    if (!this.bounds) this.bounds = krm.findBounds(this.points.getAllPoints())
    return this.bounds
  }

  getPoints() {
    return this.points
  }

  getGrid(zoom) {
    return this.baseGrid
  }
}
