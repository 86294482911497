import React, { useContext, useState } from 'react'
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DataContext from '../../../Context/DataContext'
import LoadingButton from '../../../Layout/Components/LoadingButton'
import P7Options from './Options/P7Options'
import P8Options from './Options/P8Options'
import ImageOptions from './Options/ImageOptions'
import { formatUnit } from '../../Common/units'

function createFileName(name, manager) {
  let n = name
  if (n === '') {
    manager.getData().files.map((item) => {
      if (item.active) n += (n !== '' ? '+' : '') + item.name
    })
  }
  return n
}

function createFormat(name, key, ext, useName = false, enabled = true) {
  return {
    name: name,
    key: key,
    ext: ext,
    enabled: enabled,
    useName: useName,
  }
}

const formats = [
  createFormat('Практик 7', 'p7', 'tar', true),
  createFormat('Практик 8', 'p8', 'pc8', true),
  // createFormat('Таблицей (csv)', 'csv', 'csv', false),
  // createFormat('Изображение (png)', 'image', 'png'),
  // createFormat('Отладка (json)', 'json', 'json', true),
]

function Downloader({ selection, renderOptions }) {
  const manager = useContext(DataContext)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [saveOptions, setSaveOptions] = useState({
    source: 'all',
    type: 0,
    name: '',
    useOldUuid: false,
    imageFormat: '800x800',
    background: true,
    palettes: false,
    selectedPalettes: [0],
    deletedPoints: true,
  })

  function saveLabel() {
    let s = manager.getSessionsCount()
    let p = manager.getPointsCount()
    if (saveOptions.source === 'selected') {
      const info = manager.getSelectionPointsCount()
      s = info.sessions
      p = info.points
    }

    return (
      'Будет сохранено: ' +
      formatUnit(s, ['сессия', 'сессии', 'сессий']) +
      ' и ' +
      formatUnit(p, ['точка', 'точки', 'точек'])
    )
  }

  const optionChange = (data) => {
    const newOptions = { ...saveOptions }
    for (const d in data) newOptions[d] = data[d]
    setSaveOptions(newOptions)
  }

  const downloadFile = () => {
    setLoading(true)
    const { name, type, ...options } = saveOptions
    const realName = createFileName(name, manager)
    fetch('/api/v1/download', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        layers: manager.getSavingData({ type: formats[type].key, ...options }),
        options: options,
        renderOptions: formats[type].key === 'image' ? renderOptions : null,
        type: formats[type].key,
        name: realName,
      }),
    })
      .then((response) => {
        return new Promise((resolve) => {
          response.blob().then((blob) =>
            resolve({
              blob: blob,
              status: response.status,
              statusText: response.statusText,
            })
          )
        })
      })
      .then(({ blob, status, statusText }) => {
        if (status === 200) {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', realName + '.' + formats[type].ext)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        } else setError('Ошибка: ' + status + ' ' + statusText)
        setLoading(false)
      })
      .catch((e) => {
        setError(e.message)
        setLoading(false)
      })
  }

  return !manager.isDataAvailable() ? null : (
    <Box>
      <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
        Скачать
      </Typography>

      <Box sx={{ my: 1 }}>
        <Typography sx={{ display: 'block', mb: 1 }} variant="caption">
          Источник данных
        </Typography>
        <ToggleButtonGroup
          value={saveOptions.source}
          color="primary"
          size="small"
          exclusive
          onChange={(e, v) => {
            if (v !== null) optionChange({ source: v })
          }}
        >
          <ToggleButton value="all">Все точки</ToggleButton>
          <ToggleButton value="selected" disabled={selection.length === 0}>
            Выбранные
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <FormControl fullWidth sx={{ my: 1 }} size="small">
        <InputLabel>Формат</InputLabel>
        <Select
          sx={{ display: 'block' }}
          value={saveOptions.type}
          label="Формат"
          onChange={(e) => optionChange({ type: e.target.value })}
        >
          {formats.map((item, key) => (
            <MenuItem value={key} key={key} disabled={!item.enabled}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <P7Options
        options={saveOptions}
        format={formats[saveOptions.type].key}
        onChange={optionChange}
      />
      <P8Options
        options={saveOptions}
        format={formats[saveOptions.type].key}
        onChange={optionChange}
      />
      <ImageOptions
        options={saveOptions}
        format={formats[saveOptions.type].key}
        onChange={optionChange}
      />

      {formats[saveOptions.type].useName ? (
        <Box sx={{ my: 1 }}>
          <TextField
            size="small"
            sx={{ width: 1 }}
            value={saveOptions.name}
            label="Название файла"
            onChange={(e) => optionChange({ name: e.target.value })}
            helperText="Используется при импорте в приложения Практик"
          />
        </Box>
      ) : null}

      <Chip sx={{ mt: 2 }} label={saveLabel()} />

      {error !== '' ? (
        <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError('')}>
          {error}
        </Alert>
      ) : (
        <LoadingButton
          sx={{ mt: 2 }}
          fullWidth
          loading={loading}
          onClick={downloadFile}
          variant="outlined"
          startIcon={<FileDownloadIcon />}
        >
          Скачать
        </LoadingButton>
      )}
    </Box>
  )
}

Downloader.propTypes = {
  renderOptions: PropTypes.object.isRequired,
  selection: PropTypes.array.isRequired,
}

export default Downloader
