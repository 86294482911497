import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function DialogProgress({ loading, children }) {
  return loading ? (
    <Box
      sx={{
        height: 1,
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    children
  )
}

DialogProgress.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default DialogProgress
