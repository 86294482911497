import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import 'react-virtualized/styles.css'
import { Alert, Box, ListItem, Stack, Typography } from '@mui/material'
import Moment from 'moment'
import 'moment/locale/ru'
import DataContext from '../../../Context/DataContext'

const formatDate = (utime, format = 'D MMM Y H:mm') => {
  const date = new Date(utime)
  return Moment(date).locale('ru').format(format)
}

function Timeline(props) {
  const manager = React.useContext(DataContext)

  const items = manager.getHistory().timeline
  const count = items.length

  const rowRenderer = ({ key, index, style }) => {
    return (
      <ListItem
        key={key}
        style={style}
        sx={{
          px: 1,
          pr: 3,
          borderRadius: 1,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="top"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Box>
            <Typography variant="text" sx={{ pl: 2 }} component="div">
              {items[index].name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" sx={{ pl: 2 }} component="div">
              {formatDate(items[index].time, 'H:mm:ss')}
            </Typography>
          </Box>
        </Stack>
      </ListItem>
    )
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        return count === 0 ? (
          <Alert style={{ width: width }} severity="info">
            История изменений пуста
          </Alert>
        ) : (
          <List
            width={width}
            height={height}
            rowCount={count}
            rowHeight={50}
            rowRenderer={rowRenderer}
          />
        )
      }}
    </AutoSizer>
  )
}

Timeline.propTypes = {}

export default Timeline
