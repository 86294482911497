import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, LinearProgress, Button } from '@mui/material'

function FilterLayout(props) {
  const v = props.progress === undefined ? 'indeterminate' : 'determinate'
  return (
    <Box>
      <Typography
        color="text.secondary"
        variant="caption"
        component="div"
        sx={{ my: 1 }}
      >
        {props.label}
      </Typography>
      {props.alert && props.alert !== '' ? (
        <Typography
          color="error.main"
          variant="caption"
          component="div"
          sx={{
            my: 2,
            borderRadius: 2,
            borderColor: 'error.main',
            border: 1,
            px: 2,
            py: 1,
          }}
        >
          {props.alert}
        </Typography>
      ) : null}
      {props.children}
      {props.loading ? (
        <Box sx={{ w: 1, my: 1 }}>
          <LinearProgress variant={v} value={props.progress} />
        </Box>
      ) : null}
      <Button
        variant={props.buttonVariant || 'outlined'}
        onClick={props.onExecute}
        disabled={props.loading}
        fullWidth
      >
        {props.button}
      </Button>
    </Box>
  )
}

FilterLayout.propTypes = {
  label: PropTypes.string,
  alert: PropTypes.string,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  children: PropTypes.any,
  button: PropTypes.string,
  buttonVariant: PropTypes.string,
  onExecute: PropTypes.func,
}

export default FilterLayout
