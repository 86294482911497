import React from 'react'
import PropTypes from 'prop-types'
import CanvasLayer from './CanvasLayer'
import DataContext from '../../Context/DataContext'
import { getGeoByPixel } from './PointsConvertor'

export default class PointsMaker extends React.Component {
  constructor(props) {
    super(props)
    this.handlerSelectEnd = this.handlerSelectEnd.bind(this)
    this.handlerApply = this.handlerApply.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseClick = this.handleMouseClick.bind(this)
    this.canvas = new CanvasLayer('points-maker-canvas', this.handlerApply)
  }

  handlerSelectEnd(e) {
    const res = this.canvas.onMouseUp(e)
    if (!res) return
    if (res.points) {
      this.context
        .pointsAdd(res.points)
        .then((newManager) => this.props.onDataChange(newManager))
    }
    if (res.promise) this.handlerApply(res.promise)
  }

  handlerApply(pointsPromise) {
    // if (!this.props) return
    if (!this.props.toolOptions.loading) {
      const newOptions = { ...this.props.toolOptions }
      newOptions.loading = true
      this.props.onOptionsChange(newOptions)
    }
    pointsPromise
      .then((points) => {
        if (this.props.onDataChange && points.length > 0)
          return this.context.pointsAdd(points)
        return Promise.reject(new Error('No data'))
      })
      .then((newManager) => {
        const newOptions = { ...this.props.toolOptions }
        newOptions.apply = false
        newOptions.loading = false
        this.props.onOptionsChange(newOptions)
        this.props.onDataChange(newManager)
      })
  }

  handleMouseClick() {
    if (this.props.toolOptions && this.props.toolOptions.brush === 'pipette') {
      const newOptions = { ...this.props.toolOptions }
      newOptions.depth = this.props.depth
      newOptions.brush = newOptions.lastBrush
      this.props.onOptionsChange(newOptions)
    }
  }

  handleMouseMove(e) {
    if (this.props.onPositionChanged) {
      const geo = getGeoByPixel(this.props.mapState, {
        x: e.clientX,
        y: e.clientY,
      })
      this.props.onPositionChanged({ geopos: [geo.x, geo.y] })
    }
  }

  componentDidMount() {
    this.canvas.initCanvas()
    this.canvas.resize(this.props.mapState)
    this.canvas.setData(this.props.points)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.mapState !== this.props.mapState ||
      prevProps.points !== this.props.points ||
      prevProps.ready !== this.props.ready
    ) {
      if (this.props.ready) {
        this.canvas.initCanvas()
        this.canvas.resize(this.props.mapState)
      }
      // console.log('mapState change')
    }
    if (
      prevProps.toolOptions !== this.props.toolOptions &&
      this.props.toolOptions
    ) {
      this.canvas.setTool(this.props.toolOptions)
    }
    if (prevProps.points !== this.props.points) {
      this.canvas.setData(this.props.points)
      this.canvas.render()
      // console.log('points change')
    }
  }

  render() {
    return (
      <div
        style={{
          display: this.props.ready ? 'block' : 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          width: this.props.mapState.size.x,
          height: this.props.mapState.size.y,
        }}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleMouseClick}
      >
        <canvas
          id="points-maker-canvas"
          onTouchStart={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.canvas.onMouseDown(e.changedTouches[0])
          }}
          onTouchMove={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.canvas.onMouseMove(e.changedTouches[0])
          }}
          onTouchEnd={(e) => this.handlerSelectEnd(e.changedTouches[0])}
          onMouseDown={(e) => this.canvas.onMouseDown(e)}
          onMouseMove={(e) => this.canvas.onMouseMove(e)}
          onMouseUp={(e) => this.handlerSelectEnd(e)}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  }
}

PointsMaker.contextType = DataContext

PointsMaker.propTypes = {
  points: PropTypes.any.isRequired,
  mapState: PropTypes.object.isRequired,
  ready: PropTypes.bool,
  toolOptions: PropTypes.object,
  onDataChange: PropTypes.func,
  onOptionsChange: PropTypes.func,
  onPositionChanged: PropTypes.func,
  depth: PropTypes.number,
}
