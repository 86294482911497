import React, { useContext } from 'react'
import { Box, Divider } from '@mui/material'
import PanToolIcon from '@mui/icons-material/PanTool'
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PropTypes from 'prop-types'
import { LayersMenu, StyledDivider } from '../Tools'
import FeaturesContext from '../../FeatureToggle/FeaturesContext'
import DataContext from '../../Context/DataContext'
import ToolSelector from '../Tools/ToolSelector'
import StraightenIcon from '@mui/icons-material/Straighten'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import CachedIcon from '@mui/icons-material/Cached'
import SelectionTools from '../Tools/SelectionTools'
import AddTools from '../Tools/AddTools'
import RulerTools from '../Tools/RulerTools'
import EditTools from '../Tools/EditTools'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import ToolButton from '../Tools/ToolButton'
import SettingsContext from '../../Context/SettingsContext'
import ToolGroup from '../Tools/ToolGroup'

function EditorTools(props) {
  const features = useContext(FeaturesContext)
  const settings = useContext(SettingsContext)
  const manager = useContext(DataContext)
  const [loading, setLoading] = React.useState('')

  const deletePoints = () => {
    setLoading('delete')
    manager.pointsDelete(props.selection).then((newManager) => {
      setLoading('')
      props.onDataChange(newManager)
    })
  }

  const handleResetCache = () => {
    props.onAction({ action: 'reset-cache' })
  }
  const handleUndo = () => {
    setLoading('history')
    manager
      .getHistory()
      .undo()
      .then(() => {
        setLoading('')
        props.onAction({ action: 'undo' })
      })
  }
  const handleRedo = () => {
    setLoading('history')
    manager
      .getHistory()
      .redo()
      .then(() => {
        setLoading('')
        props.onAction({ action: 'redo' })
      })
  }

  const checkSelection = () => {
    for (const p in props.selection)
      if (props.selection[p] === true) return true
    return false
  }
  const haveSelection = checkSelection()

  const handleTool = (tool) => {
    if (tool !== null) props.onToolChange(tool)
  }

  const handleToolOptions = (options) => {
    const newOptions = { ...props.toolOptions }
    newOptions[props.tool] = options
    props.onToolChange(props.tool, newOptions)
  }

  const availableOptions = ['add', 'edit', 'select']
  if (features.includes('RULER_OPTIONS')) availableOptions.push('ruler')
  const subTools = availableOptions.indexOf(props.tool) !== -1

  const history = manager.getHistory().available()

  const iconSize = props.isMobile ? 'small' : 'medium'

  return (
    <Box>
      <Box
        sx={{
          p: props.isMobile ? 0.5 : 0,
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        <ToolGroup
          size={iconSize}
          value={props.tool}
          onChange={handleTool}
          buttons={[
            {
              value: 'hand',
              tooltip: 'Управление картой',
              icon: <PanToolIcon />,
              feature: 'TOOL_HAND',
            },
            {
              value: 'select',
              tooltip: 'Выделение точек',
              icon: <HighlightAltOutlinedIcon />,
              feature: 'TOOL_SELECT_RECT',
            },
            {
              value: 'ruler',
              tooltip: 'Линейка',
              icon: <StraightenIcon />,
              feature: 'TOOL_RULER',
            },
          ]}
        />

        <StyledDivider />
        {!features.includes('TOOL_RESET_CACHE') ? null : (
          <ToolButton
            size={iconSize}
            tooltip="Сбросить кэш"
            onClick={handleResetCache}
          >
            <CachedIcon />
          </ToolButton>
        )}
        {!features.includes('HISTORY') ? null : (
          <ToolSelector
            loading={loading === 'history'}
            size={iconSize}
            tools={[
              {
                name: history.undoText,
                icon: <UndoIcon />,
                key: 'undo',
                enabled: history.undo,
                onClick: handleUndo,
              },
              {
                name: history.redoText,
                key: 'redo',
                icon: <RedoIcon />,
                enabled: history.redo,
                onClick: handleRedo,
              },
            ]}
          />
        )}
        <StyledDivider />
        {!features.includes('ACTIONS_ADD') || !settings.proMode ? null : (
          <ToolButton
            size={iconSize}
            color="success.main"
            onClick={(e) => handleTool('add')}
            selected={props.tool === 'add'}
            tooltip="Создать точки измерения"
          >
            <AddCircleOutlineIcon />
          </ToolButton>
        )}
        {!features.includes('ACTIONS_EDIT') || !settings.proMode ? null : (
          <ToolButton
            size={iconSize}
            color="info.main"
            onClick={(e) => handleTool('edit')}
            selected={props.tool === 'edit'}
            disabled={!haveSelection}
            tooltip="Коррекция координаты и глубины"
          >
            <EditLocationAltIcon />
          </ToolButton>
        )}
        {!features.includes('ACTIONS_REMOVE') ? null : (
          <ToolButton
            size={iconSize}
            value="remove"
            onClick={deletePoints}
            disabled={!haveSelection}
            color="error.main"
            loading={loading === 'delete'}
          >
            <DeleteOutlineOutlinedIcon />
          </ToolButton>
        )}
        <StyledDivider />
        <LayersMenu
          size={iconSize}
          options={props.options}
          onChange={props.onOptionsChange}
        />
      </Box>
      {subTools ? (
        <Box
          style={
            props.isMobile
              ? { minWidth: '100%' }
              : { borderRadius: '0 0 0.5rem 0.5rem' }
          }
          sx={{ bgcolor: 'divider' }}
        >
          <Divider orientation="horizontal" />
          <Box
            style={
              props.isMobile
                ? { minWidth: '100%' }
                : { borderRadius: '0 0 0.5rem 0.5rem' }
            }
            sx={{ display: 'inline-block', bgcolor: 'background.paper' }}
          >
            <SelectionTools
              tool={props.tool}
              options={props.toolOptions}
              selection={props.selection}
              onSelect={props.onSelect}
              onOptionsChange={handleToolOptions}
              isMobile={props.isMobile}
            />
            <AddTools
              tool={props.tool}
              options={props.toolOptions}
              onOptionsChange={handleToolOptions}
            />
            <EditTools
              tool={props.tool}
              selection={props.selection}
              onDataChange={props.onDataChange}
            />
            <RulerTools
              tool={props.tool}
              options={props.toolOptions}
              onOptionsChange={handleToolOptions}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

EditorTools.propTypes = {
  tool: PropTypes.string.isRequired,
  toolOptions: PropTypes.object.isRequired,
  onToolChange: PropTypes.func,
  onDataChange: PropTypes.func,
  onAction: PropTypes.func.isRequired,
  selection: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  options: PropTypes.object,
  onOptionsChange: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
}

export default EditorTools
