import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

function getMaxShift() {
  return 0.85
}

const maxShift = getMaxShift()

function updateShifts(oldColors) {
  if (!oldColors) return []
  const colors = oldColors.slice()
  if (colors.length === 0) return colors
  const maxValue = maxByDepth(colors).depth
  for (let i = 0; i < colors.length - 1; i++) {
    if (colors[i].depth === undefined)
      colors[i].depth = (maxValue * colors[i].shift) / maxShift
    colors[i].shift = (colors[i].depth / maxValue) * maxShift
  }
  colors[colors.length - 1].shift = 1
  return colors
}

function maxByDepth(colors) {
  if (colors.length === 0) return -1
  let max = 0
  for (let i = 1; i < colors.length - 1; i++)
    if (colors[max].depth < colors[i].depth) max = i
  return colors[max]
}

function renderFixed(sourceColors) {
  let res = 'linear-gradient(to right'
  const colors = updateShifts(sourceColors)
  colors.sort((a, b) => (a.shift < b.shift ? -1 : 1))
  let ls = 0
  for (const c of colors) {
    res +=
      ',' +
      c.color +
      ' ' +
      Math.round(ls * 100) +
      '%' +
      ' ' +
      Math.round(c.shift * 100) +
      '%'
    ls = c.shift
  }
  return res + ')'
}

function renderGradient(sourceColors) {
  let res = 'linear-gradient(to right'
  const colors = updateShifts(sourceColors)
  colors.sort((a, b) => (a.shift < b.shift ? -1 : 1))
  for (const c of colors)
    res += ',' + c.color + ' ' + Math.round(c.shift * 100) + '%'
  return res + ')'
}

function PaletteColors({ children, colors, fixed, sx, ...others }) {
  if (!sx) sx = {}
  if (!sx.borderRadius) sx.borderRadius = 1
  if (!colors)
    colors = [
      { color: '#000000', value: 1 },
      { color: '#ffffff', value: 3 },
    ]

  return (
    <Box
      {...others}
      sx={sx}
      style={{
        background: fixed ? renderFixed(colors) : renderGradient(colors),
        minHeight: 1,
        width: '100%',
      }}
    >
      {children || null}
    </Box>
  )
}

PaletteColors.propTypes = {
  colors: PropTypes.array,
  fixed: PropTypes.bool,
  sx: PropTypes.object,
  children: PropTypes.any,
}

export { PaletteColors, updateShifts, maxByDepth, getMaxShift }
